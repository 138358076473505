import React, { useState } from "react";
import { Container, Button, Breadcrumb, Modal } from "react-bootstrap";

const CancleOrder = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="body_container_wrapper">
      <Container>
        <div className="shoopingcart_list cancleorder">
          <div className="heading">
            <Breadcrumb>
              <Breadcrumb.Item href="#">
                <h6>Order</h6>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <h6>Order Number: 123456789</h6>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>QTY.</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="shopping_list">
                      <div className="left-img">
                        <img src={require("../../assets/img/Cucumber.png")} />
                      </div>
                      <div className="content">
                        <h6>
                          Star Light Gardens - Salad Greens - 1 Bag (6 oz)
                        </h6>
                        <p>
                          <small>
                            Star Light Gardens, 54 Fowler Avenue, Durham,
                            Connecticut, 06422 - Friday, April 3rd, After 2 pm
                          </small>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <h6 className="qty">2</h6>
                  </td>
                  <td>
                    <h6 className="ex_bold text_dark">$ 6.00</h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="total">
            <h6 className="ex_bold mb-5 text_dark">Subtotal - $12.00</h6>
            <Button
              variant="outline-dark"
              className="cancle-button"
              onClick={handleShow}
            >
              {" "}
              Checkout
            </Button>
          </div>
        </div>
      </Container>

      <Modal
        show={show}
        onHide={handleClose}
        className="cm_confirmation cancleorder"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src={require("../../assets/img/standclose.png")} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h6>Order Placed Successfully!</h6> */}
          <span className="text_dark">
            Are you sure you want to cancel this order.
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-dark"
            className="cancle-button"
            onClick={handleClose}
          >
            {" "}
            No
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Yes{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CancleOrder;
