/**
 * @About
 * This file is entry point of config
 * NOTE: if you struggle to get windows to identify these env variables you can just set the stage here by adjusting the default
 */

import _ from "lodash";

import {
  devSetting,
  stagSetting,
  prodSetting,
  localhostSetting,
} from "./Setting";

let defaultSetting = {
  api: {
    url: "https://stageapi.healthyplaneat.com/healthyplaneat/api/v1/",
  },
  lat: null,
  long: null,
  google_map_key: null,
};

let siteSetting = defaultSetting;

switch (process.env.REACT_APP_ENV) {
  case "prod":
  case "production":
    siteSetting = _.extend(defaultSetting, prodSetting);
    break;
  case "stag":
  case "staging":
    siteSetting = _.extend(defaultSetting, prodSetting);
    break;
  case "local":
  case "localhost":
    siteSetting = _.extend(defaultSetting, prodSetting);
    break;
  default:
    siteSetting = _.extend(defaultSetting, prodSetting);
}
export default siteSetting;
