import { Container } from "react-bootstrap";
import React from "react";

function PrivacyPolicy() {
  return (
    <div className="body_container_wrapper home">
      <Container>
        <div className="termspolicy">
          <h4>HP</h4>
          <h4>PRIVACY POLICY</h4>
          <h6>Last Modified: May 27, 2020</h6>
          <p>
            <span>
              At Healthy Planeat (“HP”) your privacy is important to us, so we
              strive to be transparent about how we collect, use and share
              information about you. This policy is intended to help you
              understand:
            </span>
          </p>
          <ol start="1" type="1">
            <li>
              <strong>
                <span>WHAT INFORMATION WE COLLECT</span>
              </strong>
            </li>
            <li>
              <strong>
                <span>HOW WE USE INFORMATION</span>
              </strong>
            </li>
            <li>
              <strong>
                <span>HOW WE SHARE INFORMATION</span>
              </strong>
            </li>
            <li>
              <strong>
                <span>HOW WE STORE INFORMATION</span>
              </strong>
            </li>
            <li>
              <strong>
                <span>HOW TO ACCESS AND CONTROL YOUR INFORMATION</span>
              </strong>
            </li>
            <li>
              <strong>
                <span>ADDITIONAL IMPORTANT PRIVACY MATTERS</span>
              </strong>
            </li>
            <li>
              <strong>
                <span>HOW TO CONTACT US</span>
              </strong>
            </li>
          </ol>
          <p>
            <span>
              Please take a moment to read this privacy policy carefully so that
              you can understand your rights and our responsibilities regarding
              your information. This policy also explains your choices about how
              we use information about you. By using the Service, you consent to
              the collection and use of information in accordance with this
              Privacy Policy. 
            </span>
          </p>
          <p>
            <span>
              This Privacy Policy (this “Privacy Policy”) governs your use of
              the HP website, mobile application or other related services (the
              “App” or the “Services”) that was developed by HP, Inc. (the
              “Company”). The Company is sometimes referred to herein as “we,”
              “us,” or “our” and the business owner or the client (collectively
              the “User”) (as defined below) is sometimes referred to as “you,”
              “your,” or “customer. Unless otherwise defined in this Privacy
              Policy, terms used in this Privacy Policy have the same meanings
              as in our Terms of Service.
            </span>
          </p>
          <h5>1. WHAT INFORMATION WE COLLECT</h5>
          <h6>Definitions</h6>
          <p>
            <span>
              We require certain information to provide our services to you. For
              example, you must have an account in order to purchase or interact
              with the Services. When you choose to share the information below
              with us, we collect and use it to operate our Services. "Personal
              Data" means data that allows someone to identify or contact you,
              including, for example, your name, address, telephone number,
              e-mail address, travel preferences, specific expenses, as well as
              any other non-public information about you that is associated with
              or linked to any of the foregoing data. "Anonymous Data" means
              data that is not associated with or linked to your Personal Data;
              Anonymous Data does not permit the identification of individual
              persons. We collect Personal Data and Anonymous Data, as described
              below.  
            </span>
          </p>
          <p>
            <span> </span>
          </p>
          <h6>Information You Provide.</h6>
          <p>
            <span>
              When you use the Services, you will provide information that could
              be Personal Data, such 
            </span>
            <span>
              (a) your name, email address, mobile telephone number, password,
              date of birth, tax identification number, schedule, picture, and
              other registration information; (b) transaction-related
              information, such as when you book appointments with and send
              invoices to other Users or contacts; (c) information you provide
              us when you contact us for help; (d) credit card or banking
              information for making payments to and receiving payments from
              other Users or contacts; and (e) any other information you enter
              into the App.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>User Content.</span>
              </em>
            </strong>
            <span>
               The Services may include publicly accessible blogs, community
              forums, or private messaging features. The Services may also
              contain links and interactive features with various social media
              platforms (e.g., widgets). If you already use these platforms,
              their cookies may be set on your device when using our Services or
              other services. You should be aware that Personal Data which you
              voluntarily include and transmit online in a publicly accessible
              blog, chat room, social media platform or otherwise online, or
              that you share in an open forum may be viewed and used by others
              without any restrictions. We are unable to control such uses of
              your information when interacting with a social media platform,
              and by using such services you assume the risk that the Personal
              Data provided by you may be viewed and used by third parties for
              any number of purposes.
            </span>
          </p>
          <p>
            <span> </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Financial Information.</span>
              </em>
            </strong>
            <strong>
              <span> </span>
            </strong>
            <span>
              We use Stripe, Inc. (“Stripe”) to gather your Personal Data from
              financial institutions and process payments.  By using our
              Services, you grant HP and Stripe the right, power and authority
              to act on your behalf to access and transmit your personal and
              financial information from the relevant financial institution.
               You agree to your personal and financial information being
              transferred, stored and processed by Stripe in accordance with
              the 
            </span>
            <a
              href="https://stripe.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Stripe Privacy Policy</span>
            </a>
            <span>.  </span>
          </p>
          <h6>Information We Collect Automatically </h6>
          <p>
            <span>
              We automatically receive and record information from your use of
              the Services, including
            </span>
            <span>
               but not limited to:{" "}
              <span>
                app usage, your IP address browser type, Internet service
                provider, referring/exit pages, operating system, date/time
                stamp, clickstream data, and cookie information. This
                information is used to optimize your user experience. Generally,
                the Services automatically collect usage information, such as
                the number and frequency of users of the Services. We may use
                this data in aggregate form, that is, as a statistical measure,
                but not in a manner that would identify you personally. This
                type of aggregate data enables us and third parties authorized
                by us to figure out how often individuals use parts of the
                Services so that we can analyze and improve them.
              </span>
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Cookies.</span>
              </em>
            </strong>
            <span>
               Cookies are small pieces of information that a website sends to
              your computer’s hard drive while you are viewing the website. We
              may use both session cookies (which expire once you close your
              browser) and persistent cookies (which stay on your
              computer/mobile phone until you delete them) to provide you with a
              more personal and interactive experience on our Site.  We use
              cookies to enable our servers to recognize your web browser and
              tell us how and when you use the Services. Most browsers have an
              option for turning off the cookie feature, which will prevent your
              browser from accepting new cookies, as well as (depending on the
              sophistication of your browser software) allowing you to decide on
              acceptance of each new cookie in a variety of ways. We strongly
              recommend that you leave cookies active, because they enable you
              to take advantage of the most attractive features of the
              Services. 
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Pixel Tags</span>
              </em>
            </strong>
            <strong>
              <span>.</span>
            </strong>
            <span>
               Pixel Tags (also referred to as clear Gifs, Web beacons, or Web
              bugs). Pixel Tags are tiny graphic images with a unique
              identifier, similar in function to Cookies, that are used to track
              online movements of Web users. In contrast to Cookies, which are
              stored on a user’s computer hard drive, Pixel Tags are embedded
              invisibly in Web pages. Pixel Tags also allow us to send e-mail
              messages in a format users can read, and they tell us whether
              e-mails have been opened to ensure that we are sending only
              messages that are of interest to our users. The use of a pixel
              allows us to record, for example, that a user has visited a
              particular web page or clicked on a particular advertisement.
              Advertisements served through the Services may be targeted to
              users who fit a certain general profile category may be based on
              Anonymous Data inferred from information provided to us by a user,
              may be based on the Services usage patterns of particular users,
              or may be based on your activity on Third Party Services. We
              currently do not provide Personal Data to any ad networks for use
              outside of the Services. To increase the effectiveness of ad
              delivery, we may deliver a file pixel tag from an ad network to
              you through the Services. These pixel tags allow ad networks to
              provide anonymized, aggregated auditing, research and reporting
              for us and for advertisers. Web beacons also enable ad networks to
              serve targeted advertisements to you when you visit other
              websites. Because your web browser must request these
              advertisements and pixel tags from the ad network’s servers, these
              companies can view, edit or set their own cookies, just as if you
              had requested a web page from their site.
            </span>
          </p>
          <p>
            <span> </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Social Media Widgets.</span>
              </em>
            </strong>
            <span>
               Our Website includes social media features such as the Facebook
              “Like” button that might include widgets such as the share this
              button or other interactive mini-programs. These features may
              collect your IP address, which page you are visiting on our site,
              and may set a cookie to enable the feature to function properly.
              These social media features are either hosted by a third party or
              hosted directly on our Website. Your interactions with these
              features are governed by the privacy policy of the company
              providing it.
            </span>
          </p>
          <p>
            <span> </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Mobile Services.</span>
              </em>
            </strong>
            <span>
               We may also collect Anonymous Data from your mobile device if you
              have downloaded our application(s). This information is generally
              used to help us deliver the most relevant information to you.
              Examples of information that may be collected and used include
              your geographic location, how you use the Application(s), and
              information about the type of device you use. In addition, in the
              event our Application(s) crash on your mobile device, we will
              receive information about your mobile device model software
              version and device carrier, which allows us to identify and fix
              bugs and otherwise improve the performance of our Application(s).
              This information is sent to us as aggregated information and is
              not traceable to any individual and cannot be used to identify an
              individual.
            </span>
          </p>
          <p>
            <span> </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Analytics.</span>
              </em>
            </strong>
            <span>
               We use third party analytics services, including but not limited
              to Google Analytics ("Analytics Services"), to help analyze how
              users use our Site and Services. We use the information we get
              from Analytics Services only to improve our Site and Services. The
              information generated by the Cookies or other technologies about
              your use of our Site and Services (the "Analytics Information") is
              transmitted to the Analytics Services. The Analytics Services use
              Analytics Information to compile reports on user activity. The
              Analytics Services may also transfer the Analytics Information to
              third parties where required to do so by law, or where such third
              parties process Analytics Information on their behalf. Each
              Analytics Service’s ability to use and share Analytics Information
              is restricted by such Analytics Service’s Terms of Use and Privacy
              Policy. By using our Site and Services, you consent to the
              processing of data about you by Analytics Services in the manner
              and for the purposes set out above. For a full list of Analytics
              Services, please contact us at 
            </span>
            <span>[[email]].</span>
          </p>
          <p>
            <span> </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Third Party Data.</span>
              </em>
            </strong>
            <span>
               We may receive Personal and/or Anonymous Data about you from
              companies that provide our Services by way of a co-branded or
              private-labeled website or companies that offer their products
              and/or services on our Services. These third party companies may
              supply us with Personal Data. We may add this information to the
              information we have already collected from you via our Services in
              order to improve the Services we provide.
            </span>
          </p>
          <p>
            <span> </span>
          </p>
          <h5>2. HOW WE USE THE INFORMATION</h5>
          <p>
            <span>
              While we consider the collection and processing of your
              information to be in our legitimate business interests we take
              your privacy rights seriously. We use information about you for a
              number of purposes. Below are the specific purposes for which we
              use the information we collect about you.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>
                  To provide the Services and personalize your experience. 
                </span>
              </em>
            </strong>
            <span>
              We use information about you to provide the Services to you,
              including to process payment transactions with you, authenticate
              you when you log in, provide customer support, and operate and
              maintain the Services. 
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>For research and development. </span>
              </em>
            </strong>
            <span>
               We are always looking for ways to make our Services smarter,
              faster, more secure, integrated and useful to you. To that end, we
              use collective learnings about how people use our Services and
              feedback provided directly to us to troubleshoot and to identify
              trends, usage, activity patterns and areas for integration and
              improvement of the Services. 
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>To communicate with you about the Services. </span>
              </em>
            </strong>
            <span>
               We use your contact information to send transactional
              communications via email 
            </span>
            <span>
              or chat{" "}
              <span>
                and within the Services, including confirming your purchases,
                reminding you of subscription expirations, ensuring accur
              </span>
              ate and timely payments are processed,
              <span>
                 responding to your comments, questions and requests, providing
                customer support, and sending you technical notices, product
                updates, security alerts, and administrative messages. 
              </span>
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>
                  To market, promote, and drive engagement with the Services. 
                </span>
              </em>
            </strong>
            <span>
               We use your contact information and information about how you use
              the Services to send promotional communications that may be of
              specific interest to you, including by email and by displaying ads
              on other companies' websites and applications, as well as on
              platforms like Facebook and Google. These communications are aimed
              at driving engagement and maximizing what you get out of the
              Services, including information about new features, survey
              requests, events and other information we think may be of interest
              to you. We also communicate with you about new product offers,
              promotions and contests. 
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>
                  Conducting surveys and collecting feedback about our Services.
                </span>
              </em>
            </strong>
            <em>
              <span> </span>
            </em>
            <span>
              We do this to pursue our legitimate interests to understand if the
              Services are helpful to you and to evaluate the effectiveness of
              any updates we provide.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Customer support.</span>
              </em>
            </strong>
            <span>
               We use your information to resolve technical issues you
              encounter, to respond to your requests for assistance, to analyze
              crash information, and to repair and improve the Services.  We 
            </span>
            <span>
              also use your information to provide requested assistance (either
              originated from you, or a person acting legitimately on your
              behalf)<span> </span>and to offer proactive support in case of
              itinerary changes.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>For safety and security.</span>
              </em>
            </strong>
            <span>
               We use information about you and your Service use to verify
              accounts and activity, to monitor suspicious or fraudulent
              activity and to identify violations of Service policies. 
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>
                  To protect our legitimate business interests and legal rights.
                </span>
              </em>
            </strong>
            <strong>
              <span> </span>
            </strong>
            <span>
              Where required by law, where we believe it is in our legitimate
              business interest, or where it is necessary to protect our legal
              rights, interests and the interests of others, we use information
              about you in connection with legal claims, compliance, regulatory,
              and audit functions, and disclosures in connection with the
              acquisition, merger or sale of a business.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>With your consent.</span>
              </em>
            </strong>
            <span>
               We use information about you where you have given us consent to
              do so for a specific purpose not listed above. For example, we may
              publish testimonials or featured customer stories to promote the
              Services, with your permission.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Additional purposes.</span>
              </em>
            </strong>
            <strong>
              <span> </span>
            </strong>
            <span>
              We may process your information for any other purpose disclosed to
              you in connection with our Services from time to time. If we
              intend to process your personal data for a purpose other than that
              set out above, we will provide you with information prior to such
              processing and will obtain your consent where necessary.
            </span>
          </p>
          <p>
            <span> </span>
          </p>
          <h5>3. HOW WE SHARE INFORMATION</h5>
          <p>
            <span>
              We may share your information with our third-party service
              providers, to comply with legal obligations, to protect and defend
              our rights and property, or with your permission. Below are the
              specific ways we share information we collect about you.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Mobile Communications.</span>
              </em>
            </strong>
            <span>
               As part of the Services, you will receive text, email and other
              communications from other Users or us. In the event that you
              communicate through text, standard messaging rates apply.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>User Profile Information.</span>
              </em>
            </strong>
            <span>
               User profile information including your username and other
              information you enter may be displayed to other users to
              facilitate user interaction within the Services. We will not
              directly reveal user email addresses to other users.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Company</span>
              </em>
            </strong>
            <span>
              . In the event that you use the Services in connection with a
              company, we may share your Personal Data with the company and the
              company administrator(s).
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Information Shared with Our Agents.</span>
              </em>
            </strong>
            <span>
               We employ and contract with people and other entities that
              perform certain tasks on our behalf and who are under our control
              (our “Agents”). We may need to share Personal Data with our Agents
              in order to provide products or services to you. Unless we tell
              you differently, our Agents do not have any right to use Personal
              Data or other information we share with them beyond what is
              necessary to assist us. You hereby consent to our sharing of
              Personal Data with our Agents.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>IP Address Information</span>
              </em>
            </strong>
            <strong>
              <span>.</span>
            </strong>
            <span>
               While we collect and store IP address information, that
              information is not made public. We do at times, however, share
              this information with our partners, service providers and other
              persons with whom we conduct business, and as otherwise specified
              in this Privacy Policy.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Aggregate Information</span>
              </em>
            </strong>
            <strong>
              <span>.</span>
            </strong>
            <span>
               We share Aggregate Information with our partners, service
              providers and other persons with whom we conduct business. We
              share this type of statistical data so that our partners can
              understand how and how often people use our Services and their
              services or websites, which facilitates improving both their
              services and how our Services interface with them. In addition,
              these third parties may share with us non-private, aggregated or
              otherwise non-Personal Data about you that they have independently
              developed or acquired.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Interest-Based Advertising.</span>
              </em>
            </strong>
            <span>
               We may allow third-party advertising partners to set tracking
              tools (e.g., cookies) to collect information regarding your
              activities (e.g., your IP address, page(s) visited, time of day).
              We may also share such de-identified information as well as
              selected Personal Data (such as demographic information and past
              purchase history) we have collected with third-party advertising
              partners. These advertising partners may use this information (and
              similar information collected from other websites) for purposes of
              delivering targeted advertisements to you when you visit other
              websites within their networks. This practice is commonly referred
              to as “interest-based advertising” or “online behavioral
              advertising. We may allow access to other data collected by the
              Site to facilitate transmittal of information that may be useful,
              relevant, valuable or otherwise of interest to you. 
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>
                  Information Disclosed Pursuant to Business Transfers
                </span>
              </em>
            </strong>
            <strong>
              <span>.</span>
            </strong>
            <span>
               In some cases, we may choose to buy or sell assets. In these
              types of transactions, user information is typically one of the
              transferred business assets. Moreover, if we, or substantially all
              of our assets, were acquired, or if we go out of business or enter
              bankruptcy, user information would be one of the assets that is
              transferred or acquired by a third party. You acknowledge that
              such transfers may occur, and that any acquirer of us or our
              assets may continue to use your Personal Data as set forth in this
              policy.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>
                  Information Disclosed for Our Protection and the Protection of
                  Others
                </span>
              </em>
            </strong>
            <strong>
              <span>.</span>
            </strong>
            <span>
               We also reserve the right to access, read, preserve, and disclose
              any information as it reasonably believes is necessary to (i)
              satisfy any applicable law, regulation, legal process or
              governmental request, (ii) enforce these Terms of Service,
              including investigation of potential violations hereof, (iii)
              detect, prevent, or otherwise address fraud, security or technical
              issues, (iv) respond to user support requests, or (v) protect our
              rights, property or safety, our users and the public. This
              includes exchanging information with other companies and
              organizations for fraud protection and spam/malware prevention.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Information We Share with Your Consent</span>
              </em>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span>
               We will share your Personal Data with any relevant party you have
              granted us permission to share with. 
            </span>
          </p>
          <p>
            <span> </span>
          </p>
          <h5>4. HOW WE STORE AND SECURE INFORMATION</h5>
          <p>
            <span>
              Keeping your information secure is a top priority for us. To that
              end we comply with industry-standard best practices to secure your
              information. We use data hosting service providers in the United
              States to host the information we collect, and we use technical
              measures to secure your data. While we implement safeguards
              designed to protect your information, no security system is
              impenetrable and due to the inherent nature of the Internet, we
              cannot guarantee that data, during transmission through the
              Internet or while stored on our systems or otherwise in our care,
              is absolutely safe from intrusion by others. How long we keep
              information we collect about you depends on the type of
              information, as described in further detail below. After such
              time, we will either delete or anonymize your information or, if
              this is not possible (for example, because the information has
              been stored in backup archives), then we will securely store your
              information and isolate it from any further use until deletion is
              possible.
            </span>
          </p>
          <p>
            <strong>
              <span>
                <i>Account Information</i>
              </span>
            </strong>
            <span>
              .  
              <span>
                We retain your personal information only as long as necessary to
                accomplish the business purpose for which it was collected or to
                comply with our legal and contractual obligations, plus 1 year,
                and then securely disposes of that information. We also retain
                some of your information as necessary to comply with our legal
                obligations, to resolve disputes, to enforce our agreements, to
                support business operations and to continue to develop and
                improve our Services. Where we retain information for Service
                improvement and development, we take steps to eliminate
                information that directly identifies you, and we only use the
                information to uncover collective insights about the use of our
                Services, not to specifically analyze personal characteristics
                about you.
              </span>
            </span>
          </p>
          <p>
            <strong>
              <i>Information You Share on the Services</i>
            </strong>
            <span>
               If your account is deactivated or disabled, some of your
              information and the content you have provided will remain.
            </span>
          </p>
          <p>
            <strong>
              <span>Marketing information</span>
            </strong>
            <span>
               If you have elected to receive marketing emails from us, we
              retain information about your marketing preferences unless you
              specifically ask us to delete such information. We retain
              information derived from cookies and other tracking technologies
              for a reasonable period of time from the date such information was
              created.
            </span>
          </p>
          <p>
            <span> </span>
          </p>
          <h5>5. HOW TO ACCESS AND CONTROL YOUR INFORMATION</h5>
          <p>
            <span>
              You can stop all collection of information by logging off the
              Website or by uninstalling the App. You may use the standard
              uninstall processes as may be available as part of your mobile
              device or via the mobile application marketplace or network.
            </span>
          </p>
          <h5>6. ADDITIONAL IMPORTANT PRIVACY MATTERS</h5>
          <p>
            <strong>
              <em>
                <span>Minimum Age. </span>
              </em>
            </strong>
            <span>
              The Services are not directed to individuals under 16. We do not
              knowingly collect Personal Data from children under 16. If we
              become aware that a child under 16 has provided us with Personal
              Data, we will take steps to delete such information. If you become
              aware that a child has provided us with Personal Data, please
              contact our support services.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Security:</span>
              </em>
            </strong>
            <span>
               We are concerned about safeguarding the confidentiality of your
              Personal Information. We provide physical, electronic, and
              procedural safeguards to protect information we process and
              maintain and use a combination of firewall barriers, encryption
              techniques and authentication procedures. For example, we limit
              access to your Personal Information to authorized employees and
              contractors who need to know that information in order to operate,
              develop or improve our App. Please be aware that, although we
              endeavor to provide reasonable security for information we process
              and maintain, no security system can prevent all potential
              security breaches.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>California Privacy Rights. </span>
              </em>
            </strong>
            <span>
              California law permits users who are California residents to
              request and obtain from us once a year, free of charge, a list of
              the third-parties to whom we have disclosed their Personal Data
              (if any) for their direct marketing purposes in the prior calendar
              year, as well as the type of Personal Data disclosed to those
              parties. 
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Links to Other Sites.</span>
              </em>
            </strong>
            <strong>
              <span> </span>
            </strong>
            <span>
              Our Service may contain links to other sites that are not operated
              by us. If you click on a third-party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit. We have no control over,
              and assume no responsibility for the content, privacy policies or
              practices of any third-party sites or services.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Changes to this Privacy Policy.</span>
              </em>
            </strong>
            <strong>
              <span> </span>
            </strong>
            <span>
              We will notify you when we change this Privacy Policy. We may
              change this Privacy Policy from time to time. If we make
              significant changes in the way we treat your Personal Data, or to
              the Privacy Policy, we will provide notice to you on the Services
              or by some other means, such as email or an in-app notice. Please
              review the changes carefully. If you agree to the changes, simply
              continue to use our Services.  
            </span>
            <span>
              Your continued use of our Services after the revised Statement has
              become effective indicates that you have read, understood, and
              agreed to the current version of this Policy.  
              <span>
                If you object to any of the changes to our terms and you no
                longer wish to use our Services, you may close your account(s).
                Unless stated otherwise, our current Privacy Policy applies to
                all information that we have about you and your account. Using
                our Services after a notice of changes has been communicated to
                you or published on our Services shall constitute consent to the
                changed terms or practices.
              </span>
            </span>
          </p>
          <p>
            <span> </span>
          </p>
          <h5>
            7<span>. CONTACT US</span>
          </h5>
          <p>
            <strong>
              <em>
                <span> </span>
              </em>
            </strong>
          </p>
          <p>
            <strong>
              <em>
                <span>Contacting us:</span>
              </em>
            </strong>
            <span>
               If you have any questions, please don’t hesitate to contact us
              at: 
            </span>
            <a href="mailto:info@healthyplaneat.com">
              <span>info@healthyplaneat.com</span>
            </a>
          </p>
          <p>
            <span> </span>
          </p>
          <p>
            <strong>
              <span> </span>
            </strong>
          </p>
          <p>
            <span> </span>
          </p>
          <p>
            <span> </span>
          </p>
          <p>
            <span> </span>
          </p>
          <p>
            <span> </span>
          </p>
        </div>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
