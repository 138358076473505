/**
 * @About
 *
 */

export default {
  USER_SIGNUP: { url: `user/signup`, method: "POST" },
  USER_LOGIN: { url: `user/login`, method: "POST" },
  USER_NEWS_LETTER_SIGNUP: {
    url: `newsletter/newsletterSignup`,
    method: "POST",
  },
  USER_MAP_EXPLORE: { url: `user/exploreOnMap`, method: "GET" },
  USER_FORGOT_PASSWORD: { url: `user/forgetpassword`, method: "POST" },
  USER_RESET_PASSWORD: { url: `user/resetpassword`, method: "POST" },
  USER_GET_FARM: { url: `farm/getFarm`, method: "GET" },
  USER_ALL_FARM_DISTRIBUTIONS_INVENTORY: {
    url: `farm/getAllFarmDistributionsInventory`,
    method: "GET",
  },
  FARMER_SIGNUP: { url: `admin/addFarmer`, method: "POST" },
  GET_USER_PROFILE: { url: `user/getProfile`, method: "GET" },
  USER_GET_FARM_BY_FILTER: { url: `farm/getFarmStateCity`, method: "GET" },
  USER_GET_FARM_BY_IDS: { url: `farm/viewFarmInfoByIds`, method: "GET" },
  USER_VIEW_ALL_FRAM_DETAILS: { url: `farm/viewFarmDetails`, method: "GET" },
  USER_GET_MARKET_POPUP_DETAILS: { url: `market/getMarkets`, method: "GET" },
  USER_VIEW_MARKET_POPUP_DETAILS: {
    url: `market/viewFarmsByMarket`,
    method: "GET",
  },
  USER_ADD_ADDRESS: { url: `user/addAddress`, method: "POST" },
  USER_VIEW_FARM_BY_MARKET_DETAILS: {
    url: `market/viewFarmsByMarket`,
    method: "GET",
  },
  USER_GET_INVEBTERY_VIEW_DETAILS_BY_ID: {
    url: `inventory/getViewDetails`,
    method: "GET",
  },
  USER_GET_INVENTORY_BY_DISTRIBUTION: {
    url: "inventory/getInventoryByDistribution",
    method: "GET",
  },
  USER_ADD_PRODUCT_TO_CART: { url: `addCart/add`, method: "POST" },
  USER_EDIT_PRODUCT_QTY: { url: `addCart/updateQty`, method: "POST" },
  USER_UPDATE_CART: { url: `addCart/updateCart`, method: "POST" },
  USER_UPDATE_CART_DELIVERY_INFO: {
    url: `addCart/updateCartDeliveryInfo`,
    method: "POST",
  },
  USER_GET_CART_LIST: { url: `addCart/getCart`, method: "GET" },
  USER_DELETE_PRODUCT_TO_CART: { url: `addCart/deleteCart`, method: "POST" },
  USER_GET_ALL_FARMER_LIST: { url: `user/getAllFarmer`, method: "GET" },
  USER_GET_RECIPES_LIST: { url: `recipes/getRecipes`, method: "GET" },
  USER_GET_RECIPES_VIEW_DETAILS: { url: `recipes/viewRecipes`, method: "POST" },
  USER_GET_ORDER_LIST: { url: `payment/getOrder`, method: "GET" },
  USER_ORDER_CANCEL_STRIPE_SUBSCRIPTION: {
    url: `payment/cancelStripeSubscription`,
    method: "POST",
  },
  USER_EDIT_ADDRESS: { url: `user/editAddress`, method: "POST" },
  USER_EDIT_USER_PROFILE: { url: `user/editProfile`, method: "POST" },
  USER_CHANGE_PASSWORD: { url: `user/changpassword`, method: "POST" },
  USER_LOGOUT: { url: `user/logout`, method: "POST" },

  GET_USER_STRIPE_CUSTOMER_INFO: { url: `user/getCustomerInfo`, method: "GET" },
  GET_USER_STRIPE_CARDS_INFO: { url: `user/getCard`, method: "GET" },
  USER_STRIPE_ADD_CARD: { url: `user/addCard`, method: "POST" },
  USER_STRIPE_CARD_CHANGE: { url: `user/cardChange`, method: "POST" },
  USER_STRIPE_CARD_REMOVE: { url: `user/removeCard`, method: "POST" },
  USER_PAYMENT: { url: `payment/charge`, method: "POST" },
  USER_PAYMENT_BY_INVOICE: { url: `payment/chargeByInvoice`, method: "POST" },
};
