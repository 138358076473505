import { Button, Card, Col, Container, Row } from "react-bootstrap";
import React, { Component } from "react";

import Banner from "../banner";
import { Empty } from "antd";
import Joinourfoodmovement from "../home/Joinourfoodmovement";
import Loader from "../../components/common/Loader";
import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";
import { notify } from "../../components/common/Tooster.js";

class Recipes extends Component {
  constructor(props) {
    super(props);
    this.state = { recipesList: "", isLoader: false };
  }
  componentDidMount() {
    this.getRecipesList();
  }
  getRecipesList = () => {
    this.setState({ isLoader: true });
    _Api(
      Url.USER_GET_RECIPES_LIST.method,
      Url.USER_GET_RECIPES_LIST.url,
      "",
      `?recipesStatus=1`,
    )
      .then((resp) => {
        this.setState({
          recipesList: resp.responseData.result.recipesList,
          isLoader: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        notify("error", err.error.responseMessage);
      });
  };
  render() {
    let { recipesList, isLoader } = this.state;
    return (
      <div className="body_container_wrapper no_pad recipies">
        <Banner
          heading="Recipes"
          bannerbg={`url(${require("../../assets/img/Recipes.png")})`}
        />
        <Container>
          <div className="who_we_are">
            <div className="heading">
              <h3>
                <span>Recipes</span>
              </h3>
              <p>Get inspired by your local farmers!</p>
            </div>
            {isLoader && <Loader />}
          </div>
          <div className="recipieslist_wrapper">
            <Row>
              {recipesList &&
                recipesList.map((value) => {
                  return (
                    <Col md="4">
                      <Card>
                        <Card.Img variant="top" src={value.image} />
                        <Card.Body>
                          <Card.Title>{value.recipesName}</Card.Title>
                          {/* <Card.Text>
                                                    {value.description}
                                                </Card.Text> */}
                        </Card.Body>
                        <Card.Footer>
                          <Button
                            variant="outline-info"
                            type="button"
                            onClick={() =>
                              (window.location.href = `/recipes/recipeslist/${value._id}`)
                            }
                          >
                            View
                          </Button>
                        </Card.Footer>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </div>
          {this.state.recipesList && this.state.recipesList.length ? (
            ""
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Container>
        <Joinourfoodmovement />
      </div>
    );
  }
}

export default Recipes;
