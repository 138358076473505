import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  StripeProvider,
  injectStripe,
} from "react-stripe-elements";
import React, { Component } from "react";

import Loader from "../../components/common/Loader";
import OrderSummaryItems from "./ordersummaryitems";
import Url from "../../services/BaseUrl";
import _, { remove, update } from "lodash";
import { _Api } from "../../services/Api";
import { connect } from "react-redux";
import { notify } from "../../components/common/Tooster";
import siteSetting from "../../configs/env/Index";
import { CiEdit } from "react-icons/ci";
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

class _SplitFieldsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToHome: false,
      isLoader: false,
      show: false,
      minPurchaseOrder: 0,
      profileInfo: [],

      firstName: "",
      lastName: "",
      organization: "",
      address1: "",
      address2: "",
      zip: "",
      city: "",
      phone: "",
      country: "",
      state: "",

      errors: "",
      cardHolderErrorMsg: "",
      addCardOption: false,
      editCardOption: false,
      cardId: "",
      cardInfo: [],
      subscribtionInfo: [],
      addcarderrmsg: "",
      minAmountMsg: false,

      paidByInvoice: false,
    };
  }

  RemovehandleClose = () => {
    this.setState({ minAmountMsg: false });
  };

  componentWillReceiveProps(nextProps, nextState) {
    let self = this;
    if (
      nextProps &&
      nextProps.profiledetails &&
      nextProps.profiledetails.profileInfo &&
      nextProps.profiledetails.profileInfo._id
    ) {
      this.setState(
        { profileInfo: nextProps.profiledetails.profileInfo },
        function () {
          this.setBillingInfo();
        },
      );
    }
  }

  componentWillMount() {
    // when menu change
    let self = this;
    let getProps = this.props;
    if (
      getProps &&
      getProps.profiledetails &&
      getProps.profiledetails.profileInfo &&
      getProps.profiledetails.profileInfo._id
    ) {
      this.setState(
        { profileInfo: getProps.profiledetails.profileInfo },
        function () {
          this.setBillingInfo();
        },
      );
    }
  }

  componentDidMount() {
    let self = this;
    let getProps = this.props;
    if (
      getProps &&
      getProps.profiledetails &&
      getProps.profiledetails.profileInfo &&
      getProps.profiledetails.profileInfo._id
    ) {
      this.setState(
        { profileInfo: getProps.profiledetails.profileInfo },
        function () {
          this.setBillingInfo();
        },
      );
    }

    this.getUserCustomerInfo();
  }

  setBillingInfo = () => {
    let { profileInfo } = this.state;
    if (profileInfo && profileInfo.firstName) {
      this.setState({
        firstName: profileInfo.firstName,
        lastName: profileInfo.lastName,
        phone: profileInfo.phone,
      });

      if (
        profileInfo.address &&
        profileInfo.address.length > 0 &&
        profileInfo.address[0].addressLine1
      ) {
        this.setState({
          address1: profileInfo.address[0].addressLine1,
          address2: profileInfo.address[0].addressLine2,
          city: profileInfo.address[0].city,
          state: profileInfo.address[0].state,
          zip: profileInfo.address[0].postalCode,
          country: "United States",
        });
      }
    }
  };

  getUserCustomerInfo = () => {
    let self = this;
    self.setState({ isLoader: true });

    _Api(
      Url.GET_USER_STRIPE_CUSTOMER_INFO.method,
      Url.GET_USER_STRIPE_CUSTOMER_INFO.url,
    )
      .then((response) => {
        if (response.statusCode == 1) {
          let cardId = "";
          if (
            response.responseData.result.invoice_settings.default_payment_method
          ) {
            cardId =
              response.responseData.result.invoice_settings
                .default_payment_method;
          } else {
            cardId = response.responseData.result.default_source;
          }

          // Filter out other payment methods such as "sources",
          // we only want to display cards
          let cardInfo = response.responseData.result.sources.data.filter(
            (card) => card.object === "card",
          );
          let subscribtionInfo =
            response.responseData.result.subscriptions.data;
          self.setState({
            cardId: cardId,
            cardInfo: cardInfo,
            subscribtionInfo: subscribtionInfo,
            isLoader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
      });
  };

  handleChckoutClose = () => {
    window.location.href = "/";
  };

  handleChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    });
  };

  addCardforPayment(e) {
    this.setState({ addCardOption: true });
  }

  updateCardforPayment(item, e) {
    console.log(item.name);
    this.setState({
      editCardOption: true,
      addCardOption: true,
      cardHoldeName: item.name,
      cardToRemove: item.id,
    });
  }

  selectCard(item, e) {
    let self = this;
    self.setState({ cardId: item.id });
  }

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  handleNameSave = (e) => {
    let self = this;
    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + "ErrorMsg"]: "",
        errorMessage: "",
        cardHolderErrorMsg: "",
      },
      () => this.setState({ errors: "" }),
    );
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    this.setState({ addcarderrmsg: "", cardHolderErrorMsg: "" });
    if (this.props.stripe) {
      let self = this;
      let { cardHoldeName, cardHolderErrorMsg, cardToRemove, editCardOption } =
        this.state;
      if (cardHoldeName === "" || cardHoldeName === undefined) {
        this.setState({ cardHolderErrorMsg: "Please enter card holder name." });
      } else {
        this.props.stripe.createToken({ name: cardHoldeName }).then((res) => {
          if (res.error) {
            this.setState({ loader: false, addcarderrmsg: res.error.message });
          } else {
            this.setState({ isLoader: true });
            let cardtoken = res.token.id;
            let req = {
              source: cardtoken,
            };
            _Api(
              Url.USER_STRIPE_ADD_CARD.method,
              Url.USER_STRIPE_ADD_CARD.url,
              req,
            )
              .then((resp) => {
                self.setState({ isLoader: false });
                if (resp.statusCode == 1) {
                  this.getUserCustomerInfo();
                  this.setState({
                    cardHolderErrorMsg: "",
                    addCardOption: false,
                  });
                  if (editCardOption) {
                    let req = {
                      cardId: cardToRemove,
                    };

                    _Api(
                      Url.USER_STRIPE_CARD_REMOVE.method,
                      Url.USER_STRIPE_CARD_REMOVE.url,
                      req,
                    )
                      .then((resp) => {
                        this.setState({
                          editCardOption: false,
                          openCardRemoveSuccessPopup: true,
                        });
                        if (resp.statusCode === 1) {
                          self.getUserCustomerInfo();
                        } else {
                          self.setState({
                            addcarderrmsg: resp.error.responseMessage,
                          });
                        }
                      })
                      .catch((err) => {
                        self.setState({
                          addcarderrmsg: resp.error.responseMessage,
                        });
                        notify("error", err.error.responseMessage);
                      });
                  }
                } else {
                  self.setState({ addcarderrmsg: resp.error.responseMessage });
                }
              })
              .catch((err) => {
                this.setState({ isLoader: false });
                notify("error", err.error.responseMessage);
              });
          }
        });
      }
    } else {
    }
  };

  handleValidateInvoice = () => {
    let validate = false;
    let errors = [];

    let {
      cardInfo,
      cardId,
      firstName,
      lastName,
      organization,
      address1,
      address2,
      zip,
      city,
      phone,
      country,
      state,
    } = this.state;
    this.setState({ errors: "" });
    // Removed validation for card if a customer is trying to checkout with
    // the invoice feature

    if (_.isEmpty(firstName)) {
      validate = true;
      errors["firstName"] = "First name is required.";
    }
    if (_.isEmpty(lastName)) {
      validate = true;
      errors["lastName"] = "Last name is required.";
    }
    if (_.isEmpty(address1)) {
      validate = true;
      errors["address1"] = "Address 1 is required.";
    }
    if (_.isEmpty(zip)) {
      validate = true;
      errors["zip"] = "Zip is required.";
    }
    if (_.isEmpty(city)) {
      validate = true;
      errors["city"] = "City is required.";
    }
    if (_.isEmpty(phone)) {
      validate = true;
      errors["phone"] = "Phone is required.";
    }
    if (_.isEmpty(country)) {
      validate = true;
      errors["country"] = "Country is required.";
    }
    if (_.isEmpty(state)) {
      validate = true;
      errors["state"] = "State is required.";
    }
    this.setState({ errors });
    return validate;
  };

  handleValidate = () => {
    let validate = false;
    let errors = [];

    let {
      cardInfo,
      cardId,
      firstName,
      lastName,
      organization,
      address1,
      address2,
      zip,
      city,
      phone,
      country,
      state,
    } = this.state;
    this.setState({ errors: "" });

    if (_.isEmpty(cardId)) {
      validate = true;
      if (cardInfo.length > 0) {
        errors["cardId"] = "Please select card or add card for payment.";
      } else {
        errors["addCard"] = "Please add card for payment.";
      }
      this.setState({ errors });
      return validate;
    }

    if (_.isEmpty(firstName)) {
      validate = true;
      errors["firstName"] = "First name is required.";
    }
    if (_.isEmpty(lastName)) {
      validate = true;
      errors["lastName"] = "Last name is required.";
    }
    if (_.isEmpty(address1)) {
      validate = true;
      errors["address1"] = "Address 1 is required.";
    }
    if (_.isEmpty(zip)) {
      validate = true;
      errors["zip"] = "Zip is required.";
    }
    if (_.isEmpty(city)) {
      validate = true;
      errors["city"] = "City is required.";
    }
    if (_.isEmpty(phone)) {
      validate = true;
      errors["phone"] = "Phone is required.";
    }
    if (_.isEmpty(country)) {
      validate = true;
      errors["country"] = "Country is required.";
    }
    if (_.isEmpty(state)) {
      validate = true;
      errors["state"] = "State is required.";
    }
    this.setState({ errors });
    return validate;
  };

  handleCheckout = () => {
    let {
      minPurchaseOrder,
      cardId,
      firstName,
      lastName,
      organization,
      address1,
      address2,
      zip,
      city,
      phone,
      country,
      state,
    } = this.state;
    let totalPayAmount = localStorage.getItem("totalPayAmount");

    if (!this.handleValidate()) {
      if (totalPayAmount < minPurchaseOrder) {
        this.setState({ minAmountMsg: true });
        return false;
      } else if (
        totalPayAmount == 0 ||
        totalPayAmount == "" ||
        totalPayAmount == null ||
        totalPayAmount == "undefined"
      ) {
        this.setState({ minAmountMsg: true });
        return false;
      } else {
        let req = {
          stripeCardId: cardId,
          firstName: firstName,
          lastName: lastName,
          organization,
          addressLine1: address1,
          addressLine2: address2,
          city: city,
          postalCode: zip,
          phoneNumber: phone,
          country: country,
          state: state,
        };

        this.setState({ isLoader: true });
        _Api(Url.USER_PAYMENT.method, Url.USER_PAYMENT.url, req)
          .then((resp) => {
            this.setState({ isLoader: false, show: true });
          })
          .catch((err) => {
            this.setState({ isLoader: false });
            notify("error", err.error.responseMessage);
          });
      }
    }
  };

  handleCheckoutByInvoice = () => {
    let {
      minPurchaseOrder,
      cardId,
      firstName,
      lastName,
      organization,
      address1,
      address2,
      zip,
      city,
      phone,
      country,
      state,
    } = this.state;
    let totalPayAmount = localStorage.getItem("totalPayAmount");

    if (!this.handleValidateInvoice()) {
      if (totalPayAmount < minPurchaseOrder) {
        this.setState({ minAmountMsg: true });
        return false;
      } else if (
        totalPayAmount == 0 ||
        totalPayAmount == "" ||
        totalPayAmount == null ||
        totalPayAmount == "undefined"
      ) {
        this.setState({ minAmountMsg: true });
        return false;
      } else {
        let req = {
          stripeCardId: cardId,
          firstName: firstName,
          lastName: lastName,
          organization,
          addressLine1: address1,
          addressLine2: address2,
          city: city,
          postalCode: zip,
          phoneNumber: phone,
          country: country,
          state: state,
        };

        this.setState({ isLoader: true });
        _Api(
          Url.USER_PAYMENT_BY_INVOICE.method,
          Url.USER_PAYMENT_BY_INVOICE.url,
          req,
        )
          .then((resp) => {
            this.setState({ isLoader: false, show: true, paidByInvoice: true });
          })
          .catch((err) => {
            this.setState({ isLoader: false });
            notify("error", err.error.responseMessage);
          });
      }
    }
  };

  handleChangedMobile = (e) => {
    if (!isNaN(e.target.value))
      this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    let {
      errors,
      cardId,
      cardInfo,
      addCardOption,
      addcarderrmsg,
      cardHolderErrorMsg,
      editCardOption,
      priceDetails,
      profileInfo,
      paidByInvoice,
    } = this.state;

    return (
      <div className="body_container_wrapper ordersummary" id="checkout">
        {this.state.isLoader && <Loader />}
        <Container>
          <div className="oerdersumary_wrapper">
            <Row className="mt2">
              <Col md="6">
                <div className="order_wrapper_list">
                  <h6>Order Summary</h6>
                  <OrderSummaryItems />
                </div>
              </Col>
              <Col md="6">
                <div className="user_details">
                  <div className="payment-items">
                    <h6>Payment</h6>
                    <div className="paymentitem_content boxed">
                      {!_.isEmpty(cardInfo)
                        ? cardInfo.map((item, index = 0) => {
                            return (
                              <React.Fragment key={`payment-items-${index}`}>
                                <table>
                                  <tbody>
                                    <tr style={{ textAlign: "center" }}>
                                      <td>
                                        <div className="shopping_list">
                                          <div className="content">
                                            <h6>
                                              <label className="conta cmchk-box">
                                                <input
                                                  checked={
                                                    cardId === item.id
                                                      ? true
                                                      : false
                                                  }
                                                  type="checkbox"
                                                  onChange={this.selectCard.bind(
                                                    this,
                                                    item,
                                                  )}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                              <span className="cardImage mr-0">
                                                {item.brand == "Visa" ? (
                                                  <img
                                                    src={require("../../assets/img/cardicon/visa.png")}
                                                  />
                                                ) : null}
                                                {item.brand ==
                                                "Visa (debit)" ? (
                                                  <img
                                                    src={require("../../assets/img/cardicon/visaDebit.png")}
                                                  />
                                                ) : null}
                                                {item.brand == "MasterCard" ||
                                                item.brand ==
                                                  "Mastercard (2-series)" ||
                                                item.brand ==
                                                  "Mastercard (debit)" ||
                                                item.brand ==
                                                  "Mastercard (prepaid)" ? (
                                                  <img
                                                    src={require("../../assets/img/cardicon/master.png")}
                                                  />
                                                ) : null}
                                                {item.brand ==
                                                "American Express" ? (
                                                  <img
                                                    src={require("../../assets/img/cardicon/a_ex.png")}
                                                  />
                                                ) : null}
                                                {item.brand == "Diners Club" ||
                                                item.brand ==
                                                  "Diners Club (14 digit card)" ? (
                                                  <img
                                                    src={require("../../assets/img/cardicon/d_c.png")}
                                                  />
                                                ) : null}
                                                {item.brand == "JCB" ? (
                                                  <img
                                                    src={require("../../assets/img/cardicon/jcb.png")}
                                                  />
                                                ) : null}
                                                {item.brand == "UnionPay" ? (
                                                  <img
                                                    src={require("../../assets/img/cardicon/uP.png")}
                                                  />
                                                ) : null}
                                                {item.brand == "Discover" ? (
                                                  <img
                                                    src={require("../../assets/img/cardicon/discover.png")}
                                                  />
                                                ) : null}
                                              </span>
                                              ************{item.last4}
                                            </h6>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <h6 className="qty">
                                          {item.exp_month}/{item.exp_year}
                                        </h6>
                                      </td>
                                      <td>
                                        <a
                                          href="#"
                                          onClick={this.updateCardforPayment.bind(
                                            this,
                                            item,
                                          )}
                                        >
                                          <CiEdit />
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </React.Fragment>
                            );
                          })
                        : ""}
                      {!_.isEmpty(cardInfo) && !addCardOption ? (
                        <div
                          className="addcard"
                          onClick={this.addCardforPayment.bind(this)}
                        >
                          {" "}
                          Add card for payment{" "}
                        </div>
                      ) : null}
                      {_.isEmpty(cardInfo) || addCardOption ? (
                        <>
                          <form
                            className="add_stripe_card_form"
                            onSubmit={this.handleSubmit.bind(this)}
                          >
                            <div className="form-group row">
                              <div className="split-form col-sm-12 col-lg-12">
                                <label htmlFor="card"> Card number</label>
                                <label className="form-control card-numb">
                                  <CardNumberElement
                                    {...createOptions()}
                                    onChange={this.handleChange}
                                  />
                                </label>
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="split-form col-sm-12 col-lg-12">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control"
                                  value={this.state.cardHoldeName}
                                  name="cardHoldeName"
                                  id="cardHoldeName"
                                  onChange={this.handleNameSave}
                                  maxLength="35"
                                  placeholder="Card Holder Name"
                                />
                                {cardHolderErrorMsg ? (
                                  <div className="error">
                                    {cardHolderErrorMsg}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="split-form form-group row mb-0">
                                <div className="col-sm-6 cm_striped_label">
                                  <label
                                    className="form-control"
                                    htmlFor="Expiration"
                                  >
                                    Expiration date
                                    <CardExpiryElement
                                      {...createOptions()}
                                      onChange={this.handleChange}
                                    />
                                  </label>
                                </div>

                                <div className="col-sm-6 cm_striped_label ">
                                  <label className="form-control">
                                    CVC
                                    <CardCVCElement
                                      {...createOptions()}
                                      onChange={this.handleChange}
                                      className=""
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>

                            {addcarderrmsg ? (
                              <div className="error">{addcarderrmsg}</div>
                            ) : null}
                            <div className="text-left">
                              <button className="btn btn-primary btn-blue card_save">
                                {!editCardOption ? "Save" : "Update"}
                              </button>
                              <span className="error_msg">
                                {errors["addCard"]}
                              </span>
                            </div>
                          </form>
                        </>
                      ) : null}
                      <span className="error_msg">{errors["cardId"]}</span>
                      <label>Billing Address</label>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Control
                            type="text"
                            placeholder="First Name"
                            onChange={this.handleChanged}
                            name="firstName"
                            value={this.state.firstName}
                          />
                          <span className="error_msg">
                            {errors["firstName"]}
                          </span>
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            onChange={this.handleChanged}
                            name="lastName"
                            value={this.state.lastName}
                          />
                          <span className="error_msg">
                            {errors["lastName"]}
                          </span>
                        </Form.Group>
                      </Form.Row>
                      {/* 
                          If account is a school or wholesaler, display input field
                          3 - Wholesaler
                          4 - School
                      */}
                      {profileInfo.userType === 3 ||
                      profileInfo.userType === 4 ? (
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Organization"
                            onChange={this.handleChanged}
                            name="organization"
                            value={this.state.organization}
                          />
                          <span className="error_msg">
                            {errors["organization"]}
                          </span>
                        </Form.Group>
                      ) : null}
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Address 1"
                          onChange={this.handleChanged}
                          name="address1"
                          value={this.state.address1}
                        />
                        <span className="error_msg">{errors["address1"]}</span>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Address 2"
                          onChange={this.handleChanged}
                          name="address2"
                          value={this.state.address2}
                        />
                      </Form.Group>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Control
                            type="text"
                            placeholder="City"
                            onChange={this.handleChanged}
                            name="city"
                            value={this.state.city}
                          />
                          <span className="error_msg">{errors["city"]}</span>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Control
                            type="text"
                            placeholder="state"
                            onChange={this.handleChanged}
                            name="state"
                            value={this.state.state}
                          />
                          <span className="error_msg">{errors["state"]}</span>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Control
                            type="number"
                            placeholder="ZIP Code"
                            onChange={this.handleChanged}
                            name="zip"
                            value={this.state.zip}
                          />
                          <span className="error_msg">{errors["zip"]}</span>
                        </Form.Group>
                      </Form.Row>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Country"
                          onChange={this.handleChanged}
                          name="country"
                          value={this.state.country}
                        />
                        <span className="error_msg">{errors["country"]}</span>
                      </Form.Group>
                      <Form.Control
                        type="text"
                        placeholder="Phone Number"
                        maxLength={15}
                        onChange={this.handleChangedMobile}
                        name="phone"
                        value={this.state.phone}
                      />
                      <span className="error_msg">{errors["phone"]}</span>
                      <div className="confirm-button"></div>
                    </div>
                  </div>
                  <div className="payment-items">
                    <h6 className="hidden">2. Review & Purchase</h6>
                    <div className="paymentitem_content">
                      <p className="hidden">
                        Press continue if above information is correct.
                      </p>
                      <div className="confirm-button">
                        <Button
                          variant="info"
                          className="btn-lg"
                          onClick={this.handleCheckout}
                        >
                          {profileInfo.invoiceStatus == 0 &&
                          profileInfo.userType == 3
                            ? "Pay by Card"
                            : "Place Order"}
                        </Button>
                      </div>
                      {/* 
                          If invoiceStatus is 0 (active) and account is either 
                          a wholesale buyer or school, show pay by invoice option
                      */}
                      {profileInfo.invoiceStatus == 0 &&
                      profileInfo.userType == 3 ? (
                        <div className="confirm-button">
                          <div>Or</div>

                          <Button
                            variant="info"
                            className="btn-lg"
                            onClick={this.handleCheckoutByInvoice}
                          >
                            Pay by Invoice
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <div className="confirmation_popup">
          <Modal
            show={this.state.show}
            onHide={this.handleChckoutClose}
            className="cm_confirmation"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <img src={require("../../assets/img/stand.png")} alt="" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <h6>Order Placed Successfully!</h6> */}
              {paidByInvoice
                ? "Your order has been placed. You will receive an email from Stripe with your invoice shortly."
                : "Order Placed Successfully!"}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleChckoutClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <Modal
          show={this.state.minAmountMsg}
          onHide={this.RemovehandleClose}
          className="cm_confirmation imageView"
        >
          <Modal.Header closeButton>
            <Modal.Title>Minimun Amount greater than $0</Modal.Title>
          </Modal.Header>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.login.isLoggedIn,
    profiledetails: state.profile,
    cartListDetails: state.cartList.cartListDetails,
  };
}
const SplitFieldsForm = injectStripe(
  connect(mapStateToProps)(_SplitFieldsForm),
);

export default class ordersummary extends Component {
  render() {
    return (
      <StripeProvider apiKey={siteSetting.stripeKey}>
        <Elements>
          <SplitFieldsForm handleResult={this.props.handleResult} />
        </Elements>
      </StripeProvider>
    );
  }
}
