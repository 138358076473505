import { Button, Col, Form } from "react-bootstrap";
import React, { Component } from "react";

import Loader from "../../components/common/Loader";
import Url from "../../services/BaseUrl";
import _ from "lodash";
import { _Api } from "../../services/Api";
import { notify } from "../../components/common/Tooster";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      errors: [],
      userDetails: "",
      firstName: "",
      lastName: "",
      phone: "",
      profileImage: "",
    };
  }

  componentDidMount() {
    this.getUserProfile();
  }

  getUserProfile = () => {
    this.setState({ isLoader: true });
    _Api(Url.GET_USER_PROFILE.method, Url.GET_USER_PROFILE.url)
      .then((resp) => {
        this.setState({
          userDetails: resp.responseData.userProfile,
          firstName: resp.responseData.userProfile.firstName,
          lastName: resp.responseData.userProfile.lastName,
          phone: resp.responseData.userProfile.phone,
          profileImage: resp.responseData.userProfile.profileImage,
          isLoader: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        notify("error", err.error.responseMessage);
      });
  };

  handleOnChange = (e) => {
    const regexp = new RegExp(`^-?[0-9]*$`);
    if (e.target.name === "phone") {
      if (regexp.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handlePicUpload = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    let { valiDate } = this.state;

    reader.addEventListener(
      "load",
      () => {
        this.setState({ profilePicPreview: reader.result }, function () {});
      },
      false,
    );
    if (file) {
      reader.readAsDataURL(file);
    }

    if (
      file.type === "image/jpeg" ||
      file.type === "image/gif" ||
      file.type === "image/png" ||
      file.type === "image/svg+xml"
    ) {
      this.setState({
        profileImage: event.target.files[0],
        profilePicErrorMsg: "",
      });
    } else {
      valiDate = false;
      this.setState({ profilePicErrorMsg: "File type not supported." });
    }
  };

  handleProfileValidate = () => {
    let validate = false;
    let errors = {};
    let { firstName, lastName, phone } = this.state;

    if (_.isEmpty(firstName)) {
      validate = true;
      errors["firstName"] = "First name is required.";
    }
    if (_.isEmpty(lastName)) {
      validate = true;
      errors["lastName"] = "Last name is required.";
    }
    if (_.isEmpty(phone)) {
      validate = true;
      errors["phone"] = " Phone number is required.";
    }
    this.setState({ errors });
    return validate;
  };

  handleOnEditProfile = () => {
    const { userId, onRefreshEditProfile } = this.props;
    let { firstName, lastName, phone, profileImage } = this.state;
    if (!this.handleProfileValidate()) {
      let formData = new FormData();
      formData.append("userId", userId);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("phone", phone);
      formData.append("profileImage", profileImage);
      this.setState({ isLoader: true });
      _Api(
        Url.USER_EDIT_USER_PROFILE.method,
        Url.USER_EDIT_USER_PROFILE.url,
        formData,
      )
        .then((resp) => {
          notify("success", resp.responseData.message);
          onRefreshEditProfile();
          this.setState({ isLoader: false });
        })
        .catch((err) => {
          this.setState({ isLoader: false });
          notify("error", err.error.responseMessage);
        });
    }
  };

  render() {
    let { errors, firstName, lastName, phone, profilePicPreview } = this.state;
    return (
      <div className="shoopingcart_list">
        {this.state.isLoader && <Loader />}
        <div className="heading">
          <h6>
            <span className="text-bc">Edit Profile </span>
          </h6>
        </div>
        <Form className="useraddress">
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                maxLength={100}
                name="firstName"
                value={firstName}
                onChange={this.handleOnChange}
              />
              <span className="error_msg">{errors["firstName"]}</span>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Control
                type="text"
                placeholder="Enter last name"
                maxLength={100}
                name="lastName"
                value={lastName}
                onChange={this.handleOnChange}
              />
              <span className="error_msg">{errors["lastName"]}</span>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Control
                type="text"
                placeholder="Phone number"
                maxLength={15}
                name="phone"
                value={phone}
                onChange={this.handleOnChange}
              />
              <span className="error_msg">{errors["phone"]}</span>
            </Form.Group>
          </Form.Row>
          <div className="file-uploader">
            <div className="file-uploader-plus">
              {profilePicPreview ? (
                <img
                  alt="profile pic preview"
                  src={profilePicPreview}
                  className="documentpreview"
                />
              ) : (
                <>
                  <i class="fas fa-plus"></i>
                  <span>Upload</span>
                </>
              )}
            </div>
            <Form.Group>
              <input
                type="file"
                accept="image/x-png,image/gif,image/jpeg,image/jpg"
                onChange={this.handlePicUpload}
              />
            </Form.Group>
          </div>

          <div className="text-right">
            {" "}
            <Button
              variant="info"
              className="btn-lg"
              onClick={this.handleOnEditProfile}
            >
              Save
            </Button>{" "}
          </div>
        </Form>
      </div>
    );
  }
}

export default EditProfile;
