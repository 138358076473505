import {
  GET_USER_CART_LIST_FAILED,
  GET_USER_CART_LIST_SUCCESS,
} from "../action/Type";

const initialState = {
  cartListDetails: {},
};

// get cart list users
export const cartList = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_CART_LIST_SUCCESS:
      return {
        ...state,
        cartListDetails: action.payload.cartListDetails,
      };
    case GET_USER_CART_LIST_FAILED:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};
