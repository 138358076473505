import "moment-timezone";
import "jspdf-autotable";

import * as html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { Component } from "react";

import { Container, Table, Button, Col, Modal, Row } from "react-bootstrap";
import Loader from "../../components/common/Loader";
import Url from "../../services/BaseUrl.js";
import { _Api } from "../../services/Api.js";
import _ from "lodash";
import { DISTRIBUTION_COURIER_TYPE } from "../../utils/distribution";
import { subscriptionFormatted } from "../../utils/inventory";
import moment from "moment";

const debug = require("debug")("orderNumber.jsx");

// we only support these subscriptions states atm
const STRIPE_SUBSCRIPTION_STATUS = {
  ACTIVE: "active",
  CANCELED: "canceled",
};

function isNonEmptyArray(arr) {
  return Array.isArray(arr) && arr.length > 0;
}

function centsStringToDollarsString(cents) {
  return (parseInt(cents) / 100).toFixed(2);
}

function decorateOrderProductListEntryWithSubscription({
  orders,
  subscriptions,
}) {
  if (!Array.isArray(subscriptions)) return orders;
  console.log("decorateOrderProductListEntryWithSubscription");
  orders.forEach((order, orderIndex) => {
    order.buyList.forEach((buyListEntry, buyListEntryIndex) => {
      buyListEntry.productList.forEach(
        (productListEntry, productListEntryIndex) => {
          if (!productListEntry.stripeSubscriptionId) return;
          subscriptions.forEach((subscription) => {
            if (
              subscription.stripeSubscriptionObject.id ===
              productListEntry.stripeSubscriptionId
            ) {
              orders[orderIndex].buyList[buyListEntryIndex].productList[
                productListEntryIndex
              ].subscriptionInterval =
                subscription.stripeSubscriptionObject.metadata.subscriptionInterval;
              orders[orderIndex].buyList[buyListEntryIndex].productList[
                productListEntryIndex
              ].subscriptionIntervalCount =
                subscription.stripeSubscriptionObject.metadata.subscriptionIntervalCount;
            }
          });
        },
      );
    });
  });
  return orders;
}

class orderNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      orders: [],
      subscriptions: [],
      ItemList: [],
      show: false,
    };
  }
  componentDidMount() {
    this.getOrderList();
  }

  cancelSubscription({ stripeSubscriptionId }) {
    console.log("cancel this subscription:", stripeSubscriptionId);
    const body = {
      stripeSubscriptionId,
    };

    this.setState({
      isLoader: true,
    });

    _Api(
      Url.USER_ORDER_CANCEL_STRIPE_SUBSCRIPTION.method,
      Url.USER_ORDER_CANCEL_STRIPE_SUBSCRIPTION.url,
      body,
    )
      .then((response) => {
        console.log("cancel-subscription-response");
        console.log(response);
        // reload orders to get latest from stripe
        this.getOrderList();
      })
      .catch((err) => {
        this.setState({
          isLoader: false,
        });
      });
  }

  getOrderList = () => {
    let self = this;
    self.setState({ isLoader: true });

    _Api(Url.USER_GET_ORDER_LIST.method, Url.USER_GET_ORDER_LIST.url)
      .then((resp) => {
        if (resp.responseData && resp.responseData.OrderDetails) {
          let { orders, subscriptions } = resp.responseData.OrderDetails;
          if (!subscriptions) subscriptions = [];
          orders = decorateOrderProductListEntryWithSubscription({
            orders,
            subscriptions,
          });
          orders.sort((a, b) => {
            const aCreated = moment(a.created).unix();
            const bCreated = moment(b.created).unix();
            return bCreated - aCreated;
          });
          subscriptions.sort((a, b) => {
            const aCreated = parseInt(a.stripeSubscriptionObject.created);
            const bCreated = parseInt(b.stripeSubscriptionObject.created);
            return bCreated - aCreated;
          });
          self.setState({
            orders,
            subscriptions,
            isLoader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
      });
  };

  OrderBuyMp = (ItemList, itemColl) => {
    let Json =
      !_.isEmpty(ItemList) &&
      ItemList.map((val, i) => (
        <tr key={val._id}>
          <td>
            {val.productQuantity} {val.productName}
          </td>
          <td>{val.variety ? val.variety : ""}</td>
          <td>
            <span>
              ${val.productPrice} *{" "}
              {val.productQuantity ? val.productQuantity : ""} {val.unit}
            </span>
          </td>
          <td>
            {val.image ? (
              <img src={val.image} height="40px" width="40px" />
            ) : (
              ""
            )}
          </td>
        </tr>
      ));

    return Json;
  };

  OrderPick = (product) => {
    if (!_.isEmpty(product)) {
      let cc = "";
      for (let i = 0; i < product.buyList.length; i++) {
        if (product.buyList[i].farmUserId === this.state.userId) {
          cc =
            product.buyList[i].courierType + ":" + product.buyList[i].address;
        }
      }
      return cc;
    }
  };

  OrderMp = (ItemList) => {
    if (!ItemList) return null;
    let Json =
      !_.isEmpty(ItemList.buyList) &&
      ItemList.buyList.map((val, i) => (
        <>
          {this.OrderBuyMp(val.productList, ItemList)}
          <div className="order-mp-width">
            <p>
              Farm Name: {val.farmName}, {val.courierType} : {val.address}
            </p>
            <p>Delivery Fee: ${val.deliveryFees}</p>
            <p>Total Price: ${val.totalPrice}</p>
          </div>
        </>
      ));

    return Json;
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = (item) => {
    this.setState({ show: true, ItemList: item });
  };

  ConvertDate = (created) => {
    var created = new Date(created);
    let data = moment(created)
      .tz("America/New_York")
      .format("dddd MMMM Do YYYY, h:mma");
    return data;
  };

  modalPrint = () => {
    html2canvas(document.getElementById("divIdToPrint"), {
      useCORS: true,
    }).then((canvas) => {
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const imgData = canvas.toDataURL("image/png");

      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight + 25);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight + 25);
        heightLeft -= pageHeight;
      }
      doc.save("HealthyPlanEat.pdf");
    });
  };

  render() {
    const { orders, subscriptions, ItemList } = this.state;
    let subscriptionHeadingComponent = null;
    let subscriptionListComponent = null;

    if (isNonEmptyArray(subscriptions)) {
      subscriptionHeadingComponent = (
        <h2 className="bold font-main h2 mt1 mb1">Subscriptions</h2>
      );
      subscriptionListComponent = (
        <div className="table-responsive">
          <Table className="conpleteorder">
            <thead>
              <tr>
                <th className="th-product">Product</th>
                <th></th>
                {/*<th>Name</th>*/}
                <th>Type</th>
                <th>Qty.</th>
                <th>Frequency</th>
                <th>Price</th>
                <th>Total</th>
                <th>Status</th>
                {/*<th>Cancel</th>*/}
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((subscription, subscriptionIndex) => {
                {
                  /* we assume one product per subscription, to easily cancel one */
                }
                const item =
                  subscription.stripeSubscriptionObject.items.data[0];
                return (
                  <tr key={`subscription-row-subscriptionIndex`}>
                    <td className="td-product-image">
                      <img
                        src={
                          item.metadata.productImage
                            ? item.metadata.productImage
                            : require("../../assets/img/providerfarm.png")
                        }
                        className="order-table-product-image"
                        alt={item.metadata.productName}
                      />
                    </td>
                    <td>
                      <h6 className="mb-1 mt-1 product-name">
                        {item.metadata.productName}
                      </h6>
                    </td>
                    <td>
                      <h6 className="distribution-type">
                        {item.metadata.orderBuyListCourierType}
                      </h6>
                      {item.metadata.orderBuyListCourierType ===
                        DISTRIBUTION_COURIER_TYPE.DELIVERY && (
                        <div className="location">
                          {/* when Delivery, address is just address, day is the time range */}
                          {item.metadata.orderBuyListAddress}{" "}
                          {item.metadata.orderBuyListDay}
                        </div>
                      )}
                      {item.metadata.orderBuyListCourierType ===
                        DISTRIBUTION_COURIER_TYPE.PICKUP && (
                        <div className="location">
                          {/* when Pickup, address includes the location & time, for some reason */}
                          {item.metadata.orderBuyListAddress}
                        </div>
                      )}
                      {item.metadata.orderBuyListCourierType ===
                        DISTRIBUTION_COURIER_TYPE.SHIPPING && (
                        <div className="location">
                          {/* when Pickup, address includes the location & time, for some reason */}
                          {item.metadata.orderBuyListAddress}
                        </div>
                      )}
                    </td>
                    <td className="product-quantity">
                      {item.quantity} {item.metadata.productUnit}
                    </td>
                    <td className="product-frequency">
                      {subscriptionFormatted({
                        inventoryItem:
                          subscription.stripeSubscriptionObject.metadata,
                      })}
                    </td>
                    <td className="product-price">
                      ${centsStringToDollarsString(item.price.unit_amount)}
                    </td>
                    <td className="product-total">
                      $
                      {centsStringToDollarsString(
                        item.price.unit_amount * item.quantity,
                      )}
                    </td>
                    <td className="product-status">
                      {subscription.stripeSubscriptionObject.status}
                    </td>
                    <td>
                      <button
                        className="btn btn-brand-tertiary btn-sm subscription-cancel-button"
                        disabled={
                          subscription.stripeSubscriptionObject.status ===
                          STRIPE_SUBSCRIPTION_STATUS.CANCELED
                        }
                        type="submit"
                        onClick={this.cancelSubscription.bind(this, {
                          stripeSubscriptionId:
                            subscription.stripeSubscriptionObject.id,
                        })}
                      >
                        Cancel
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      );
    } else if (subscriptions.length === 0) {
      subscriptionHeadingComponent = (
        <h2 className="bold font-main h2 mt1 mb1">
          No subscriptions placed yet
        </h2>
      );
    }

    return (
      <>
        <div className="body_container_wrapper" id="customer-orders">
          {this.state.isLoader && <Loader />}
          <Container>
            <div className="shoopingcart_list order_number">
              <div className="boxed customer-orders-block subscriptions-block mt3">
                {subscriptionHeadingComponent}
                {subscriptionListComponent}
              </div>
              <div className="boxed customer-orders-block orders-block">
                {orders.length === 0 && (
                  <>
                    <h2 className="bold font-main h2 mt1 mb1">
                      No orders placed yet
                    </h2>
                  </>
                )}
                {orders.length > 0 && (
                  <>
                    <h2 className="bold font-main h2 mt1 mb1">Orders</h2>
                    <div className="table-responsive">
                      <div className="main_content">
                        <div className="table-responsive">
                          <table className="table cm_bottom_space">
                            <thead>
                              <tr className="broder_top_0">
                                <th scope="col" className="ws-nowrap">
                                  {/* Order */}
                                </th>
                                <th scope="col">Placed On</th>
                                <th scope="col" className="ws-nowrap">
                                  {" "}
                                  Total
                                </th>
                                <th scope="col" className="ws-nowrap">
                                  Status
                                </th>
                                <th scope="col" className="ws-nowrap">
                                  View
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {!_.isEmpty(orders) &&
                                orders.map((item, i) => (
                                  <tr key={item._id}>
                                    <td>{i + 1}</td>
                                    <td>{this.ConvertDate(item.created)}</td>
                                    <td>${item.amount}</td>
                                    <td>
                                      {item.status === "1" ? "Paid" : ""}{" "}
                                      {item.status === "0" ? "Pending" : ""}{" "}
                                      {item.status === "3" ? "cancel" : ""}
                                    </td>
                                    <td>
                                      <div className="action_wrapper ws-nowrap">
                                        <span>
                                          <i
                                            className="fas fa-eye"
                                            onClick={() =>
                                              this.handleShow(item)
                                            }
                                          ></i>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>{" "}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Container>
        </div>

        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          className="cm_order_view order-detailsmodal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="order-number-modal-flex">
              <p>Order Details</p>
              <div className="d-flex text-nowrap header_btn order-number-modal-print-button">
                <div className="print_button orderabs noabs">
                  <Button onClick={this.modalPrint} variant="light">
                    <i className="fas fa-print"></i> Print{" "}
                  </Button>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <div id={"divIdToPrint"}>
            <Modal.Body>
              <Row>
                <Col md="12">
                  {ItemList.billingInfo &&
                  ItemList.billingInfo.firstName &&
                  ItemList.billingInfo.lastName ? (
                    <p>
                      Name:{" "}
                      <span>
                        {ItemList.billingInfo.firstName}{" "}
                        {ItemList.billingInfo.lastName}
                      </span>
                    </p>
                  ) : (
                    ""
                  )}
                  {ItemList.billingInfo && ItemList.billingInfo.phoneNumber ? (
                    <p>
                      Phone: <span>{ItemList.billingInfo.phoneNumber} </span>
                    </p>
                  ) : (
                    ""
                  )}
                  {ItemList && ItemList.harvestStatus ? (
                    <p>
                      Status:{" "}
                      <span>
                        {ItemList.harvestStatus === "0" ? "Pending" : ""}{" "}
                        {ItemList.harvestStatus === 1 ? "Harvested" : ""}{" "}
                        {ItemList.harvestStatus === 2 ? "Fulfilled" : ""}
                        {ItemList.harvestStatus === 3 ? "Out of Stock" : ""}
                      </span>
                    </p>
                  ) : (
                    ""
                  )}
                  {ItemList.billingInfo && ItemList.billingInfo ? (
                    <p>
                      Address:{" "}
                      <span>
                        {ItemList.billingInfo.addressLine1}{" "}
                        {ItemList.billingInfo.addressLine2}{" "}
                        {ItemList.billingInfo.city} {ItemList.billingInfo.state}{" "}
                        {ItemList.billingInfo.country}{" "}
                        {ItemList.billingInfo.postalCode}
                      </span>
                    </p>
                  ) : (
                    ""
                  )}
                  {!_.isEmpty(ItemList.buyList) &&
                    ItemList.buyList.map((val, i) =>
                      val && val.trackingInfo && val.trackingInfo.length > 0
                        ? val.trackingInfo.map((box, index) => (
                            <div className="order-usps-tracking">
                              <a
                                href={`https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${box.trackingNumber}`}
                                target="_blank"
                              >
                                <button className="btn btn-brand-tertiary btn-sm">
                                  Track USPS Package {index + 1}
                                </button>
                              </a>
                            </div>
                          ))
                        : null,
                    )}
                  <p>{this.OrderPick(ItemList)}</p>
                </Col>
              </Row>
              <div className="order_items">
                <div className="table-responsive">
                  <table className="table" id="my-table">
                    <thead>
                      <tr>
                        <th className="ws-nowrap">Product Name</th>
                        <th className="ws-nowrap">Variety</th>
                        <th className="ws-nowrap">Product Price</th>
                        <th className="ws-nowrap">Product Image</th>
                      </tr>
                    </thead>
                    <tbody>{this.OrderMp(ItemList, false)}</tbody>
                  </table>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="footer_total">
                <p>
                  Order Total:
                  <span>${ItemList.amount}</span>
                </p>
                <p>
                  Delivery Charges:
                  <span>${ItemList.deliveryFees}</span>
                </p>
                <p>
                  Total:
                  <span> ${ItemList.totalPrice}</span>
                </p>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </>
    );
  }
}

export default orderNumber;
