import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { Button, Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { FarmProductCard } from "../../components/FarmProductCard";
import {
  GetCloseAndOpenStatus,
  Units,
  days,
  showAddress,
} from "../../utils/Helper";
import React, { Component } from "react";
import { SubscriptionInfo } from "../../components/products.jsx";
import {
  formatDayAndTime,
  getDeliveryFlatFee,
  getDeliveryZipCodesString,
  hasDeliveryDistribution,
  getDeliveryDistribution,
  getOpenOrderDistributionForUserType,
  hasOpenOrderDistributionForUserType,
  getDistributionForUserType,
  hasDistributionForUserType,
  hasOpenOrderShippingDistribution,
  hasOpenOrderNonShippingDistribution,
} from "../../utils/distribution";

import {
  shippableFromInventory,
  nonShippableFromInventory,
} from "../../utils/inventory";

import { USER_TYPE, userTypeStringFromInt } from "../../utils/user";

import Loader from "../../components/common/Loader";
import { NullComponent } from "../../components/common/Null";
import Login from "../../components/auth/Login";
import SignInAs from "../../components/auth/SignInAs";
import Signup from "../../components/auth/Signup";
import Slider from "react-slick";
import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCart } from "../../redux/action/Get";
import { notify } from "../../components/common/Tooster.js";
import { userProfile } from "../../redux/action/Get";
const debug = require("debug")("FarmList");

class farmList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 1,
      viewFarmDetails: {},
      isLoader: false,
      loadapi: false,
      orderStatus: false,
      userType: USER_TYPE.CUSTOMER,
      productListId: "",
      image: "",
      description: "",
      variety: "",
      itemName: "",
      wholeselerPrice: "",
      consumerPrice: "",
      consumerUnit: "",
      wholeselerUnit: "",
      maximumConsumerQuantity: "",
      maximumWholeselerQuantity: "",
      disable: false,
      cardInfo: [],
    };
  }

  handleShowModal = (value) => {
    this.setState({ showModal: value });
  };

  handleShowModalLogin = (value) => {
    this.setState({ showModalLogin: value });
  };

  handleShowModalSignInAs = (value) => {
    this.setState({ signInAs: value });
  };

  componentDidMount() {
    let userType = +localStorage.getItem("userType");
    if (!userType) {
      userType = USER_TYPE.CUSTOMER;
    }
    this.setState({ userType: userType }, function () {
      this.getAllFarmDetails();
    });
  }

  //get details about farm and inventery
  getAllFarmDetails = () => {
    this.setState({ isLoader: true });
    let req = {
      farmId: this.props.match.params.id,
      userType: this.state.userType,
    };

    localStorage.setItem(
      "previousPurchaseUrl",
      `/farms/farmlist/${req.farmId}`,
    );

    _Api(
      Url.USER_VIEW_ALL_FRAM_DETAILS.method,
      Url.USER_VIEW_ALL_FRAM_DETAILS.url,
      "",
      `?farmId=${req.farmId}&userType=${req.userType}`,
    )
      .then((resp) => {
        this.setState({
          viewFarmDetails: resp.responseData.result,
          isLoader: false,
          loadapi: true,
        });
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  checkout = () => {
    this.props.history.push({
      pathname: "/shoppingcart",
    });
  };

  RemovehandleShow = (item) => {
    console.log(item);
    this.setState({
      removeitem: true,
      image: item.image,
      description: item.description,
      itemName: item.itemName,
      variety: item.variety,
      wholeselerPrice: item.wholeselerPrice,
      consumerPrice: item.consumerPrice,
      wholeselerUnit: item.wholeselerUnit,
      consumerUnit: item.consumerUnit,
      maximumConsumerQuantity: item.maximumConsumerQuantity,
      maximumWholeselerQuantity: item.maximumWholeselerQuantity,
      otherItemName: item.otherItemName,
      otherConsumerUnit: item.otherConsumerUnit,
      otherWholeSalerUnit: item.otherWholeSalerUnit,
    });
  };

  render() {
    let foundPickup = false;
    let { loadapi, viewFarmDetails, isLoader, userType } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
    };

    const ProductSectionHeading = ({ text }) => {
      return (
        <div className="heading mt3">
          <h3>
            <span>{text}</span>
          </h3>
        </div>
      );
    };

    let Products = NullComponent;
    const nonShippableProducts = nonShippableFromInventory({
      inventory: viewFarmDetails.inventoryData,
    });
    const hasNonShippableProducts = nonShippableProducts.length > 0;
    const hasOpenNonShippingOrderWindow = hasOpenOrderNonShippingDistribution({
      distributionInfo: viewFarmDetails.distributionData,
      userType,
    });
    if (hasNonShippableProducts && hasOpenNonShippingOrderWindow) {
      Products = () => (
        <>
          <ProductSectionHeading text={"Products"} />
          <div className="product_list_wrapper cards-container">
            {nonShippableProducts.map((inventoryItem) => {
              const cardProps = {
                inventoryItem: {
                  ...inventoryItem,
                  farmName: this.state.viewFarmDetails?.farmData[0]?.farmName,
                  farmId: this.props.match.params.id,
                },
                userType,
                farmId: this.props.match.params.id,
              };
              return <FarmProductCard {...cardProps} />;
            })}
          </div>
        </>
      );
    }

    let ShippedProducts = NullComponent;
    const shippableInventory = shippableFromInventory({
      inventory: viewFarmDetails.inventoryData,
    });
    const hasShippableInvetory = shippableInventory.length > 0;
    const hasOpenShippingOrderWindow = hasOpenOrderShippingDistribution({
      distributionInfo: viewFarmDetails.distributionData,
      userType,
    });
    if (hasShippableInvetory && hasOpenShippingOrderWindow) {
      ShippedProducts = () => (
        <>
          <ProductSectionHeading text={"Shipped Products"} />
          <div className="product_list_wrapper">
            {shippableInventory.map((inventoryItem) => {
              const cardProps = {
                inventoryItem: {
                  ...inventoryItem,
                  farmName: this.state.viewFarmDetails?.farmData[0]?.farmName,
                  farmId: this.props.match.params.id,
                },
                userType,
                farmId: this.props.match.params.id,
              };
              return <FarmProductCard {...cardProps} />;
            })}
          </div>
        </>
      );
    }

    let OrderWindowClosed = NullComponent;
    let Checkout = NullComponent;
    if (
      hasOpenNonShippingOrderWindow === false &&
      hasOpenShippingOrderWindow === false
    ) {
      OrderWindowClosed = () => (
        <h6 className="text-center text-orange">Order window closed</h6>
      );
    } else {
      Checkout = () => (
        <div className="text-right">
          <button
            className="btn btn-warning text-light"
            onClick={this.checkout}
          >
            Checkout
          </button>
        </div>
      );
    }

    return (
      <div className="body_container_wrapper">
        <Container>
          {isLoader && <Loader />}
          <div className="two_col_contentimg farmerlisting">
            <Row>
              <Col md="6">
                {viewFarmDetails &&
                  viewFarmDetails.farmData &&
                  viewFarmDetails.farmData.map((value, i) => {
                    return (
                      <div className="farmer_card">
                        <div className="caption">
                          <div className="farmer_card_img_wrapper ">
                            <img
                              src={
                                value.farmLogo == null
                                  ? require("../../assets/img/placehoder_2.png")
                                  : value.farmLogo
                              }
                              alt=""
                            />
                          </div>
                          <h4>{value.farmName}</h4>
                          <p className="cardcontentalign">{value.shortInfo}</p>
                        </div>
                        <div className="backgroundimgslide">
                          {value.media && value.media.length > 0 ? (
                            <>
                              <Slider {...settings} className="">
                                {value.media.map((item, i) => (
                                  <img src={item.url} />
                                ))}
                              </Slider>
                            </>
                          ) : (
                            <img
                              src={require("../../assets/img/placeholder_1.png")}
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </Col>
              <Col md="6">
                <div className="two_col_content">
                  {viewFarmDetails &&
                    viewFarmDetails.farmData &&
                    viewFarmDetails.farmData.map((value, i) => (
                      <h4>{`About ${value.farmName}`}</h4>
                    ))}

                  <Tabs defaultActiveKey="About">
                    <Tab eventKey="About" title="Info About Farm">
                      {viewFarmDetails &&
                        viewFarmDetails.farmData &&
                        viewFarmDetails.farmData.map((value, i) => (
                          <>
                            <h6>{value.infoAboutFarm}</h6>

                            {value.userId &&
                            (value.userId.certifiedOrganic == 1 ||
                              value.userId.nofa == 1) ? (
                              <>
                                <div className="cretifiedbadge">
                                  {value.userId.certifiedOrganic == 1 ? (
                                    <img
                                      src={require("../../assets/img/Organic4colorsealGIF.gif")}
                                      className="logosize"
                                      height="96"
                                      width="48"
                                      alt="Certified Organic"
                                    />
                                  ) : null}
                                  {value.userId.nofa == 1 ? (
                                    <img
                                      src={require("../../assets/img/NOFAFarmersPledge.jpg")}
                                      className="logosize2"
                                      alt="NOFA Farmers Pledge"
                                    />
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                          </>
                        ))}
                    </Tab>
                    <Tab eventKey="Location" title="Location">
                      {viewFarmDetails &&
                        viewFarmDetails.farmData &&
                        viewFarmDetails.farmData.map((value, i) => {
                          return (
                            <>
                              <h6>{showAddress(value.farmAddress[0])}</h6>
                            </>
                          );
                        })}
                    </Tab>
                    <Tab eventKey="Order Window" title="Order Window">
                      {viewFarmDetails &&
                        getDistributionForUserType({
                          distributionInfo: viewFarmDetails.distributionData,
                          userType,
                        }).map((distributionInfo, i) => {
                          return (
                            <>
                              {" "}
                              <h6>{`${
                                days[distributionInfo.placeOrderOpen.day]
                              } ${distributionInfo.placeOrderOpen.time} ${
                                distributionInfo.placeOrderOpen.meridiem
                              } - ${
                                days[distributionInfo.placeOrderClose.day]
                              } ${distributionInfo.placeOrderClose.time} ${
                                distributionInfo.placeOrderClose.meridiem
                              }`}</h6>
                            </>
                          );
                        })}

                      {viewFarmDetails &&
                        !hasDistributionForUserType({
                          distributionInfo: viewFarmDetails.distributionData,
                          userType,
                        }) && (
                          <p>
                            No order windows for{" "}
                            {`${userTypeStringFromInt({
                              userType,
                            }).toLowerCase()}`}
                          </p>
                        )}
                    </Tab>

                    <Tab eventKey="Pick Up" title="Pick Up">
                      {viewFarmDetails &&
                        viewFarmDetails.distributionData &&
                        viewFarmDetails.distributionData.map((obj, i) => {
                          return (
                            <>
                              {obj.pickup.length > 0 ? (
                                <div className="adress-time-wrapper">
                                  <p>{obj.distributionLocation.address}</p>
                                  {obj.pickup.map((item, index) => {
                                    foundPickup = true;
                                    const time = item.time
                                      .map((shiftTime) => {
                                        return `${shiftTime.starttime}-${shiftTime.endTime}`;
                                      })
                                      .join(", ");
                                    return (
                                      <p key={index}>
                                        {days[item.day] + "  " + time}
                                      </p>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </>
                          );
                        })}

                      {loadapi && !foundPickup
                        ? "Pick ups are not currently available."
                        : null}
                    </Tab>
                    <Tab eventKey="Delivery" title="Delivery">
                      {viewFarmDetails &&
                        viewFarmDetails.distributionData &&
                        !hasDeliveryDistribution(
                          viewFarmDetails.distributionData,
                        ) && (
                          <h6>
                            This farmer currently has no delivery options.
                          </h6>
                        )}

                      <div className="delivery-time-wrapper">
                        {viewFarmDetails &&
                          hasDeliveryDistribution(
                            viewFarmDetails.distributionData,
                          ) && (
                            <p>
                              Available zip codes:{" "}
                              {`${getDeliveryZipCodesString(
                                viewFarmDetails.distributionData,
                              )}`}
                            </p>
                          )}

                        {viewFarmDetails &&
                          hasDeliveryDistribution(
                            viewFarmDetails.distributionData,
                          ) &&
                          getDeliveryDistribution(
                            viewFarmDetails.distributionData,
                          ).map((distributionInfo, i) => {
                            return distributionInfo.delivery.map((delivery) => {
                              const formattedDayAndTime =
                                formatDayAndTime(delivery);

                              if (!formattedDayAndTime) {
                                return null;
                              }

                              const flatFee =
                                getDeliveryFlatFee(distributionInfo);

                              const deliveryInfo = `${formattedDayAndTime} - $${flatFee}`;
                              return <p key={i}>{deliveryInfo}</p>;
                            });
                          })}
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </div>
          <div className="two_col_wrapper">
            {viewFarmDetails &&
              viewFarmDetails.farmData &&
              viewFarmDetails.farmData.map((value, i) => (
                <Row>
                  <Col md="6">
                    <div className="two_col_content">
                      <h2>The Farmers</h2>
                      <p className="farmer discription">
                        {value.infoAboutFarmer}
                      </p>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="farmer_cardimg">
                      <img
                        src={
                          value.userId.profileImage == ""
                            ? require("../../assets/img/placeholder_1.png")
                            : value.userId.profileImage
                        }
                        className="rightimg two-col-img"
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              ))}
          </div>
          <div className="product_section">
            <Products />
            <ShippedProducts />
            <Checkout />
            <OrderWindowClosed />
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.login.isLoggedIn,
    profiledetails: state.profile,
    cartListDetails: state.cartList.cartListDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({ userProfile, getCart }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(farmList);
