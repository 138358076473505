import { Button, Col, Container, Row } from "react-bootstrap";
import React, { Component } from "react";

import { Empty } from "antd";
import Loader from "../../components/common/Loader";
import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";

class Farms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nearme: false,
      farmData: "",
      search: "",
      isLoader: false,
      farmFilterDetails: {},
      SelectedState: "",
      selectedCity: "",
      currentLat: "",
      currentLong: "",
    };
  }

  componentDidMount() {
    this.handleSearchFilter();
  }

  handleSearch = (e) =>
    this.setState({ search: e.target.value }, () => this.getAllFarm());

  handleSearchFilter = () => {
    this.setState({ isLoader: true });
    _Api(
      Url.USER_GET_FARM_BY_FILTER.method,
      Url.USER_GET_FARM_BY_FILTER.url,
    ).then((resp) => {
      this.setState({ farmFilterDetails: resp.responseData.result }, () => {
        this.getAllFarm();
      });
    });
  };

  //get farm
  getAllFarm = () => {
    // this.setState({ isLoader: true })
    let req = {
      search: this.state.search,
      state: this.state.SelectedState,
      city: this.state.selectedCity,
      currentLat: this.state.currentLat,
      currentLong: this.state.currentLong,
    };

    _Api(
      Url.USER_GET_FARM.method,
      Url.USER_GET_FARM.url,
      "",
      `?validacct=1&search=${req.search}&state=${req.state}&city=${req.city}&lat=${req.currentLat}&long=${req.currentLong}`,
    ).then((resp) => {
      this.setState({ farmData: resp.responseData.result, isLoader: false });
    });
  };

  handleOnChangeSelect = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOnGetCurrentLatLng = () => {
    let { nearme } = this.state;
    if (!nearme) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState(
          {
            nearme: true,
            currentLat: position.coords.latitude,
            currentLong: position.coords.longitude,
          },
          function () {
            this.getAllFarm();
          },
        );
      });
    } else {
      this.setState(
        { nearme: false, currentLat: "", currentLong: "" },
        function () {
          this.getAllFarm();
        },
      );
    }
  };

  handleOnClickSearch = () => {
    this.getAllFarm();
  };
  handleOnClickNearBy = () => {
    this.getAllFarm();
  };

  redirectToProductDetails = (id) => {
    this.props.history.push({ pathname: `/farms/farmlist/${id}` });
  };

  render() {
    let { nearme } = this.state;
    return (
      <div className="body_container_wrapper">
        <Container>
          <div className="farmers_search header">
            <Row>
              <Col md="5">
                <div>
                  <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by Farm Name"
                      maxLength={30}
                      name="search"
                      value={this.state.search}
                      onChange={this.handleSearch}
                    />
                  </div>
                </div>
              </Col>
              <Col md="7">
                <Row>
                  <Col md="3">
                    <select
                      id="state"
                      className="form-control"
                      name="SelectedState"
                      onChange={this.handleOnChangeSelect}
                    >
                      <option value="">State</option>
                      {this.state.farmFilterDetails.state &&
                        this.state.farmFilterDetails.state.map((item, i) => {
                          return (
                            <>
                              {item ? (
                                <option
                                  value={item.SelectedState}
                                  key={`state-option-${i}`}
                                >
                                  {item}
                                </option>
                              ) : null}
                            </>
                          );
                        })}
                    </select>
                  </Col>
                  <Col md="3">
                    <select
                      id="state"
                      className="form-control"
                      name="selectedCity"
                      onChange={this.handleOnChangeSelect}
                    >
                      <option value="">City</option>
                      {this.state.farmFilterDetails.city &&
                        this.state.farmFilterDetails.city.map((item, i) => {
                          return (
                            <>
                              {item ? (
                                <option
                                  value={item.selectedCity}
                                  key={`city-option-${i}`}
                                >
                                  {item}
                                </option>
                              ) : null}
                            </>
                          );
                        })}
                    </select>
                  </Col>
                  <Col md="3">
                    <button
                      className={nearme ? "btn nearme active" : "btn nearme"}
                      onClick={this.handleOnGetCurrentLatLng}
                    >
                      Near By
                    </button>
                  </Col>
                  <Col md="3">
                    <Button
                      variant="info"
                      type="button"
                      onClick={this.handleOnClickSearch}
                    >
                      Apply
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {this.state.isLoader && <Loader />}
          <div className="farmers_cardsection">
            <Row>
              {this.state.farmData &&
                this.state.farmData.map((value, i) => {
                  let img = `url(${require("../../assets/img/placeholder_1.png")})`;
                  if (value.media[0].url) {
                    img = `url(${value.media[0].url})`;
                  }
                  //console.log(value.farmName + ' @ ' + value.geoLocation);
                  return (
                    <Col md="4" key={`farmer_card-col-${i}`}>
                      <div
                        className="farmer_card bg-img"
                        style={{ backgroundImage: img }}
                        onClick={() => this.redirectToProductDetails(value._id)}
                      >
                        <div className="caption">
                          <div className="farmer_card_img_wrapper">
                            <img
                              alt="farm logo"
                              src={
                                value.farmLogo == null
                                  ? require("../../assets/img/providerfarm.png")
                                  : value.farmLogo
                              }
                            />
                          </div>
                          <div className="farmcard-heading">
                            <h4>{value.farmName}</h4>
                          </div>
                          <div className="farmcard-location">
                            <h5>
                              {value.farmAddress.city},{" "}
                              {value.farmAddress.state}
                            </h5>
                          </div>
                          <div className="farmcard-content">
                            <p>{value.shortInfo}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            {this.state.farmData && this.state.farmData.length ? (
              ""
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </Container>
      </div>
    );
  }
}

export default Farms;
