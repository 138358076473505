import { Col, Container, Row } from "react-bootstrap";

import Banner from "../banner";
import Joinourfoodmovement from "../home/Joinourfoodmovement";
import OurClient from "./ourclient";
import React from "react";

const About = () => {
  return (
    <div className="body_container_wrapper no_pad">
      <Banner
        heading="About Healthy PlanEat"
        bannerbg={`url(${require("../../assets/img/about.png")})`}
      />
      <Container>
        <div className="about_wrapper">
          <div className="two_col_wrapper about">
            <Row className="heightauto">
              <Col>
                <div className="two_col_content">
                  <div className="heading">
                    <h3>
                      <span>About Healthy PlanEat</span>
                    </h3>

                    <h6 className="text-center">
                      Healthy PlanEat is an online farmers market that exists so
                      you can easily shop directly from local sustainable farms
                      and food artisans.
                    </h6>

                    <h6 className="text-center">
                      Healthy PlanEat is a new sustainable food tech startup
                      based in East Lyme, Connecticut which helps farmers using
                      sustainable growing practices to sell their food directly
                      to local customers. Using Healthy PlanEat, farmers can
                      upload inventory, set distribution options, and manage
                      incoming orders from individuals and wholesale buyers such
                      as restaurants, schools, and universities. Customers can
                      purchase food to pick up at the farm, farmer’s market,
                      pop-up, get delivery, or have the food sustainably shipped
                      to their door. We work with farms that are committed to
                      sustainable growing practices, including USDA Certified
                      Organic farms, because we believe sustainable growing
                      practices are important for healthy people and our planet.
                      Our mission is to help increase access to fresh,
                      sustainably grown, local food so people can have a healthy
                      and sustainable diet.
                    </h6>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="who_we_are">
          <div className="heading">
            <h3>
              <span>Healthy PlanEat’s Founder</span>
            </h3>
          </div>
        </div>
        <div className="imgcontent_wrapper">
          <div className="about_wrapper">
            <div className=" two_col_wrapper  ">
              <Row>
                <Col md="4">
                  <div className="farmer_cardimg">
                    <img src={require("../../assets/img/RM1.jpg")} />
                  </div>
                </Col>
                <Col md="8">
                  <div className="two_col_content">
                    <div className="heading">
                      <h3>
                        <span>Rosemary</span>
                      </h3>
                      <h5>
                        <p className="designation">Founder and CEO</p>
                      </h5>
                      <h6 className="content">
                        <span>
                          “My love of healthy and sustainable food is in my
                          roots – my mom started a natural food store in the
                          early 1970s. While I was working on an organic farm in
                          my hometown the summer after my freshman year of
                          college, my interest in agriculture grew. I continued
                          my studies at the University of Cambridge in England
                          where I earned a PhD in Land Economy and researched
                          strategies for sustainable agricultural production.
                          I've started Healthy PlanEat because I want to make it
                          easier for everyone to have a healthy and sustainable
                          diet.”
                        </span>
                      </h6>
                      <dd className="bottomright"> – Rosemary Ostfeld, PhD</dd>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="two_col_wrapper ">
          <div className="who_we_are">
            <div className="heading">
              <h3>
                <span>Our Commitment To Sustainable Growing Practices</span>
              </h3>
              <h6 className="content">
                Environmental sustainability is at the core of Healthy PlanEat.
                Our motto is: “Food for you and the planet.”
              </h6>
            </div>
          </div>
        </div>
        <div className="who_we_are">
          <div className="heading">
            <h3>
              <span>Sustainable Growing Practices</span>
            </h3>
            <h6 className="content">
              The farms Healthy PlanEat works with are committed to
              environmentally sustainable growing practices such as rotating
              crops, utilizing cover crops, composting, raising animals on
              pasture, and refraining from the use of synthetic pesticides,
              herbicides, or fertilizers. These practices maintain clean air and
              water, improve soil quality, and mitigate global climate change.
            </h6>
          </div>
        </div>
        <div className="imgcontent_wrapper">
          <div className="about_wrapper">
            <div className=" two_col_wrapper  right">
              <Row>
                <Col md="4">
                  <div className="farmer_cardimg">
                    <img
                      src={require("../../assets/img/Organic4colorsealGIF.gif")}
                      className="logosize"
                    />
                  </div>
                </Col>
                <Col md="8">
                  <div className="two_col_content">
                    <div className="heading">
                      <h3>
                        <span>USDA Certified Organic</span>
                      </h3>
                      <h6 className="content">
                        <span>
                          Not all the farms Healthy PlanEat works with are USDA
                          Certified Organic. USDA Certified Organic farms adhere
                          to guidelines that are established by the National
                          Organic Program (NOP) and are verified by an
                          accredited certifying agency. Farms that are USDA
                          Certified Organic are clearly labeled on this site
                          with a USDA Organic logo. These farms have uploaded
                          proof of their certification and have been verified
                          using the USDA Organic Integrity Database. To learn
                          more about USDA Certified Organic, click{" "}
                          <a
                            href="https://www.usda.gov/topics/organic"
                            target="_blank"
                          >
                            {" "}
                            here
                          </a>
                          .
                        </span>
                      </h6>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/**
          <div className="about_wrapper">
            <div className=" two_col_wrapper  right">
              <Row>
                <Col md="4">
                  <div className="farmer_cardimg text-right">
                    <img
                      src={require("../../assets/img/NOFAFarmersPledge.jpg")}
                      className="logosize2"
                    />
                  </div>
                </Col>

                <Col md="8">
                  <div className="two_col_content">
                    <div className="heading">
                      <h3>
                        <span>
                          Northeast Organic Farming Association Farmer’s Pledge
                        </span>
                      </h3>
                      <h6 className="content">
                        <span>
                          The Northeast Organic Farming Association (NOFA)
                          Farmer’s Pledge is a document farmers can sign to
                          share their commitment to sustainable growing
                          practices. It is a separate program from USDA
                          Certified Organic and adherence to the Pledge’s claims
                          are not verified by an accredited certifier. According
                          to NOFA, "The heart of the Farmer’s Pledge is the
                          integrity of the farmer and the judgment of the
                          consumer. The Pledge does not focus on the details of
                          organic farming practices, but embraces the big
                          picture by stating in the opening line that knowing
                          the farmer is the consumer’s best protection." Farms
                          that have signed the Farmer’s Pledge are clearly
                          labeled on this site with a Farmer’s Pledge logo. To
                          read the Northeast Organic Farming Association
                          Farmer’s Pledge, click{" "}
                          <a
                            href="https://ctnofa.org/wp/wp-content/uploads/2020/01/2020-Farmers-Pledge.pdf"
                            target="_blank"
                          >
                            {" "}
                            here
                          </a>
                          .
                        </span>
                      </h6>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>*/}
        </div>
      </Container>
      <div className="our_client">
        <div className="heading">
          <h3>
            <span>Our Farmers</span>
          </h3>
          <p>Meet the farmers who grow your food</p>
        </div>
        <OurClient />
      </div>
      <Joinourfoodmovement />
    </div>
  );
};

export default About;
