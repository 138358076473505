import { Button, Col, Container, Form, Navbar, Row } from "react-bootstrap";
// import React from 'react'
import React, { Component } from "react";

import { Link } from "react-router-dom";
import Loader from "../common/Loader";
import Url from "../../services/BaseUrl";
import _ from "lodash";
import { _Api } from "../../services/Api";
import { isValidEmail } from "../../utils/Validation";
import { notify } from "../common/Tooster";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      errors: [],
      responseError: "",
      err: "",
    };
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    });
  };

  handleValidate = () => {
    let validate = false;
    let errors = {};
    let { email, desc, imageLocation, phone, countryCode } = this.state;

    if (_.isEmpty(email)) {
      validate = true;
      errors["email"] = "email is required.";
    } else if (!isValidEmail(email)) {
      validate = true;
      errors["email"] = "Enter a valid email.";
    }

    this.setState({ errors });
    return validate;
  };

  // Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "",
  handleForgotSubmit = (e) => {
    e.preventDefault();
    if (!this.handleValidate()) {
      let { email } = this.state;
      let req = {
        email: email,
      };
      _Api(Url.USER_FORGOT_PASSWORD.method, Url.USER_FORGOT_PASSWORD.url, req)
        .then((resp) => {
          if (resp.statusCode == 1) {
            notify("success", resp.responseData.message);
            this.props.history.goBack();
          }
        })
        .catch((err) => notify("error", err.error.responseMessage));
    }
  };

  render() {
    let { email, password, passwordErrorMsg, emailErrorMsg, errors, success } =
      this.state;
    return (
      <div className="body_container_wrapper login_page">
        <Container>
          <h1>
            <Navbar.Brand href="#home">
              {/* <img src={require('../../assets/img/OFFICIAL LOGO TM WITH HEALTHY PLANEAT BLOCK.png')} alt="Logo" /> */}
            </Navbar.Brand>
          </h1>
          <div className="login_wrapper">
            <Row>
              <Col md="4" className="mx-auto text-center">
                <div>
                  {/* <img  src={require('../../assets/img/OFFICIAL LOGO.png')}  /> */}
                </div>
                <h6>Forgot Password</h6>

                {/* <div className="error"> {errors} </div>
                                <div className="success"> {success} </div> */}

                <Form.Group>
                  <Form.Control
                    type="email"
                    onChange={this.handleOnChange}
                    name="email"
                    value={email}
                    placeholder="Email Address"
                  />
                  <span className="error_msg">{errors["email"]}</span>
                  {/* {emailErrorMsg ? <div className="error">{emailErrorMsg}</div> : null} */}
                </Form.Group>

                <Button
                  type="submit"
                  onClick={this.handleForgotSubmit}
                  variant="dark"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}
