import React from "react";

import { isSubscriptionItem, subscriptionFormatted } from "../utils/inventory";

export const SubscriptionInfo = (props) => {
  const { inventoryItem } = props;
  if (!isSubscriptionItem({ inventoryItem })) return null;
  return (
    <p className="subscription-info">
      Subscription:
      <br />
      <span className="subscription-info-details">
        {subscriptionFormatted({ inventoryItem })}
      </span>
    </p>
  );
};

export const SubscriptionInfoOrderSummary = (props) => {
  const { inventoryItem } = props;
  if (!isSubscriptionItem({ inventoryItem })) return null;
  return (
    <p className="subscription-info">
      Subscription:
      <br />
      <p className="subscription-info-details">
        {subscriptionFormatted({ inventoryItem })}
      </p>
    </p>
  );
};
