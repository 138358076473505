import React from "react";
import { isEmpty } from "lodash";
import { Alert } from "react-bootstrap";

import Slider from "react-slick";
import { FarmProductCard } from "./FarmProductCard";

const noop = () => undefined;

export const FarmProductCards = ({
  userType,
  zipCode,
  sliderProps,
  guardOrderWileLoggedOut = noop,
  isShippable = true,
  updatedInventory,
}) => {
  const useSlider = !isEmpty(sliderProps);

  if (zipCode && updatedInventory.length === 0) {
    return (
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <Alert
            variant="warning"
            className="order-now-no-results hp-alert hp-alert-large"
          >
            There aren't currently any products shipping to your zip code
          </Alert>
        </div>
      </div>
    );
  }

  if (updatedInventory.length === 0) {
    return (
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <Alert
            variant="warning"
            className="order-now-no-results hp-alert hp-alert-large"
          >
            There aren't currently any products available for shipping, please
            check back soon
          </Alert>
        </div>
      </div>
    );
  }

  if (useSlider && updatedInventory.length > 3) {
    return (
      <Slider {...sliderProps}>
        {updatedInventory.map((inventoryItem, index) => {
          const cardProps = {
            inventoryItem: {
              ...inventoryItem,
              farmName: inventoryItem.farmName,
              farmId: inventoryItem.farmId,
            },
            userType,
            // farmId,
            guardOrderWileLoggedOut,
          };
          return <FarmProductCard key={index} {...cardProps} />;
        })}
      </Slider>
    );
  }

  return (
    <div className="cards-container">
      {updatedInventory.map((inventoryItem, index) => {
        const cardProps = {
          inventoryItem,
          userType,
          // farmId,
          guardOrderWileLoggedOut,
        };
        return <FarmProductCard key={index} {...cardProps} />;
      })}
    </div>
  );
};
