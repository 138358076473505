import { USER_TYPE, getUserType } from "./user";
import { Units } from "./Helper";
import { isEqual } from "lodash";
import {
  DISTRIBUTION_TYPE,
  openOrderWindowDistributionForUserId,
} from "./distribution";
const debug = require("debug")("util-inventory");

const DEFAULT_INVENTORY_IMAGE = require("../assets/img/placehoder_2.png");

export const INVENTORY_DISTRIBUTION_TYPES = {
  PICKUP_DELIVERY: [DISTRIBUTION_TYPE.PICKUP, DISTRIBUTION_TYPE.DELIVERY],
  SHIPPING: [DISTRIBUTION_TYPE.SHIPPING],
};

export const productIsShippable = ({ inventoryItem }) => {
  return isEqual(
    inventoryItem.distributionTypes,
    INVENTORY_DISTRIBUTION_TYPES.SHIPPING,
  );
};

export const shippableFromInventory = ({ inventory }) => {
  if (!Array.isArray(inventory)) return [];
  return inventory.filter((inventoryItem) =>
    productIsShippable({ inventoryItem }),
  );
};

export const nonShippableFromInventory = ({ inventory }) => {
  if (!Array.isArray(inventory)) return [];
  return inventory.filter((inventoryItem) => {
    const isNotShippable = !productIsShippable({ inventoryItem });
    if (inventoryItem._id === "6102ec6a9bccde4f17cbd645")
      debug("isNotShippable:", isNotShippable);
    return isNotShippable;
  });
};

export const hasShippableFromInventory = ({ inventory }) => {
  return shippableFromInventory({ inventory }).length > 0;
};

export const getFarmIdsFromCart = (cart) => {
  if (!cart || !cart.buyList) {
    return [];
  }

  return cart.buyList.map((buyList) => {
    return buyList.farmId;
  });
};

export const noProductsAvailable = (productList) => {
  return productList.map((product) => {
    return {
      ...product,
      isAvailable: false,
    };
  });
};

export const checkInventoryAvailable = (farmDetails, listToCheck) => {
  if (!farmDetails || !listToCheck) {
    return;
  }

  const userType = getUserType();

  let maxQuantityKey = "maximumConsumerQuantity";
  if (userType === USER_TYPE.WHOLESALER) {
    maxQuantityKey = "maximumWholeselerQuantity";
  }

  const { inventoryInfo } = farmDetails;
  const { farmId, productList } = listToCheck;

  // This particular buy list does not correspond to this farmer
  if (farmId !== farmDetails._id) {
    return;
  }

  // Inventory list for this farmer is empty
  if (!inventoryInfo || !inventoryInfo.length) {
    return;
  }

  // Product list for this particular buy list is empty
  if (!productList || !productList.length) {
    return;
  }

  const updatedProductList = [];

  productList.forEach((product) => {
    const matchedInventory = inventoryInfo.find((inventory) => {
      return inventory._id === product.productId;
    });

    // No matched inventory, so defaulting to not available
    if (!matchedInventory) {
      updatedProductList.push({ ...product, isAvailable: false });
      return;
    }

    const isAvailable =
      product.productQuantity <= matchedInventory[maxQuantityKey];

    updatedProductList.push({ ...product, isAvailable });
  });

  return updatedProductList;
};

export const hasUnavailableProductInProductList = (productList) => {
  if (!productList || !productList.length) {
    return true;
  }

  const unavailableProduct = productList.find(
    (product) => !product.isAvailable,
  );

  return unavailableProduct !== undefined;
};

const hasUnavailableProductInBuyList = (cart) => {
  if (!cart || !cart.length) {
    return true;
  }

  const unavailableProduct = cart.find((buyList) => buyList.isDisabled);

  return unavailableProduct !== undefined;
};

/**
 * Find the corresponding farm to farmId in cart.buyList
 *
 * @param {object} cart
 * @param {array} farms
 */
const getFarmFromCartFarmId = (cart, farms) => {
  return farms.find((farm) => {
    return farm._id === cart.farmId;
  });
};

/**
 * Check if inventory is still available for all items in a cart
 * and returns updated cart that reflects availability
 *
 * @param {object} cart
 * @param {array} farms
 */
const getUpdatedCart = (cart, farms) => {
  if (!cart || !cart.buyList || !farms || !farms.length) {
    return false;
  }

  const updatedCart = [];

  cart.buyList.forEach((list) => {
    let farm = getFarmFromCartFarmId(list, farms);
    let productList = checkInventoryAvailable(farm, list);

    if (productList === undefined) {
      updatedCart.push({ ...list, isDisabled: false });

      return;
    }

    let isDisabled = hasUnavailableProductInProductList(productList);

    updatedCart.push({ ...list, productList, isDisabled });
  });

  return updatedCart;
};

/**
 * Check if cart is still valid (inventory is still available for all items)
 *
 * @param {object} cart
 * @param {array} farms
 */
export const isValidCart = (cart, farms) => {
  if (!cart || !cart.buyList || !farms || !farms.length) {
    return false;
  }

  const updatedCart = getUpdatedCart(cart, farms);

  return !hasUnavailableProductInBuyList(updatedCart);
};

export const isSubscriptionItem = ({ inventoryItem }) => {
  return (
    inventoryItem?.subscriptionInterval &&
    parseInt(inventoryItem.subscriptionIntervalCount) > 0
  );
};

export const subscriptionParameterFields = ({ inventoryItem }) => {
  return {
    subscriptionInterval: inventoryItem.subscriptionInterval,
    subscriptionIntervalCount: inventoryItem.subscriptionIntervalCount,
  };
};

export const subscriptionFormatted = ({ inventoryItem }) => {
  let intervalCount = "";
  if (parseInt(inventoryItem.subscriptionIntervalCount) > 1) {
    intervalCount = inventoryItem.subscriptionIntervalCount;
  }
  return `Orders every ${intervalCount} ${inventoryItem.subscriptionInterval}${
    parseInt(inventoryItem.subscriptionIntervalCount) > 1 ? "s" : ""
  }`;
};

export const productNameForInventoryItem = ({ inventoryItem }) => {
  return inventoryItem.itemName === "others" && inventoryItem.otherItemName
    ? inventoryItem.otherItemName
    : inventoryItem.itemName;
};

export const productImageForInventoryItem = ({ inventoryItem }) => {
  return !inventoryItem.image ? DEFAULT_INVENTORY_IMAGE : inventoryItem.image;
};

export const productPriceForInventoryItem = ({ inventoryItem, userType }) => {
  if (userType === USER_TYPE.CUSTOMER) {
    return inventoryItem.consumerPrice;
  }
  if (userType === USER_TYPE.WHOLESALER) {
    return inventoryItem.wholeselerPrice;
  }
  return 0;
};

export const productUnitForInventoryItem = ({ inventoryItem, userType }) => {
  const keys = {};
  if (userType === USER_TYPE.CUSTOMER) {
    keys.unit = inventoryItem.consumerUnit;
    keys.otherUnit = inventoryItem.otherConsumerUnit;
  }
  if (userType === USER_TYPE.WHOLESALER) {
    keys.unit = inventoryItem.wholeselerUnit;
    keys.otherUnit = inventoryItem.otherWholeSalerUnit;
  }
  return Units(keys.unit, keys.otherUnit);
};

export const productPriceFromattedForInventoryItem = ({
  inventoryItem,
  userType,
}) => {
  const price = productPriceForInventoryItem({ inventoryItem, userType });
  const unit = productUnitForInventoryItem({ inventoryItem, userType });
  return `$${price} / ${unit}`;
};

export const productMaxQuantityForInventoryItem = ({
  inventoryItem,
  userType,
}) => {
  if (userType === USER_TYPE.CUSTOMER)
    return +inventoryItem.maximumConsumerQuantity;
  if (userType === USER_TYPE.WHOLESALER)
    return +inventoryItem.maximumWholeselerQuantity;
  return 0;
};

export const currentlyOrderableInventory = ({ inventory, distributions }) => {
  if (!Array.isArray(inventory) || !Array.isArray(distributions)) return [];
  return inventory.filter((inventoryItem) => {
    const userId = inventoryItem.userId;
    const openDistributions = openOrderWindowDistributionForUserId({
      distributionInfo: distributions,
      userId,
    });
    return openDistributions.length > 0;
  });
};
