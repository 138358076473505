import { Button, Container, Form } from "react-bootstrap";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import Loader from "../common/Loader";
import Url from "../../services/BaseUrl";
import _ from "lodash";
import { _Api } from "../../services/Api";
import { history } from "../../Routes";
import { isValidEmail } from "../../utils/Validation";
import { notify } from "../common/Tooster";

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      errors: [],
      responseError: "",
      oldPassword: "",
      confirmPassword: "",
      newPassword: "",
    };
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    });
  };

  handleValidate = () => {
    let validate = false;
    let errors = {};
    let { oldPassword, confirmPassword, newPassword } = this.state;
    if (_.isEmpty(oldPassword)) {
      validate = true;
      errors["oldPassword"] = "Old password is required.";
    } else if (oldPassword.length <= 4) {
      validate = true;
      errors["oldPassword"] = "Password must be greater than 4 digits.";
    } else if (_.isEmpty(newPassword)) {
      validate = true;
      errors["newPassword"] = "New password is required.";
    } else if (newPassword.length <= 4) {
      validate = true;
      errors["newPassword"] = "Password must be greater than 4 digits.";
    } else if (_.isEmpty(confirmPassword)) {
      validate = true;
      errors["confirmPassword"] = "Confirm password is required.";
    } else if (newPassword !== confirmPassword) {
      validate = true;
      errors["confirmPassword"] = "Password and Confirm password must be same.";
    } else if (newPassword == oldPassword) {
      validate = true;
      errors["confirmPassword"] =
        "New password and old password should not be same.";
    }
    this.setState({ errors });
    return validate;
  };

  // Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "",
  handleChangePassword = (e) => {
    e.preventDefault();
    if (!this.handleValidate()) {
      let { newPassword, oldPassword } = this.state;

      let req = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      _Api(Url.USER_CHANGE_PASSWORD.method, Url.USER_CHANGE_PASSWORD.url, req)
        .then((resp) => {
          notify("success", resp.responseData.message);
          history.push("/");
        })
        .catch((err) => {
          this.setState({ isLoader: false });
          if (err.error) notify("error", err.error.responseMessage);
        });
    }
  };

  render() {
    let { oldPassword, newPassword, confirmPassword, errors } = this.state;
    return (
      <div className="body_container_wrapper userprofile">
        <Container>
          <div className="usersetting-card">
            <Form.Group>
              <Form.Control
                type="password"
                placeholder="Old password"
                autoComplete="off"
                maxLength={20}
                value={oldPassword}
                name="oldPassword"
                onChange={this.handleOnChange}
              />
              <span className="error_msg">{errors["oldPassword"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                placeholder="New password"
                autoComplete="off"
                maxLength={20}
                value={newPassword}
                name="newPassword"
                onChange={this.handleOnChange}
              />
              <span className="error_msg">{errors["newPassword"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                maxLength={20}
                autoComplete="off"
                value={confirmPassword}
                name="confirmPassword"
                onChange={this.handleOnChange}
              />
              <span className="error_msg">{errors["confirmPassword"]}</span>
            </Form.Group>
            <Form.Group>
              <Button vairant="primary" onClick={this.handleChangePassword}>
                Change Password
              </Button>
            </Form.Group>
          </div>
        </Container>
      </div>
    );
  }
}

// export default Login
