import React from "react";
import { NullComponent } from "./common/Null";

const debug = require("debug")("PickupCard");

export const PickupCard = (props) => {
  const { pickup } = props;
  debug(pickup);
  //console.log(pickup);
  const link = `/marketpopups/marketpopuplist/${pickup._id}`;
  const pickupWindows = !Array.isArray(pickup.marketPickupWindows)
    ? null
    : pickup.marketPickupWindows.map((pickupWindow, pickupWindowIndex) => {
        return (
          <span
            className="hp-card-text-sub"
            key={`pickupWindow-${pickupWindowIndex}`}
          >
            {pickupWindow}
          </span>
        );
      });
  return (
    <div className="hp-card-col slide" key={`pickup-${pickup._id}`}>
      <div className="hp-card hp-card-pickup">
        <div className="hp-card-background"></div>
        <a href={link}>
          <div
            className="hp-card-header"
            style={{
              backgroundColor: pickup.marketBackgroundColor,
            }}
          >
            <img className="hp-card-icon" src={pickup.marketIcon} alt="" />
            <hgroup className="hp-card-heading-group">
              <h2 className="hp-card-heading">{pickup.marketName}</h2>
              <h3 className="hp-card-subheading">
                {pickup.address?.addressLine1}
              </h3>
            </hgroup>
          </div>
          <div className="hp-card-body">
            {pickup.marketOrderWindow && (
              <h4 className="hp-card-text-item mb1">
                <span className="hp-card-label">Order Window</span>
                <span className="hp-card-text">{pickup.marketOrderWindow}</span>
              </h4>
            )}
            <h4 className="hp-card-text-item">
              <span className="hp-card-label">Pick Up</span>
              <span className="hp-card-text">{pickupWindows}</span>
            </h4>
          </div>
          <div className="hp-card-link floating">
            <button className="">Order Now</button>
          </div>
        </a>
      </div>
    </div>
  );
};
