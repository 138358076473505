import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Url from "../services/BaseUrl";
import { _Api } from "../services/Api";
import { isEmpty } from "lodash";

import Slider from "react-slick";
import { notify } from "./common/Tooster.js";
import { FarmCard } from "./FarmCard";
import { currentlyOrderableInventory } from "../utils/inventory";
import { addNumberAvailableProducts } from "../utils/farm";

const debug = require("debug")("FarmCards");
const queryString = require("query-string");

const noop = () => undefined;

export const FarmCards = ({
  userType,
  zipCode,
  distributionType,
  sliderProps,
}) => {
  const [farms, setFarms] = useState([]);

  const useSlider = !isEmpty(sliderProps);

  // - get delivery distributions that deliver to zipcode
  // - get inventory from each farmer, that is for delviery/pickup
  // - locally filter inventory based on open distributions
  // - use that count to determine products available
  useEffect(() => {
    debug("query-farms");
    const queryParams = {
      zipCode,
      userType,
      distributionType,
    };
    if (distributionType) {
      queryParams.distributionType = distributionType;
    }

    _Api(
      Url.USER_ALL_FARM_DISTRIBUTIONS_INVENTORY.method,
      Url.USER_ALL_FARM_DISTRIBUTIONS_INVENTORY.url,
      "",
      `?${queryString.stringify(queryParams)}`,
    ).then((resp) => {
      debug("query-farm-result");
      const { inventory, distributions } = resp.responseData.result;
      setFarms(
        addNumberAvailableProducts({
          farms: resp.responseData.result.farms,
          inventory: currentlyOrderableInventory({ inventory, distributions }),
        }),
      );
    });
  }, [zipCode]);

  //console.log(useSlider);

  if (useSlider && farms.length > 3) {
    return (
      <Slider {...sliderProps} key="farm-slider">
        {farms.map((farm) => {
          //console.log(farm);
          const cardProps = {
            farm,
          };
          return <FarmCard {...cardProps} key={`farm-${farm._id}`} />;
        })}
      </Slider>
    );
  }

  if (!zipCode && farms.length === 0)
    return (
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <Alert
            variant="warning"
            className="order-now-no-results hp-alert hp-alert-large"
          >
            Please enter a zip code to see farms in your area
          </Alert>
        </div>
      </div>
    );

  if (zipCode && farms.length === 0)
    return (
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <Alert
            variant="warning"
            className="order-now-no-results hp-alert hp-alert-large"
          >
            There aren't currently any farms delivering to your zip code
          </Alert>
        </div>
      </div>
    );

  //initial case,
  if (useSlider && farms.length === 0) return null;

  //default condition - render farms in a grid, without slider
  return (
    <div className="cards-container cards-container-farms">
      {farms.map((farm) => {
        const cardProps = {
          farm,
        };
        return <FarmCard {...cardProps} key={`farm-${farm._id}`} />;
      })}
    </div>
  );
};
