import {
  Button,
  Col,
  Container,
  Dropdown,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { FarmProductCard } from "../../components/FarmProductCard";
import { nonShippableFromInventory } from "../../utils/inventory";
import { SubscriptionInfo } from "../../components/products.jsx";
import {
  GetCloseAndOpenStatus,
  Units,
  days,
  showAddress,
} from "../../utils/Helper";
import { USER_TYPE } from "../../utils/user";
import { Link, NavLink } from "react-router-dom";
import React, { Component } from "react";

import {
  isSubscriptionItem,
  subscriptionFormatted,
} from "../../utils/inventory";

import { Empty } from "antd";
import FarmerContact from "../data";
import Loader from "../../components/common/Loader";
import Login from "../../components/auth/Login";
// Import Markers On Map
import SignInAs from "../../components/auth/SignInAs";
import Signup from "../../components/auth/Signup";
import Slider from "react-slick";
import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCart } from "../../redux/action/Get";
import { notify } from "../../components/common/Tooster.js";
import { userProfile } from "../../redux/action/Get";

const debug = require("debug")("MarketPopupList");

const marketInfoAddressAsDistributionLocation = ({ market }) => {
  if (!market || !market.address) return "";
  // matches string value set in -farmer distribution settings
  // perhaps there should be a market id on the distribution instead?
  return (
    market.address.addressLine1 +
    " " +
    market.address.addressLine2 +
    " " +
    market.address.city +
    " " +
    market.address.state +
    " " +
    market.address.postalCode
  ).trim();
};

class Farms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketInfo: {},
      farmData: [],
      search: "",
      userType: USER_TYPE.CUSTOMER,
      isLoader: false,
      farmFilterDetails: {},
      SelectedState: "",
      selectedCity: "",
      currentLat: "",
      currentLong: "",
    };
  }

  componentDidMount() {
    let userType = localStorage.getItem("userType");
    if (userType) {
      userType = +userType;
    } else {
      userType = USER_TYPE.CUSTOMER;
    }
    this.setState({ userType: userType }, function () {
      this.getFarmByMarketDetails();
    });
  }

  getFarmByMarketDetails = () => {
    let { userType } = this.state;
    this.setState({ isLoader: true });
    let req = {
      marketId: this.props.match.params.id,
    };

    localStorage.setItem(
      "previousPurchaseUrl",
      `/marketpopups/marketpopuplist/${req.marketId}`,
    );

    _Api(
      Url.USER_VIEW_FARM_BY_MARKET_DETAILS.method,
      Url.USER_VIEW_FARM_BY_MARKET_DETAILS.url,
      "",
      `?marketId=${req.marketId}&userType=${userType}`,
    )
      .then((resp) => {
        console.log(resp);
        this.setState({
          marketInfo: resp.responseData.marketInfo,
          farmData: resp.responseData.farmInfo,
          isLoader: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  RemovehandleClose = () => {
    this.setState({ notLoginMsg: false });
  };

  addToCart = (product, farmId) => {
    if (localStorage.getItem("accessToken")) {
      const getQryKey = "qty_" + product._id;
      let getQry = this.state[getQryKey];
      if (!getQry > 0) {
        getQry = 1;
      }

      let productPrice = product.consumerPrice;
      let unit = "";

      if (localStorage.getItem("userType") == 3) {
        productPrice = product.wholeselerPrice;
        unit = Units(product.wholeselerUnit, product.otherWholeSalerUnit);
      } else {
        unit = Units(product.consumerUnit, product.otherConsumerUnit);
      }

      let req = {
        productQuantity: getQry,
        productPrice: productPrice,
        productId: product._id,
        farmId: farmId,
        unit: unit,
        variety: product.variety,
      };

      if (isSubscriptionItem({ inventoryItem: product })) {
        req.subscriptionInterval = product.subscriptionInterval;
        req.subscriptionIntervalCount = product.subscriptionIntervalCount;
      }

      this.setState({ isLoader: true });
      _Api(
        Url.USER_ADD_PRODUCT_TO_CART.method,
        Url.USER_ADD_PRODUCT_TO_CART.url,
        req,
      )
        .then((resp) => {
          this.setState({ isLoader: false });
          this.props.action.getCart();
        })
        .catch((err) => {
          this.setState({ isLoader: false });
          if (err.error) {
            notify("error", err.error.responseMessage);
          }
        });
    } else {
      this.setState({ notLoginMsg: true });
    }
  };

  checkout = () => {
    this.props.history.push({
      pathname: "/shoppingcart",
    });
  };

  render() {
    let { isLoader, userType, marketInfo, farmData } = this.state;

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
    };

    return (
      <div className="body_container_wrapper">
        <Container>
          {this.state.isLoader && <Loader />}

          <div className="imgcontent_wrapper">
            <div className="about_wrapper">
              <div className=" two_col_wrapper  ">
                <Row>
                  <Col md="4">
                    <div className="farmer_cardimg">
                      <img
                        alt=""
                        src={
                          marketInfo.marketImage == null
                            ? require("../../assets/img/placehoder_2.png")
                            : marketInfo.marketImage
                        }
                      />
                    </div>
                  </Col>
                  <Col md="8">
                    <div className="two_col_content">
                      <div className="heading">
                        <h3>
                          <span>{marketInfo.marketName}</span>
                        </h3>
                        <h6 className="content discription">
                          <span>{marketInfo.desc}</span>
                        </h6>
                        {marketInfo &&
                        marketInfo.address &&
                        (marketInfo.address.addressLine1 ||
                          marketInfo.address.addressLine2 ||
                          marketInfo.address.city ||
                          marketInfo.address.state ||
                          marketInfo.address.postalCode) ? (
                          <h6 className="address">
                            Address :
                            <span> {showAddress(marketInfo.address)}</span>
                          </h6>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          {this.state.farmData &&
          this.state.farmData.length &&
          marketInfo &&
          marketInfo._id ? (
            <>
              <div className="heading">
                <h3>
                  <span>Products</span>
                </h3>
              </div>
              <div className="product_list_wrapper">
                {farmData &&
                  farmData.map((viewFarmDetails, i) => {
                    debug("check-open-distribution:", viewFarmDetails.farmName);
                    {
                      /*const marketDistributionLocation =
                      marketInfoAddressAsDistributionLocation({
                        market: marketInfo,
                      });
                    debug(
                      "marketDistributionLocation:",
                      marketDistributionLocation,
                    );*/
                    }
                    //console.log(farmData);
                    const openDistribution =
                      viewFarmDetails.distributionInfo.find((distribution) => {
                        {
                          /*debug("distribution:address");
                        debug(distribution.distributionLocation?.address);
                        const matchingAddy =
                          distribution.distributionLocation?.address ===
                          marketDistributionLocation;
                        debug("distribution:address:equal:", matchingAddy);*/
                        }
                        const matchingMarketId =
                          distribution.marketId === marketInfo._id;
                        if (matchingMarketId)
                          debug(
                            "distribution:matching-market-id:address:",
                            distribution.distributionLocation?.address,
                          );
                        return (
                          matchingMarketId &&
                          GetCloseAndOpenStatus({
                            distributionInfo: distribution,
                          })
                        );
                      });

                    if (!openDistribution) return null;
                    debug("open-distribution:", viewFarmDetails.farmName);

                    const farmId = viewFarmDetails._id;

                    const nonShippableProducts = nonShippableFromInventory({
                      inventory: viewFarmDetails.inventoryInfo,
                    });

                    return nonShippableProducts.map((inventoryItem) => {
                      const cardProps = {
                        inventoryItem: {
                          ...inventoryItem,
                          farmName: viewFarmDetails.farmName,
                          farmId,
                        },
                        userType,
                        farmId,
                      };
                      return <FarmProductCard {...cardProps} />;
                    });
                  })}
              </div>
            </>
          ) : (
            <div className="text-center"> No products are available </div>
          )}

          <div className="text-center mt1 mb4">
            <button
              className="btn btn-warning text-light"
              onClick={this.checkout}
            >
              Checkout
            </button>
          </div>

          {this.state.farmData && this.state.farmData.length ? (
            <>
              <div> Farms </div>
              <div className="two_col_contentimg farmerlisting left-right-col">
                {farmData &&
                  farmData.map((viewFarmDetails, i) => {
                    let img = `url(${require("../../assets/img/providerfarm.png")})`;
                    if (viewFarmDetails.media[0].url) {
                      img = `url(${viewFarmDetails.media[0].url})`;
                    }

                    return (
                      <Row key={`farm-card-${i}`}>
                        <Col md="6">
                          <div className="farmer_card">
                            <div className="caption">
                              <div className="farmer_card_img_wrapper ">
                                <img
                                  alt=""
                                  src={
                                    viewFarmDetails.farmLogo == null
                                      ? require("../../assets/img/providerfarm.png")
                                      : viewFarmDetails.farmLogo
                                  }
                                />
                              </div>
                              <h4>{viewFarmDetails.farmName}</h4>
                            </div>
                            <div className="backgroundimgslide">
                              {viewFarmDetails.media &&
                              viewFarmDetails.media.length > 0 ? (
                                <>
                                  <Slider {...settings} className="">
                                    {viewFarmDetails.media.map((item, i) => (
                                      <img src={item.url} alt="" />
                                    ))}
                                  </Slider>
                                </>
                              ) : (
                                <img
                                  src={require("../../assets/img/thefarmers.png")}
                                  alt=""
                                />
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="two_col_content">
                            <h4>About {viewFarmDetails.farmName} </h4>
                            <Tabs defaultActiveKey="About">
                              <Tab eventKey="About" title="Info About Farm">
                                <h6>{viewFarmDetails.infoAboutFarm}</h6>
                              </Tab>
                              <Tab eventKey="Location" title="Location">
                                <h6>
                                  {showAddress(viewFarmDetails.farmAddress[0])}
                                </h6>
                              </Tab>
                              <Tab eventKey="Order Window" title="Order Window">
                                {viewFarmDetails.distributionInfo &&
                                  viewFarmDetails.distributionInfo.map(
                                    (obj, i) => {
                                      return (
                                        <>
                                          {" "}
                                          <h6>{`${
                                            days[obj.placeOrderOpen.day]
                                          } ${obj.placeOrderOpen.time} ${
                                            obj.placeOrderOpen.meridiem
                                          } - ${
                                            days[obj.placeOrderClose.day]
                                          } ${obj.placeOrderClose.time} ${
                                            obj.placeOrderClose.meridiem
                                          }`}</h6>
                                        </>
                                      );
                                    },
                                  )}
                              </Tab>
                              <Tab
                                eventKey="Pick Up Location & Time"
                                title="Pick Up Location & Time"
                              >
                                {viewFarmDetails.distributionInfo &&
                                  viewFarmDetails.distributionInfo.map(
                                    (obj, i) => {
                                      return (
                                        <>
                                          {obj.pickup.length > 0 ? (
                                            <div className="adress-time-wrapper">
                                              <p>
                                                {
                                                  obj.distributionLocation
                                                    .address
                                                }
                                              </p>
                                              {obj.pickup.map((item, index) => {
                                                let time = "";
                                                item.time.forEach(
                                                  (shiftTime, i) => {
                                                    time =
                                                      time +
                                                      "," +
                                                      shiftTime.starttime +
                                                      "-" +
                                                      shiftTime.endTime;
                                                  },
                                                );
                                                return (
                                                  <p key={index}>
                                                    {days[item.day] +
                                                      "  " +
                                                      time}
                                                  </p>
                                                );
                                              })}
                                            </div>
                                          ) : null}
                                        </>
                                      );
                                    },
                                  )}
                              </Tab>
                            </Tabs>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            </>
          ) : null}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.login.isLoggedIn,
    profiledetails: state.profile,
    cartListDetails: state.cartList.cartListDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({ userProfile, getCart }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Farms);
