import { SET_ORDER_NOW_ZIPCODE } from "../action/order-now";

export const ORDER_NOW_ZIP_CODE_EMPTY = {
  INITIAL: undefined,
  CLEARED: null,
};

const orderNowState = {
  zipCode: ORDER_NOW_ZIP_CODE_EMPTY.INITIAL,
};

export const orderNow = (state = orderNowState, action) => {
  switch (action.type) {
    case SET_ORDER_NOW_ZIPCODE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
