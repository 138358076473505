import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import React, { Component } from "react";
import { SubscriptionInfo } from "../../components/products.jsx";
import {
  checkInventoryAvailable,
  hasUnavailableProductInProductList,
  noProductsAvailable,
} from "../../utils/inventory";
import {
  deliveryAvailable,
  formatDayAndTime,
  formatDeliveryInfo,
  getDeliveryFlatFee,
  getOpenOrderDeliveryZipCodesString,
  isValidAddress,
  isNonEmptyAddress,
  hasPickupAvailable,
  hasOpenOrderDistribution,
  getOpenOrderDistribution,
  getOpenOrderPickupDistribution,
  hasOpenOrderPickupDistribution,
  getOpenOrderDeliveryDistribution,
  hasOpenOrderDeliveryDistribution,
  getOpenOrderDeliveryDistributionForDeliveryZipCode,
  hasOpenOrderDeliveryDistributionForDeliveryZipCode,
  DISTRIBUTION_CATEGORY,
  getShippingDistribution,
  hasOpenOrderShippingDistribution,
  getZipCodes,
  DISTRIBUTION_TYPE,
  DISTRIBUTION_COURIER_TYPE,
} from "../../utils/distribution";
import { cartShippedProducts, cartNonShippedBuyList } from "../../utils/cart";
import { USER_TYPE } from "../../utils/user";

import { GetCloseAndOpenStatus } from "../../utils/Helper";
import Loader from "../../components/common/Loader";
import { NullComponent } from "../../components/common/Null";
import Url from "../../services/BaseUrl";
import { isEmpty, isNil } from "lodash";
import { _Api } from "../../services/Api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCart } from "../../redux/action/Get";
import { notify } from "../../components/common/Tooster";
const debug = require("debug")("shopping-cart");

class ShoppingCart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userType: USER_TYPE.CUSTOMER,
      deliveryInfo: {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
        phoneNumber: "",
      },
      deliveryInfoErrors: [],

      getApiResponse: false,
      getApiFarmDetail: false,
      isMobile: false,
      isLoader: false,
      minQtyMsg: false,
      cartListDetails: undefined,
      getFarmInfo: [],
      productPrice: "",
      removeitem: false,
      productListId: "",
      priceDetails: "",

      showAddressForm: false,
      getApiResponse: false,
      transferArray: [],
      totalPrice: 0,
      totalSipping: 0,
      totalPayAmount: 0,

      // key: farmId
      // value: { activeKey: 'pickup|delivery'}
      farmDistributionTabState: {},
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));

    let userType = +localStorage.getItem("userType");
    if (!userType) {
      userType = USER_TYPE.CUSTOMER;
    }
    this.setState({ userType: userType }, () => {
      debug("did-mount:userType-set");
      this.getCartList();
    });

    this.resize();
  }
  resize() {
    this.setState({ isMobile: window.innerWidth <= 760 });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  componentWillReceiveProps(nextProps) {
    // we want our userprofile address and cartdetails to process
    // this next step. if we have both, we can see if the cartdetails
    // requires us to update it with an address
    const customerAddresses = nextProps.profiledetails?.profileInfo?.address;
    const cartListDetails = this.state.cartListDetails;
    // we don't have our user profile addresses yet, early exit
    if (customerAddresses === undefined) return;
    // we don't have cartListDetails yet, early exit
    if (cartListDetails === undefined) return;

    this.setDefaultDeliveryInfo({ customerAddresses, cartListDetails });
  }

  setDefaultDeliveryInfo = ({ customerAddresses, cartListDetails }) => {
    debug("setDefaultDeliveryInfo");
    const initialCartDeliveryAddressIsNotEmpty = isNonEmptyAddress(
      cartListDetails.deliveryInfo,
    );
    if (initialCartDeliveryAddressIsNotEmpty) {
      debug("setDefaultDeliveryInfo:already-set:cart");
      debug(cartListDetails.deliveryInfo);
      debug("setDefaultDeliveryInfo:already-set:state");
      debug(this.state.deliveryInfo);
      return;
    }
    // customerAddresses, cartListDetails.buyList, determine address
    // buyList is an array of products from specific farms
    const deliverableAddresses = [];
    if (
      Array.isArray(cartListDetails.buyList) &&
      Array.isArray(customerAddresses)
    ) {
      cartListDetails.buyList.forEach((buyProductInfo) => {
        const deliverableZipCodes = getOpenOrderDeliveryZipCodesString(
          buyProductInfo.distributionDetails,
        );
        customerAddresses.forEach((customerAddress) => {
          if (deliverableZipCodes.indexOf(customerAddress.postalCode) !== -1) {
            deliverableAddresses.push(customerAddress);
          }
        });
      });
    }

    // ? no customer addresses that match delivery addresses, set the first
    //    address as the address to deliver to
    // : we don't have a concept of a default address, so we go
    //    with the first we have
    const deliveryToAddress =
      deliverableAddresses.length > 0
        ? deliverableAddresses[0]
        : Array.isArray(customerAddresses) && customerAddresses.length > 0
        ? customerAddresses[0]
        : null;

    if (isNonEmptyAddress(deliveryToAddress)) {
      debug("setDefaultDeliveryInfo:has-non-empty-address");
      this.setState({ deliveryInfo: deliveryToAddress }, () => {
        this.updateCartDeliveryInfo();
      });
    } else {
      debug("setDefaultDeliveryInfo:none-set");
    }
  };

  getCartList = () => {
    this.setState({ isLoader: true });
    debug("getCartList");
    _Api(Url.USER_GET_CART_LIST.method, Url.USER_GET_CART_LIST.url)
      .then((resp) => {
        debug("getCartList:response");
        this.setState({ isLoader: false, getApiResponse: true });
        if (resp.responseData && resp.responseData.result) {
          debug("getCartList:response:result");
          let cartListDetails = resp.responseData.result;

          let getAllFarmIds = [];
          if (
            cartListDetails &&
            cartListDetails.buyList &&
            cartListDetails.buyList.length > 0
          ) {
            cartListDetails &&
              cartListDetails.buyList.map((buyList, i) => {
                getAllFarmIds.push(buyList.farmId);
                buyList &&
                  buyList.productList.map((product, i) => {
                    this.state["qty_" + `${product.productId}`] =
                      product.productQuantity;
                  });
              });
          }

          // appears that this "deliveryInfo" is only used as a temp
          // storage for the form field modal. doesn't have an impact
          // on the api comms until `updateCartDeliveryInfo` is run
          if (
            !this.state.deliveryInfo &&
            isNonEmptyAddress(cartListDetails.deliveryInfo)
          ) {
            debug("getCartList:response:set-delivery-info");
            this.setState({ deliveryInfo: cartListDetails.deliveryInfo });
          }

          this.setState({ cartListDetails: cartListDetails }, function () {
            this.getFarmsDetails(getAllFarmIds);
          });
        }
      })
      .catch((err) => {
        debug("getCartList:error");
        debug(err);
        this.setState({ isLoader: false, getApiResponse: true });
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  getFarmsDetails = (getAllFarmIds) => {
    if (!this.state.getApiFarmDetail && getAllFarmIds.length > 0) {
      let userType = localStorage.getItem("userType")
        ? localStorage.getItem("userType")
        : 2;
      _Api(
        Url.USER_GET_FARM_BY_IDS.method,
        Url.USER_GET_FARM_BY_IDS.url,
        "",
        `?farmIds=${getAllFarmIds}&userType=${userType}`,
      )
        .then((resp) => {
          this.setState({ getApiFarmDetail: true });
          if (resp.statusCode === 1) {
            let getFarmInfo = resp.responseData.farmInfo;
            // set the default active tab to pickup
            const farmDistributionTabState = getFarmInfo
              .map((farmInfo) => {
                return {
                  farmId: farmInfo._id,
                  // Default tab view to delivery if userType is Wholesale or School
                  activeKey:
                    userType == USER_TYPE.WHOLESALER ? "delivery" : "pickup",
                };
              })
              .reduce((accumulator, state) => {
                accumulator[state.farmId] = state.activeKey;
                return accumulator;
              }, {});
            this.setState(
              { getFarmInfo, farmDistributionTabState },
              function () {
                this.setFarmInfo();
              },
            );
          }
        })
        .catch((err) => {
          this.setState({ getApiFarmDetail: true });
          if (err.error) {
            notify("error", err.error.responseMessage);
          }
        });
    } else {
      this.setFarmInfo();
    }
  };

  setFarmInfo = () => {
    let { getFarmInfo, cartListDetails } = this.state;

    if (!Array.isArray(cartListDetails.buyList)) return;

    cartListDetails.buyList.map((buyList) => {
      const farmDetails = getFarmInfo.find((farmInfo) => {
        return farmInfo._id === buyList.farmId;
      });
      if (farmDetails) {
        let showProcut = false;
        let shipping_fee = 0;
        let flat_fee = 0;

        farmDetails.deliveryFees = 0;
        farmDetails.flatFees = 0;
        farmDetails.farmradius = 0;

        farmDetails.distributionInfo &&
          farmDetails.distributionInfo.map((distributionInfo) => {
            if (!showProcut) {
              showProcut = GetCloseAndOpenStatus({ distributionInfo });
            }

            if (distributionInfo.deliveryFees > shipping_fee) {
              farmDetails.deliveryFees = distributionInfo.deliveryFees;
              farmDetails.farmradius = distributionInfo.radius;

              shipping_fee = distributionInfo.deliveryFees;
            }
            if (distributionInfo.flatFee > flat_fee) {
              farmDetails.isFlatFee = true;
              farmDetails.flatFees = distributionInfo.flatFees;
              farmDetails.farmradius = distributionInfo.radius;

              flat_fee = distributionInfo.flatFee;
            }
          });

        const updatedProductList = checkInventoryAvailable(
          farmDetails,
          buyList,
        );

        buyList.deliveryFees = farmDetails.deliveryFees;
        buyList.flatFees = farmDetails.flatFees;
        buyList.farmradius = farmDetails.farmradius;
        buyList.distributionDetails = farmDetails.distributionInfo;
        buyList.farmAddress = farmDetails.farmAddress?.[0];

        buyList.productList = updatedProductList;
        buyList.isDisabled =
          hasUnavailableProductInProductList(updatedProductList);
      } else {
        buyList.productList = noProductsAvailable(buyList.productList);
        buyList.isDisabled = true;
      }
    });

    this.setState({ cartListDetails }, () => {
      if (isValidAddress(this.state.cartListDetails.deliveryInfo)) return;
      if (!this.props.profiledetails?.profileInfo?.address) return;
      this.setDefaultDeliveryInfo({
        customerAddresses: this.props.profiledetails?.profileInfo?.address,
        cartListDetails: this.state.cartListDetails,
      });
    });
  };

  RemovehandleClose = () => {
    this.setState({ removeitem: false, minQtyMsg: false });
  };

  RemovehandleShow = (id, farmId) => {
    this.setState({ removeitem: true, productListId: id, farmId: farmId });
  };

  itemDeleteToCart = () => {
    debug("itemDeleteToCart");
    let req = {
      productListId: this.state.productListId,
      farmId: this.state.farmId,
    };
    this.setState({ isLoader: true });
    _Api(
      Url.USER_DELETE_PRODUCT_TO_CART.method,
      Url.USER_DELETE_PRODUCT_TO_CART.url,
      req,
    )
      .then((resp) => {
        this.setState({ isLoader: false });
        if (resp.statusCode === 1) {
          this.RemovehandleClose();
          this.getCartList();
          this.props.action.getCart();
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  redirectToPreviousUrl = () => {
    let previousPurchaseUrl = localStorage.getItem("previousPurchaseUrl");
    if (previousPurchaseUrl) {
      this.props.history.push({ pathname: `${previousPurchaseUrl}` });
    } else {
      this.props.history.push({ pathname: `/farms` });
    }
  };

  handleQtyChanged = (item, farmId, e) => {
    debug("handleQtyChanged");
    let name = e.target.name;
    let value = e.target.value;

    if (e.target.value > 0) {
      this.setState({ [name]: value });
      let req = {
        productQuantity: e.target.value,
        productId: item.productId,
        farmId: farmId,
      };
      this.setState({ isLoader: true });
      _Api(Url.USER_EDIT_PRODUCT_QTY.method, Url.USER_EDIT_PRODUCT_QTY.url, req)
        .then((resp) => {
          this.getCartList();
          this.setState({ isLoader: false });
        })
        .catch((err) => {
          this.setState({ [name]: name, isLoader: false });
          this.getCartList();
          if (err.error) {
            notify("error", err.error.responseMessage);
          }
        });
    } else if (e.target.value === 0 && e.target.value !== "") {
      this.setState({ minQtyMsg: true });
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleDistributionTabSelect = ({ key, farmId }) => {
    const { farmDistributionTabState } = this.state;
    farmDistributionTabState[farmId] = key;
    this.setState({ farmDistributionTabState });
  };

  handleOnPickupAddress = ({ farmId, marketId }, e) => {
    debug("handleOnPickupAddress");
    if (marketId === undefined) marketId = null;
    let req = {
      farmId,
      marketId,
      courierType: "Pickup",
      address: e.target.value,
      deliveryFees: 0,
      day: e.target.getAttribute("dayvalue"),
      marketId,
    };

    this.setState({ isLoader: true });
    _Api(Url.USER_UPDATE_CART.method, Url.USER_UPDATE_CART.url, req)
      .then((resp) => {
        this.setState({ isLoader: false });
        if (resp.statusCode === 1) {
          this.getCartList();
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  handleOnDeliveryDayTime = (
    { buyProductInfo, deliveryFlatFee, address, day },
    e,
  ) => {
    debug("handleOnDeliveryDayTime");
    const { farmId, distributionDetails } = buyProductInfo;
    let req = {
      farmId: farmId,
      courierType: "Delivery",
      address,
      day,
      deliveryFees: deliveryFlatFee,
    };

    this.setState({ isLoader: true });
    _Api(Url.USER_UPDATE_CART.method, Url.USER_UPDATE_CART.url, req)
      .then((resp) => {
        this.setState({ isLoader: false });
        if (resp.statusCode === 1) {
          this.getCartList();
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  closeAddressForm = () => {
    this.setState({ showAddressForm: false });
  };

  openAddressForm = () => {
    this.setState({ showAddressForm: true });
  };

  handleDeliveryAddressOnChange = (e) => {
    this.setState({
      deliveryInfo: {
        ...this.state.deliveryInfo,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleAddressValidate = () => {
    let valid = true;
    let errors = {};
    let { addressLine1, state, city, postalCode } = this.state.deliveryInfo;

    if (isEmpty(addressLine1)) {
      valid = false;
      errors["addressLine1"] = "Address line 1 is required.";
    }
    if (isEmpty(state)) {
      valid = false;
      errors["state"] = "State is required.";
    }
    if (isEmpty(city)) {
      valid = false;
      errors["city"] = "City is required.";
    }
    if (isEmpty(postalCode)) {
      valid = false;
      errors["postalCode"] = "Zip code is required.";
    }

    this.setState({ deliveryInfoErrors: errors });

    return valid;
  };

  updateCartDeliveryInfo = () => {
    debug("updateCartDeliveryInfo");
    debug(this.state.deliveryInfo);

    this.setState({ isLoader: true });

    return _Api(
      Url.USER_UPDATE_CART_DELIVERY_INFO.method,
      Url.USER_UPDATE_CART_DELIVERY_INFO.url,
      { deliveryInfo: this.state.deliveryInfo },
    )
      .then((resp) => {
        debug("updateCartDeliveryInfo:response:statusCode");
        debug(resp.statusCode);
        this.setState({ isLoader: false });
        if (resp.statusCode === 1) {
          this.closeAddressForm();
          this.getCartList();
          this.updateCartWithAddress();
        }
      })
      .catch((err) => {
        debug("updateCartDeliveryInfo:error:");
        debug(err);
        this.setState({ isLoader: false });
        /* 8-3-22 */
        //commenting out the below error notification as a temporary solution. When the cart is empty, there is an error for "AddCart User Not Found".
        //if (err.error) notify('error', err.error.responseMessage);
      });
  };

  updateCartWithAddress = () => {
    const { cartListDetails } = this.state;
    const nonShippedBuyList = cartNonShippedBuyList({ cart: cartListDetails });
    if (nonShippedBuyList.length > 0) {
      nonShippedBuyList.forEach((buylist) => {
        if (buylist.courierType === "Delivery" || buylist.courierType === "") {
          const { deliveryInfo } = this.state;

          const req = {
            farmId: buylist.farmId,
            address: formatDeliveryInfo(deliveryInfo),
          };

          this.setState({ isLoader: true });

          _Api(Url.USER_UPDATE_CART.method, Url.USER_UPDATE_CART.url, req)
            .then((resp) => {
              this.setState({ isLoader: false });
              if (resp.statusCode === 1) {
                this.getCartList();
              }
            })
            .catch((err) => {
              this.setState({ isLoader: false });
              if (err.error) notify("error", err.error.responseMessage);
            });
        }
      });
    }
  };

  handleOnSaveAddress = () => {
    if (!this.handleAddressValidate()) {
      return;
    }
    this.updateCartDeliveryInfo();
  };

  isDeliveryAvailable = (distributionDetails) => {
    const { cartListDetails } = this.state;
    const { deliveryInfo } = cartListDetails;

    return deliveryAvailable(deliveryInfo, distributionDetails);
  };

  redirectToBuy = () => {
    this.props.history.push({ pathname: `/farms` });
  };

  redirectToCheckout = () => {
    const debugCheckout = debug.extend("checkout");

    let continueProcess = 1;
    let { cartListDetails, getFarmInfo, userType } = this.state;

    // process shipped products
    const zipCode = cartListDetails.deliveryInfo?.postalCode;
    const shippedProducts = cartShippedProducts({ cart: cartListDetails });
    const hasShippedProducts = shippedProducts.length > 0;
    if (hasShippedProducts && !zipCode) {
      continueProcess = 0;
      notify("Error", "Must enter an address to ship to.");
    }

    shippedProducts.forEach(({ productListEntry, farmId }) => {
      const farms = getFarmInfo.filter((farm) => {
        return farm._id === farmId;
      });
      let farm;
      if (farms.length == 1) {
        farm = farms[0];
      } else {
        continueProcess = 0;
        notify("Error", `No farm host for ${productListEntry.productName}`);
        return;
      }
      const distributionInfo = farm.distributionInfo;
      const shippingDistributions = getShippingDistribution({
        distributionInfo,
        userType,
      });
      const openShippingDistributions = getOpenOrderDistribution(
        shippingDistributions,
      );
      if (openShippingDistributions.length === 0) {
        continueProcess = 0;
        notify(
          "Error",
          `The order window has closed for ${productListEntry.productName}`,
        );
        return;
      }
      const openShippingZipCodes = getZipCodes({
        distributionInfo: openShippingDistributions,
      });
      const hasOpenShippingOrderWindow = openShippingDistributions.length > 0;
      const canShipToZipCode = openShippingZipCodes.includes(zipCode);
      if (!canShipToZipCode) {
        continueProcess = 0;
        notify(
          "Error",
          `Shipping not available for ${productListEntry.productName} to the address specified`,
        );
        return;
      }
    });

    // process pickup / delivery products
    const nonShippedBuyList = cartNonShippedBuyList({ cart: cartListDetails });

    nonShippedBuyList.forEach((buyProductInfo) => {
      if (buyProductInfo && buyProductInfo.length === 9) return;

      const farm = getFarmInfo.find((farm) => {
        return farm._id === buyProductInfo.farmId;
      });
      if (!farm) {
        continueProcess = 0;
        notify("Error", "Farm does not exist");
        return;
      }
      const { distributionInfo } = farm;
      const openPickupDistributions =
        getOpenOrderPickupDistribution(distributionInfo);
      const openDeliveryDistributions =
        getOpenOrderDeliveryDistribution(distributionInfo);
      const hasOpenPickupDistributions = openPickupDistributions.length > 0;
      const hasOpenDeliveryDistributions = openDeliveryDistributions.length > 0;

      if (
        continueProcess &&
        hasOpenPickupDistributions === false &&
        hasOpenDeliveryDistributions === false
      ) {
        continueProcess = 0;
        debugCheckout("order window closed");
        let errorMsg = `${buyProductInfo.farmName} Order window is closed`;
        notify("error", errorMsg);
      }
      if (
        continueProcess &&
        buyProductInfo.courierType === DISTRIBUTION_COURIER_TYPE.DELIVERY &&
        (!buyProductInfo.address || !buyProductInfo.day)
      ) {
        continueProcess = 0;
        debugCheckout("delivery issue");
        debugCheckout(buyProductInfo.address);
        debugCheckout(buyProductInfo.day);
        let errorMsg = `Please select delivery location for ${buyProductInfo.farmName}`;
        notify("error", errorMsg);
      }
      if (
        continueProcess &&
        buyProductInfo.courierType === DISTRIBUTION_COURIER_TYPE.PICKUP &&
        !buyProductInfo.address
      ) {
        continueProcess = 0;
        debugCheckout("pickup issue");
        debugCheckout("addresss");
        let errorMsg = `Please select pick up location for ${buyProductInfo.farmName}`;
        notify("error", errorMsg);
      }
      if (
        continueProcess &&
        buyProductInfo.courierType !== DISTRIBUTION_COURIER_TYPE.PICKUP &&
        buyProductInfo.courierType !== DISTRIBUTION_COURIER_TYPE.DELIVERY
      ) {
        continueProcess = 0;
        debugCheckout("no courier type");
        debugCheckout(buyProductInfo.courierType);
        let errorMsg = `Please select pick up or delivery location for ${buyProductInfo.farmName}`;
        notify("error", errorMsg);
      }

      if (
        continueProcess &&
        buyProductInfo.courierType === DISTRIBUTION_COURIER_TYPE.DELIVERY
      ) {
        if (!this.isDeliveryAvailable(buyProductInfo.distributionDetails)) {
          continueProcess = 0;
          debugCheckout("delivery to zip code not available");
          let errorMsg = `Delivery is not available for your address for ${buyProductInfo.farmName}. Please choose pickup option or update your address.`;
          notify("error", errorMsg);
        }
      }
    });

    if (continueProcess) {
      this.props.history.push({ pathname: `/ordersummary` });
    }
  };

  isCheckoutDisabled = () => {
    const { cartListDetails } = this.state;

    if (!cartListDetails || !cartListDetails.buyList) {
      return true;
    }

    const { buyList } = cartListDetails;

    const disabledBuyList = buyList.find((list) => list.isDisabled);

    return disabledBuyList !== undefined;
  };

  render() {
    // const { profiledetails  } = this.props

    let {
      getApiResponse,
      getApiFarmDetail,
      cartListDetails,
      getFarmInfo,
      userType,
    } = this.state;

    const shippedProducts = cartShippedProducts({ cart: cartListDetails });
    const hasShippedProducts = shippedProducts.length > 0;
    let ShippedProducts = NullComponent;
    if (hasShippedProducts) {
      ShippedProducts = () => (
        <div className="cart-block">
          <h2 className="cart-block-heading bold font-main">
            Shipped Products
          </h2>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>QTY</th>
                  <th>Price</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {shippedProducts.map(({ productListEntry, farmId }) => {
                  const zipCode = cartListDetails.deliveryInfo?.postalCode;
                  const farms = getFarmInfo.filter((farm) => {
                    return farm._id === farmId;
                  });
                  let farm;
                  let hasFarm = false;
                  let distributionInfo = [];
                  let shippingDistributions = [];
                  let openShippingDistributions = [];
                  let openShippingZipCodes = [];
                  let hasOpenShippingOrderWindow = false;
                  let canShipToZipCode = false;
                  if (farms.length == 1) {
                    farm = farms[0];
                    let hasFarm = true;
                    distributionInfo = farm.distributionInfo;
                    shippingDistributions = getShippingDistribution({
                      distributionInfo,
                      userType,
                    });
                    openShippingDistributions = getOpenOrderDistribution(
                      shippingDistributions,
                    );
                    openShippingZipCodes = getZipCodes({
                      distributionInfo: openShippingDistributions,
                    });
                    hasOpenShippingOrderWindow =
                      openShippingDistributions.length > 0;
                    canShipToZipCode = openShippingZipCodes.includes(zipCode);
                  }

                  let ErrorRow = NullComponent;
                  if (!hasOpenShippingOrderWindow) {
                    ErrorRow = () => (
                      <tr className="error-row cart-error-row">
                        <td colspan="5">
                          <Alert variant="danger">
                            The order window for this product has closed. Remove
                            this item from your cart to check out.
                          </Alert>
                        </td>
                      </tr>
                    );
                  } else if (!canShipToZipCode) {
                    ErrorRow = () => (
                      <tr className="error-row cart-error-row">
                        <td colspan="5">
                          <Alert variant="danger">
                            Currently shipping only to{" "}
                            {openShippingZipCodes.join(", ")}. <br />
                            Please update your shipping address or remove this
                            item from your cart to check out.
                          </Alert>
                        </td>
                      </tr>
                    );
                  }
                  return (
                    <React.Fragment
                      key={`product-list-${productListEntry.productId}`}
                    >
                      <tr>
                        <td>
                          <div className="shopping_list">
                            <div className="left-img">
                              <img
                                src={
                                  productListEntry.image === ""
                                    ? require("../../assets/img/placehoder_2.png")
                                    : productListEntry.image
                                }
                                alt={productListEntry.productName}
                              />
                              {this.state.isMobile && (
                                <div>
                                  <button
                                    onClick={() =>
                                      this.RemovehandleShow(
                                        productListEntry._id,
                                        farmId,
                                      )
                                    }
                                    className="btn btn-secondary size_small"
                                  >
                                    Remove
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className="content">
                              <h6>{productListEntry.productName}</h6>
                              <p> {productListEntry.unit} </p>
                              {getApiFarmDetail &&
                                !productListEntry.isAvailable && (
                                  <p className="error">
                                    This product is no longer available
                                  </p>
                                )}
                              <SubscriptionInfo
                                inventoryItem={productListEntry}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <input
                            disabled={!productListEntry.isAvailable}
                            type="number"
                            name={`qty_${productListEntry.productId}`}
                            placeholder={productListEntry.productQuantity}
                            className="form-control"
                            value={
                              this.state[
                                "qty_" + `${productListEntry.productId}`
                              ]
                            }
                            onChange={this.handleQtyChanged.bind(
                              this,
                              productListEntry,
                              farmId,
                            )}
                          />
                        </td>
                        <td>
                          <p>${productListEntry.productPrice.toFixed(2)}</p>
                        </td>
                        <td>
                          <p>
                            $
                            {(
                              productListEntry.productPrice *
                              productListEntry.productQuantity
                            ).toFixed(2)}
                          </p>
                        </td>
                        {!this.state.isMobile && (
                          <td>
                            <button
                              onClick={() =>
                                this.RemovehandleShow(
                                  productListEntry._id,
                                  farmId,
                                )
                              }
                              className="btn btn-secondary size_small"
                            >
                              Remove
                            </button>
                          </td>
                        )}
                      </tr>
                      <ErrorRow />
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="shopping_cart_delivery_note">
            <p>
              Products ship 1-2 business days after the farmer's order window
              closes
            </p>
          </div>
          {cartListDetails.deliveryInfo && (
            <div className="shopping_cart_delivery">
              <h6 className="deliver-to">Ship to: </h6>
              <span className="deliver-to-address">
                {formatDeliveryInfo(cartListDetails.deliveryInfo)}
              </span>
              <Button variant="link" onClick={this.openAddressForm}>
                {formatDeliveryInfo(cartListDetails.deliveryInfo)
                  ? "Edit"
                  : "Add Address"}
              </Button>
            </div>
          )}
        </div>
      );
    }

    const nonShippedBuyList = cartNonShippedBuyList({ cart: cartListDetails });
    const hasNonShippedBuyList = nonShippedBuyList.length > 0;

    return (
      <div className="body_container_wrapper" id="cart">
        {this.state.isLoader && <Loader />}
        <Container>
          <div className="shoopingcart_list latestchange">
            {cartListDetails &&
            cartListDetails.buyList &&
            cartListDetails.buyList.length > 0 ? (
              <>
                <h1 className="font-main h4 mt2">SHOPPING CART</h1>
                <div className="table-with-heading">
                  <ShippedProducts />
                  {hasNonShippedBuyList &&
                    nonShippedBuyList.map(
                      (buyProductInfo, buyProductInfoIndex) => {
                        const farm = getFarmInfo.find((farm) => {
                          return farm._id === buyProductInfo.farmId;
                        });
                        let hasFarm = false;
                        let openPickupDistributions = [];
                        let openDeliveryDistributions = [];
                        let hasOpenPickupDistributions = false;
                        let hasOpenDeliveryDistributions = false;
                        if (farm) {
                          hasFarm = true;
                          const { distributionInfo } = farm;
                          openPickupDistributions =
                            getOpenOrderPickupDistribution(distributionInfo);
                          openDeliveryDistributions =
                            getOpenOrderDeliveryDistribution(distributionInfo);
                          hasOpenPickupDistributions =
                            openPickupDistributions.length > 0;
                          hasOpenDeliveryDistributions =
                            openDeliveryDistributions.length > 0;
                        }
                        return (
                          <div key={`buy-product-info-${buyProductInfoIndex}`}>
                            {buyProductInfo.productList &&
                              buyProductInfo.productList.length > 0 && (
                                <div className="cart-block">
                                  <h2 className="cart-block-heading bold font-main">
                                    {" "}
                                    {buyProductInfo.farmName} Products
                                  </h2>

                                  {hasFarm === true &&
                                    hasOpenPickupDistributions === false &&
                                    hasOpenDeliveryDistributions === false && (
                                      <div className="text-danger">
                                        Order window is closed{" "}
                                      </div>
                                    )}

                                  <div className="table-responsive">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>Item</th>
                                          <th>QTY</th>
                                          <th>Price</th>
                                          <th>Total Price</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {buyProductInfo.productList &&
                                          buyProductInfo.productList.map(
                                            (item) => {
                                              return (
                                                <tr
                                                  key={`product-list-${item.productId}`}
                                                >
                                                  <td>
                                                    <div className="shopping_list">
                                                      <div className="left-img">
                                                        <img
                                                          src={
                                                            item.image === ""
                                                              ? require("../../assets/img/placehoder_2.png")
                                                              : item.image
                                                          }
                                                          alt={item.productName}
                                                        />
                                                        {this.state
                                                          .isMobile && (
                                                          <div>
                                                            <button
                                                              onClick={() =>
                                                                this.RemovehandleShow(
                                                                  item._id,
                                                                  buyProductInfo.farmId,
                                                                )
                                                              }
                                                              className="btn btn-secondary size_small"
                                                            >
                                                              Remove
                                                            </button>
                                                          </div>
                                                        )}
                                                      </div>
                                                      <div className="content">
                                                        <h6>
                                                          {item.productName}
                                                        </h6>
                                                        <p> {item.unit} </p>
                                                        {getApiFarmDetail &&
                                                          !item.isAvailable && (
                                                            <p className="error">
                                                              This product is no
                                                              longer available
                                                            </p>
                                                          )}
                                                        <SubscriptionInfo
                                                          inventoryItem={item}
                                                        />
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <input
                                                      disabled={
                                                        !item.isAvailable
                                                      }
                                                      type="number"
                                                      name={`qty_${item.productId}`}
                                                      placeholder={
                                                        item.productQuantity
                                                      }
                                                      className="form-control"
                                                      value={
                                                        this.state[
                                                          "qty_" +
                                                            `${item.productId}`
                                                        ]
                                                      }
                                                      onChange={this.handleQtyChanged.bind(
                                                        this,
                                                        item,
                                                        buyProductInfo.farmId,
                                                      )}
                                                    />
                                                  </td>
                                                  <td>
                                                    <p>
                                                      $
                                                      {item.productPrice.toFixed(
                                                        2,
                                                      )}
                                                    </p>
                                                  </td>
                                                  <td>
                                                    <p>
                                                      $
                                                      {(
                                                        item.productPrice *
                                                        item.productQuantity
                                                      ).toFixed(2)}
                                                    </p>
                                                  </td>
                                                  {!this.state.isMobile && (
                                                    <td>
                                                      <button
                                                        onClick={() =>
                                                          this.RemovehandleShow(
                                                            item._id,
                                                            buyProductInfo.farmId,
                                                          )
                                                        }
                                                        className="btn btn-secondary size_small"
                                                      >
                                                        Remove
                                                      </button>
                                                    </td>
                                                  )}
                                                </tr>
                                              );
                                            },
                                          )}
                                      </tbody>
                                    </table>
                                    {/* show label saying that pickup and delivery options are loading*/}
                                    {!getApiFarmDetail && (
                                      <p>
                                        Fetching pickup and delivery options.
                                      </p>
                                    )}
                                  </div>

                                  {/* pickup information */}
                                  {buyProductInfo.isDisabled && (
                                    <Alert variant="danger">
                                      Please remove any unavailable product(s)
                                      before checkout.
                                    </Alert>
                                  )}

                                  {/* show delivery & pickup options*/}
                                  {hasFarm === true &&
                                    (hasOpenPickupDistributions ||
                                      hasOpenDeliveryDistributions) && (
                                      <>
                                        <Tabs
                                          id={`pickup-delivery-options-${buyProductInfoIndex}`}
                                          activeKey={
                                            this.state.farmDistributionTabState[
                                              buyProductInfo.farmId
                                            ]
                                          }
                                          onSelect={(key) =>
                                            this.handleDistributionTabSelect({
                                              key,
                                              farmId: buyProductInfo.farmId,
                                            })
                                          }
                                        >
                                          {/* show pickup options*/}
                                          <Tab title="Pickup" eventKey="pickup">
                                            {!hasOpenPickupDistributions && (
                                              <h6>
                                                This farm currently has no
                                                pickup options.
                                              </h6>
                                            )}

                                            {hasOpenPickupDistributions && (
                                              <h6 className="distribution-instructions">
                                                Choose pick up location and time
                                              </h6>
                                            )}
                                            {openPickupDistributions.map(
                                              (distributionDetail) => {
                                                return distributionDetail.pickup
                                                  .reduce(
                                                    (accumulator, pickup) => {
                                                      {
                                                        /* one pickup day can have multiple times, lets flatten those out
                                                       so that each can be a specific row in the list */
                                                      }
                                                      return accumulator.concat(
                                                        pickup.time.map(
                                                          (time) => {
                                                            return {
                                                              ...pickup,
                                                              time: [time],
                                                            };
                                                          },
                                                        ),
                                                      );
                                                    },
                                                    [],
                                                  )
                                                  .map((pickup) => {
                                                    const formattedDayAndTime =
                                                      formatDayAndTime(pickup);

                                                    {
                                                      /* database might contain some farms that selected distribution whose address did not save
                                                due to a bug in the edit distribution details form of the farmer admin. this sets the addres
                                                as the pickup address if one does not exist */
                                                    }
                                                    let completePickupAddress = `${
                                                      distributionDetail
                                                        .distributionLocation
                                                        .address
                                                        ? distributionDetail
                                                            .distributionLocation
                                                            .address
                                                        : distributionDetail.category ===
                                                            DISTRIBUTION_CATEGORY.FARM &&
                                                          buyProductInfo.farmAddress
                                                        ? formatDeliveryInfo(
                                                            buyProductInfo.farmAddress,
                                                          )
                                                        : ""
                                                    }, ${formattedDayAndTime}`;

                                                    return (
                                                      <>
                                                        <Form.Check
                                                          type="radio"
                                                          checked={
                                                            completePickupAddress ===
                                                            buyProductInfo.address
                                                              ? "checked"
                                                              : ""
                                                          }
                                                          name={`farmpickup_${buyProductInfo.farmId}`}
                                                          value={
                                                            completePickupAddress
                                                          }
                                                          dayvalue={pickup.day}
                                                          onClick={this.handleOnPickupAddress.bind(
                                                            this,
                                                            {
                                                              farmId:
                                                                buyProductInfo.farmId,
                                                              marketId:
                                                                distributionDetail.marketId,
                                                            },
                                                          )}
                                                          label={
                                                            completePickupAddress
                                                          }
                                                        />
                                                      </>
                                                    );
                                                  });
                                              },
                                            )}
                                          </Tab>
                                          {/* show delivery options */}
                                          <Tab
                                            title="Delivery"
                                            eventKey="delivery"
                                          >
                                            {/* no delivery options open currently */}
                                            {!hasOpenDeliveryDistributions && (
                                              <h6>
                                                This farm currently has no
                                                delivery options.
                                              </h6>
                                            )}
                                            {/* show open delivery options heading showing available zip codes */}
                                            {hasOpenDeliveryDistributions && (
                                              <>
                                                <h6 className="distribution-instructions">
                                                  Choose delivery day and time
                                                </h6>
                                                <p className="available-zip-codes">
                                                  Available zip codes:{" "}
                                                  {`${getOpenOrderDeliveryZipCodesString(
                                                    buyProductInfo.distributionDetails,
                                                  )}`}
                                                </p>
                                              </>
                                            )}
                                            {/* show the avaible delivery options for the currently set delivery zipcode */}
                                            {hasOpenOrderDeliveryDistributionForDeliveryZipCode(
                                              {
                                                distributionInfo:
                                                  buyProductInfo.distributionDetails,
                                                deliverToZipCode:
                                                  cartListDetails.deliveryInfo
                                                    ?.postalCode,
                                              },
                                            ) &&
                                              getOpenOrderDeliveryDistributionForDeliveryZipCode(
                                                {
                                                  distributionInfo:
                                                    buyProductInfo.distributionDetails,
                                                  deliverToZipCode:
                                                    cartListDetails.deliveryInfo
                                                      ?.postalCode,
                                                },
                                              ).map((distributionDetail) => {
                                                return distributionDetail.delivery
                                                  .reduce(
                                                    (accumulator, delivery) => {
                                                      {
                                                        /* one delivery day can have multiple times, lets flatten those out
                                                         so that each can be a specific row in the list */
                                                      }
                                                      return accumulator.concat(
                                                        delivery.time.map(
                                                          (time) => {
                                                            return {
                                                              ...delivery,
                                                              time: [time],
                                                            };
                                                          },
                                                        ),
                                                      );
                                                    },
                                                    [],
                                                  )
                                                  .map(
                                                    (
                                                      delivery,
                                                      deliveryIndex,
                                                    ) => {
                                                      const formattedDayAndTime =
                                                        formatDayAndTime(
                                                          delivery,
                                                        );
                                                      const deliveryFlatFee =
                                                        getDeliveryFlatFee(
                                                          distributionDetail,
                                                        );
                                                      const label = `${formattedDayAndTime} - $${deliveryFlatFee}`;
                                                      return (
                                                        <Form.Check
                                                          type="radio"
                                                          name={`farmdelivery_${buyProductInfo.farmId}`}
                                                          checked={
                                                            formattedDayAndTime ===
                                                              buyProductInfo.day &&
                                                            buyProductInfo.courierType ===
                                                              "Delivery"
                                                              ? "checked"
                                                              : ""
                                                          }
                                                          disabled={
                                                            !this.isDeliveryAvailable(
                                                              buyProductInfo.distributionDetails,
                                                            )
                                                          }
                                                          value={
                                                            formattedDayAndTime
                                                          }
                                                          onClick={this.handleOnDeliveryDayTime.bind(
                                                            this,
                                                            {
                                                              buyProductInfo,
                                                              deliveryFlatFee,
                                                              address:
                                                                formatDeliveryInfo(
                                                                  cartListDetails.deliveryInfo,
                                                                ),
                                                              day: formattedDayAndTime,
                                                            },
                                                          )}
                                                          label={label}
                                                        />
                                                      );
                                                    },
                                                  );
                                              })}
                                            {/* using the delivery zip code, none of the orders are available */}
                                            {hasOpenOrderDeliveryDistribution(
                                              buyProductInfo.distributionDetails,
                                            ) &&
                                              !hasOpenOrderDeliveryDistributionForDeliveryZipCode(
                                                {
                                                  distributionInfo:
                                                    buyProductInfo.distributionDetails,
                                                  deliverToZipCode:
                                                    cartListDetails.deliveryInfo
                                                      ?.postalCode,
                                                },
                                              ) && (
                                                <Alert variant="danger">
                                                  Delivery is not available for
                                                  your address
                                                </Alert>
                                              )}
                                            {isNil(
                                              cartListDetails.deliveryInfo,
                                            ) && (
                                              <Button
                                                onClick={this.openAddressForm}
                                              >
                                                Confirm Delivery Address
                                              </Button>
                                            )}

                                            {cartListDetails.deliveryInfo &&
                                              hasOpenOrderDeliveryDistribution(
                                                buyProductInfo.distributionDetails,
                                              ) && (
                                                <div className="shopping_cart_delivery">
                                                  <h6 className="deliver-to">
                                                    Deliver to:{" "}
                                                  </h6>
                                                  <span className="deliver-to-address">
                                                    {formatDeliveryInfo(
                                                      cartListDetails.deliveryInfo,
                                                    )}
                                                  </span>
                                                  <Button
                                                    variant="link"
                                                    onClick={
                                                      this.openAddressForm
                                                    }
                                                  >
                                                    {formatDeliveryInfo(
                                                      cartListDetails.deliveryInfo,
                                                    )
                                                      ? "Edit"
                                                      : "Add Address"}
                                                  </Button>
                                                </div>
                                              )}
                                          </Tab>
                                        </Tabs>
                                      </>
                                    )}
                                </div>
                              )}
                          </div>
                        );
                      },
                    )}
                </div>

                {cartListDetails && cartListDetails._id ? (
                  <div className="total mt-5 total-summary outlined" id="total">
                    <div className="total-summary-top">
                      {cartListDetails.deliveryFees > 0 ? (
                        <>
                          <h6 className="medium">
                            Subtotal: &nbsp; $
                            {cartListDetails.totalPrice
                              ? cartListDetails.totalPrice
                              : ""}
                          </h6>
                          <h6 className="medium">
                            Delivery Charge: &nbsp; $
                            {cartListDetails.deliveryFees
                              ? cartListDetails.deliveryFees
                              : ""}
                          </h6>
                        </>
                      ) : null}

                      <h5 className="mt-3 mb0">
                        Total: &nbsp; $
                        {cartListDetails.totalPayPrice > 0
                          ? cartListDetails.totalPayPrice
                          : ""}
                      </h5>
                    </div>
                    <Button
                      disabled={this.isCheckoutDisabled()}
                      size="lg"
                      className="mx-2 btn-lg checkout-button"
                      variant="blue"
                      onClick={() => this.redirectToCheckout()}
                    >
                      Checkout
                    </Button>
                    <Button
                      size="lg"
                      className="mx-2 btn-lg"
                      variant="orange"
                      onClick={() => this.redirectToPreviousUrl()}
                    >
                      Buy More Items{" "}
                    </Button>
                  </div>
                ) : null}
              </>
            ) : (
              <>
                {getApiResponse && cartListDetails && !cartListDetails._id ? (
                  <div className="text-center">
                    <h6 className="text-orange">Your cart is empty</h6>
                    <Button
                      size="sm"
                      className="mx-2"
                      variant="info"
                      onClick={() => this.redirectToBuy()}
                    >
                      Buy Products{" "}
                    </Button>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </Container>

        <Modal
          show={this.state.removeitem}
          onHide={this.RemovehandleClose}
          className="cm_confirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove from cart</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to remove this item?</Modal.Body>
          <Modal.Footer>
            <div className="cmbtngrp">
              <Button
                variant="warning"
                className=""
                onClick={() => this.itemDeleteToCart()}
              >
                Yes
              </Button>
              <Button variant="secondary" onClick={this.RemovehandleClose}>
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.minQtyMsg}
          onHide={this.RemovehandleClose}
          className="cm_confirmation imageView"
        >
          <Modal.Header closeButton>
            <Modal.Title>Please select at least 1 unit</Modal.Title>
          </Modal.Header>
        </Modal>

        <Modal
          show={this.state.showAddressForm}
          onHide={this.closeAddressForm}
          className="cm_confirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delivery Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="useraddress">
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Control
                    type="text"
                    placeholder=" Enter address line 1"
                    maxLength={100}
                    name="addressLine1"
                    value={this.state.deliveryInfo?.addressLine1}
                    onChange={this.handleDeliveryAddressOnChange}
                  />
                  <span className="error_msg">
                    {this.state.deliveryInfoErrors["addressLine1"]}
                  </span>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Control
                    type="text"
                    placeholder="Enter address line 2"
                    maxLength={100}
                    name="addressLine2"
                    value={this.state.deliveryInfo?.addressLine2}
                    onChange={this.handleDeliveryAddressOnChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Control
                    type="text"
                    placeholder="Enter city"
                    maxLength={50}
                    name="city"
                    value={this.state.deliveryInfo?.city}
                    onChange={this.handleDeliveryAddressOnChange}
                  />
                  <span className="error_msg">
                    {this.state.deliveryInfoErrors["city"]}
                  </span>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Control
                    type="text"
                    placeholder="Enter state"
                    maxLength={100}
                    name="state"
                    value={this.state.deliveryInfo?.state}
                    onChange={this.handleDeliveryAddressOnChange}
                  />
                  <span className="error_msg">
                    {this.state.deliveryInfoErrors["state"]}
                  </span>
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder=" Enter postal code"
                  maxLength={10}
                  name="postalCode"
                  value={this.state.deliveryInfo?.postalCode}
                  onChange={this.handleDeliveryAddressOnChange}
                />
                <span className="error_msg">
                  {this.state.deliveryInfoErrors["postalCode"]}
                </span>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="cmbtngrp">
              <Button variant="warning" onClick={this.handleOnSaveAddress}>
                Save Address
              </Button>
              <Button variant="secondary" onClick={this.closeAddressForm}>
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.minQtyMsg}
          onHide={this.RemovehandleClose}
          className="cm_confirmation imageView"
        >
          <Modal.Header closeButton>
            <Modal.Title>Please select at least 1 unit</Modal.Title>
          </Modal.Header>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.login.isLoggedIn,
    profiledetails: state.profile,
    cartListDetails: state.cartList.cartListDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({ getCart }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
