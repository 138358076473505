import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import "./scss/index.scss";

import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import { Routes } from "./Routes";
import Store from "./redux/store/Store";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <Provider store={Store}>
    <Routes />
    <ToastContainer />
  </Provider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
