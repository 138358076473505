import React, { Component } from "react";
import { Alert, Container } from "react-bootstrap";
import classnames from "classnames";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userProfile } from "../../../redux/action/Get";
import { setOrderNowZipcode } from "../../../redux/action/order-now";
import { ORDER_NOW_ZIP_CODE_EMPTY } from "../../../redux/reducer/order-now";
import Loader from "../../../components/common/Loader";
import { FarmProductCards } from "../../../components/FarmProductCards";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Geocode from "react-geocode";
import { USER_TYPE } from "../../../utils/user";
import { currentlyOrderableInventory } from "../../../utils/inventory";
import Url from "../../../services/BaseUrl";
import { _Api } from "../../../services/Api";
const queryString = require("query-string");

const firstZipCodeInProfile = (profileDetails) => {
  if (!profileDetails) return;
  return profileDetails?.profileInfo?.address?.[0]?.postalCode;
};

class OrderNow extends Component {
  constructor() {
    super();
    this.state = {
      lat: null,
      long: null,
      inputZipCode: undefined,
      inputZipCodeInvalid: false,
      filterRadius: 25,
      filterShowMenu: false,
      userType: USER_TYPE.CUSTOMER,
      alertPickupShow: true,
      alertDeliveryShow: true,
      alertShippingShow: true,
      products: [],
    };
  }

  fetchAndUpdateInventory = async (inventory, batchSize) => {
    let updatedInventory = [];

    for (let i = 0; i < inventory.length; i += batchSize) {
      const batch = inventory.slice(i, i + batchSize);
      const batchResults = await Promise.all(
        batch.map(async (item) => {
          try {
            const data = await _Api(
              Url.USER_GET_INVEBTERY_VIEW_DETAILS_BY_ID.method,
              Url.USER_GET_INVEBTERY_VIEW_DETAILS_BY_ID.url,
              "",
              `?inventoryId=${item._id}&userType=${this.state.userType}`,
            );
            return {
              ...item,
              farmName: data.responseData.result.farm.farmName,
              farmId: data.responseData.result.farm._id,
            };
          } catch (error) {
            console.error(
              "Failed to fetch inventory details for item",
              item._id,
              error,
            );
            return item; // Return the item as is or handle the error as needed
          }
        }),
      );

      updatedInventory = [...updatedInventory, ...batchResults];
    }

    return updatedInventory;
  };

  fetchProducts = async () => {
    const queryParams = {
      zipCode: this.props.orderNowZipCode,
      userType: this.state.userType,
      isShippable: true,
    };

    const resp = await _Api(
      Url.USER_GET_INVENTORY_BY_DISTRIBUTION.method,
      Url.USER_GET_INVENTORY_BY_DISTRIBUTION.url,
      "",
      `?${queryString.stringify(queryParams)}`,
    );

    const { inventory, distributions } = resp.responseData;

    const batchSize = 2; // Adjust based on your needs and server capabilities
    const updatedInventory = await this.fetchAndUpdateInventory(
      inventory,
      batchSize,
    );

    this.setState({
      products: currentlyOrderableInventory({
        inventory: updatedInventory,
        distributions,
      }),
    });
  };

  componentDidMount() {
    if (localStorage.getItem("userType")) {
      const userType = +localStorage.getItem("userType");
      this.setState({
        userType,
      });
    }

    this.props.action.userProfile();
    Geocode.setApiKey("AIzaSyBHCz-HA_-BCnJrdUsvbQPY1cqMdXLflpw");

    // if (localStorage.getItem("isLoggedIn") !== "true") {
    //   this.getUserLocation();
    // }

    this.fetchProducts();
  }

  componentDidUpdate(prevProps) {
    if (this.props.orderNowZipCode !== prevProps.orderNowZipCode) {
      this.fetchProducts();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.orderNowZipCode === ORDER_NOW_ZIP_CODE_EMPTY.INITIAL) {
      const nextZipCode = firstZipCodeInProfile(nextProps.profiledetails);
      if (nextZipCode) {
        this.props.action.setOrderNowZipcode({ zipCode: nextZipCode });
      }
    }
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          Geocode.fromLatLng(
            position.coords.latitude,
            position.coords.longitude,
          ).then(
            (response) => {
              let results = response.results;
              if (results[0]) {
                for (let j = 0; j < results[0].address_components.length; j++) {
                  if (
                    results[0].address_components[j].types[0] === "postal_code"
                  ) {
                    const zipCode = results[0].address_components[j].short_name;
                    this.props.action.setOrderNowZipcode({ zipCode });
                  } else {
                  }
                }
              } else {
              }
            },
            (error) => {
              console.error(error);
            },
          );
        },
        (error) => {
          // set default if user deny access location
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 1000,
        },
      );
    }
  }

  handleChangeInputZipCode(event) {
    this.setState({
      inputZipCode: event.target.value,
    });
  }

  handleChangeFilterButton() {
    const { inputZipCode } = this.state;
    if (!(inputZipCode && inputZipCode.length === 5)) {
      this.setState({
        inputZipCodeInvalid: true,
      });
      return;
    }

    this.props.action.setOrderNowZipcode({ zipCode: inputZipCode });
    this.setState({
      filterShowMenu: false,
      inputZipCodeInvalid: false,
    });
  }

  handleChangeFilterRadius(event) {
    this.setState({
      filterRadius: event.target.value,
    });
  }

  handleChangeFilterSummary() {
    if (this.state.filterShowMenu === false) {
      this.setState({
        filterShowMenu: true,
      });
    } else {
      this.setState({
        filterShowMenu: false,
      });
    }
  }

  handleClearFilter() {
    this.props.action.setOrderNowZipcode({
      zipCode: ORDER_NOW_ZIP_CODE_EMPTY.CLEARED,
    });
  }

  closeDistributionAlert(type) {
    this.setState({
      alertShippingShow: false,
    });
  }

  render() {
    const { userType } = this.state;

    const { orderNowZipCode } = this.props;
    const isZipCodeSet =
      typeof orderNowZipCode === "string" && orderNowZipCode.length === 5;

    let orderNowDistribution = null;
    let filterSummaryHTML = "";
    let zipInputPlaceholder = `Enter your zip code to see what's nearby`;

    if (isZipCodeSet) {
      filterSummaryHTML = `Products shipping to <span class="filter-summary-data">${orderNowZipCode}</span>`;
    } else {
      filterSummaryHTML = "All Shippable Products";
    }
    zipInputPlaceholder = `Enter your zip code to see products shipping to you`;
    const cardsProps = {
      userType,
      zipCode: orderNowZipCode,
      sliderProps: null,
      updatedInventory: this.state.products,
    };
    orderNowDistribution = (
      <div
        id="order-now-shipping"
        className={classnames({
          "order-now-distribution": true,
          active: true,
          "mask-content": isZipCodeSet === false,
        })}
      >
        {this.state.alertShippingShow && (
          <Alert
            className="order-now-distribution-alert hp-alert"
            variant="info"
            dismissible
            onClose={() => this.closeDistributionAlert("shipping")}
          >
            Shippable Products are shipped directly from the farm to your door,
            using sustainable shipping practices.
          </Alert>
        )}
        <div className="order-now-shipping-items order-now-slider-container">
          <FarmProductCards {...cardsProps} />
        </div>
      </div>
    );

    return (
      <section className="block padded" id="order-now-home">
        <Container fluid>
          {this.state.isLoading && <Loader />}

          <div className="row">
            <div className="col">
              <h2 className="section-heading underscore">To Your Door</h2>
            </div>
          </div>

          <div
            id="order-now"
            className={classnames({
              "zip-code-enabled": isZipCodeSet,
              "shipping-active": true,
            })}
          >
            <div className="row order-now-nav" id="order-now-nav">
              <div className="col-lg-12 order-now-nav-location-col">
                <nav className="order-now-nav-location">
                  <div className="order-now-nav-location-top">
                    <div
                      className={classnames({
                        "order-now-nav-filter-summary-container": true,
                      })}
                    >
                      <h3
                        className={classnames({
                          "filter-summary": true,
                          interactive: isZipCodeSet,
                          issue: isZipCodeSet === false,
                          "show-caret": isZipCodeSet,
                          toggled: this.state.filterShowMenu,
                        })}
                        dangerouslySetInnerHTML={{
                          __html: filterSummaryHTML,
                        }}
                        onClick={this.handleChangeFilterSummary.bind(this)}
                      ></h3>
                      <button
                        className={classnames({
                          "filter-clear": true,
                          hidden: isZipCodeSet === false,
                          toggled: this.state.filterShowMenu,
                        })}
                        onClick={this.handleClearFilter.bind(this)}
                      >
                        ﬂ
                      </button>
                    </div>
                  </div>
                  <div
                    className={classnames({
                      "order-now-nav-location-bottom": true,
                      active:
                        this.state.filterShowMenu || isZipCodeSet === false,
                    })}
                  >
                    <input
                      id="order-now-zip-input"
                      placeholder={zipInputPlaceholder}
                      name="orderNowZipCode"
                      className="form-control"
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onChange={this.handleChangeInputZipCode.bind(this)}
                      value={this.state.inputZipCode}
                    ></input>
                    <button
                      className="order-now-zip-go"
                      onClick={this.handleChangeFilterButton.bind(this)}
                    >
                      Go
                    </button>
                  </div>
                  {this.state.inputZipCodeInvalid && (
                    <div className="order-now-nav-location-errors">
                      <Alert
                        variant="danger"
                        className="order-now-error hp-alert"
                      >
                        Zip code must be 5 numbers
                      </Alert>
                    </div>
                  )}
                </nav>
              </div>
            </div>
            <div className="order-now-main">{orderNowDistribution}</div>
          </div>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    profiledetails: state.profile,
    orderNowZipCode: state.orderNow.zipCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({ userProfile, setOrderNowZipcode }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderNow);
