/**
 * @about auth api file that contains login function
 * that get data from servers
 */

import axios from "axios";
import { history } from "../Routes";
import siteSetting from "../configs/env/Index";
import { setLocalStorargeUserLogout } from "../utils/user";
// api end point

const url = siteSetting.api.url;

const authKey =
  "Basic aGVhbHRoeVBsYW5ldF9hZG1pbl92WnBHaDQ6YWRtaW5AaGVhbHRoeVBsYW5ldF9GVGtWcFE=";

//common for all api
export const _Api = (methodType, endPoint, request = "", query = "") => {
  try {
    return new Promise((resolve, reject) => {
      let Cmp_Url = `${url}${endPoint}`;
      axios({
        method: methodType,
        url: `${Cmp_Url}${query}`,
        data: request,
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      })
        .then((resp) => {
          if (resp.data.statusCode) {
            resolve(resp.data);
          } else {
            if (
              (resp.data.error && resp.data.error.errorCode == 2) ||
              (resp.data.error && resp.data.error.errorCode == 17)
            ) {
              setLocalStorargeUserLogout();
              // history.push("/")
              window.location.href = "/";
              return false;
            }
            reject(resp.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  } catch (err) {}
};
