import { Button, Form, Modal } from "react-bootstrap";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isValidEmail } from "../../utils/Validation";
import { login } from "../../redux/action/Auth";
import { userProfile } from "../../redux/action/Get";

const debug = require("debug")("login");

//const custmberType = { 1: 'Farmer', 2: 'Consumer', 3: 'Whole Saler' };

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      userType: "",
      lat: "",
      long: "",
      confirmPassword: "",
      errors: [],
      responseError: "",
      value: "",
      showModal: false,
      showModalLogin: false,
      signInAs: false,
      address: "",
      area: "",
      city: "",
      state: "",
    };
  }

  //OnChange
  handleChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    });
  };

  handleShowModalSignUp = () => {
    this.setState({ showModal: true });
  };

  handleCloseModalSignUp = () => {
    this.setState({ showModal: false });
  };

  //Validation login
  handleValidateLogin = () => {
    let validate = false;
    let errors = {};
    let { email, password } = this.state;
    if (_.isEmpty(email)) {
      validate = true;
      errors["email"] = "email is required.";
    } else if (!isValidEmail(email)) {
      validate = true;
      errors["email"] = "Enter a valid email.";
    }
    if (_.isEmpty(password)) {
      validate = true;
      errors["password"] = "Password is required.";
    } else if (password.length <= 4) {
      validate = true;
      errors["password"] = "Password must be greater than 4 digits.";
    }
    this.setState({ errors });
    return validate;
  };

  //sign in
  handleSignInSubmit = (e) => {
    let { email, password } = this.state;
    this.handleValidateLogin();
    if (!this.handleValidateLogin()) {
      let req = {
        email: email,
        password: password,
        userType: this.props.userType.toString(),
        deviceID: "pending",
        deviceToken: "pending",
        deviceTypeID: "pending",
      };
      this.props.action.login(req);
      this.props.handleCloseModal();
    }
  };

  render() {
    let { email, password, errors } = this.state;

    return (
      <div className="confirmation_popup">
        <Modal
          show={this.props.showModalLogin}
          onHide={() => this.props.handleCloseModal()}
          className="cm_confirmation login"
        >
          <Modal.Header closeButton> </Modal.Header>
          <Modal.Body>
            <div className="two_col login">
              <div>
                <div className="login_bg">
                  <h2>Welcome Back</h2>
                </div>
              </div>
              <div>
                <div className="login_content">
                  <h5>Sign In</h5>
                  <Form.Control
                    type="email"
                    autoComplete="off"
                    onChange={this.handleChanged}
                    name="email"
                    value={email}
                    placeholder="Email Address"
                  />
                  <span className="error_msg">{errors["email"]}</span>
                  <Form.Control
                    type="Password"
                    autoComplete="off"
                    onChange={this.handleChanged}
                    name="password"
                    value={password}
                    placeholder="Password"
                  />
                  <span className="error_msg">{errors["password"]}</span>
                  <p>
                    <Link
                      to="/forgotpassword"
                      onClick={() => this.props.handleCloseModal()}
                    >
                      <small>Forgot Password?</small>
                    </Link>
                  </p>
                  <Button variant="dark" onClick={this.handleSignInSubmit}>
                    Sign In
                  </Button>
                  <p className="create_ac">
                    <small>
                      Don't have an account?
                      <span onClick={() => this.props.handleShowModalSignUp()}>
                        {" "}
                        Sign Up
                      </span>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.login.isLoggedIn,
    profiledetails: state.profile.userProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({ login, userProfile }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
