/**
 * @about this file contains action types
 * which type of action fire or which type
 *
 */

// used for global loading and loader
export const IS_LOADING_START = "IS_LOADING_START";
export const IS_LOADING_STOP = "IS_LOADING_STOP";

// local loading
export const IS_LOADER_START = "IS_LOADER_START";
export const IS_LOADER_STOP = "IS_LOADER_STOP";

//
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED";

export const GET_USER_CART_LIST_SUCCESS = "GET_USER_CART_LIST_SUCCESS";
export const GET_USER_CART_LIST_FAILED = "GET_USER_CART_LIST_FAILED";

// for login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// logout
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const IS_LOGGED_OUT = "IS_LOGGED_OUT";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_FAILURE = "LOG_OUT_FAILURE";
