import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import React, { Component } from "react";

import Banner from "../banner";
import Joinourfoodmovement from "../home/Joinourfoodmovement";
import ReactHtmlParser from "react-html-parser";
import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";
import { notify } from "../../components/common/Tooster.js";

class RecipesList extends Component {
  constructor(props) {
    super(props);
    this.state = { recipeDetails: "" };
  }

  componentDidMount() {
    this.getRecipesViewDetails();
  }

  getRecipesViewDetails = () => {
    let req = {
      recipesId: this.props.match.params.id,
    };
    _Api(
      Url.USER_GET_RECIPES_VIEW_DETAILS.method,
      Url.USER_GET_RECIPES_VIEW_DETAILS.url,
      req,
    )
      .then((resp) => {
        this.setState({ recipeDetails: resp.responseData.result });
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  render() {
    let { recipeDetails } = this.state;
    return (
      <div className="body_container_wrapper no_pad recipies">
        <Banner
          heading="Recipes"
          bannerbg={`url(${require("../../assets/img/Recipes.png")})`}
        />
        <Container>
          <div className="heading">
            <Breadcrumb>
              <Breadcrumb.Item href="#">
                <h6>Recipe</h6>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <h6>{recipeDetails?.recipesName}</h6>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="recipeslist_slide_wrapper">
            <Row>
              <Col md="4">
                <div className="recipes-img">
                  <img alt="recipes" src={recipeDetails?.image} />
                  <ul></ul>
                </div>
              </Col>
              <Col md="8">
                <div className="recipes-content">
                  <h5>{recipeDetails?.recipesName}</h5>

                  <h6>
                    {recipeDetails
                      ? ReactHtmlParser(recipeDetails.description)
                      : null}
                  </h6>
                </div>
              </Col>
            </Row>
            {/* <div className="next-prev">
                            <h4><i class="fas fa-arrow-left"></i> Prev Recipe</h4>
                            <h4> Next Recipe <i class="fas fa-arrow-right"></i></h4>
                        </div> */}
          </div>
        </Container>
        <Joinourfoodmovement />
      </div>
    );
  }
}

export default RecipesList;
