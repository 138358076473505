import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import React, { Component } from "react";

import Slider from "react-slick";
import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";

class OurClient extends Component {
  constructor(props) {
    super(props);
    this.state = { farmerList: "" };
  }
  componentDidMount() {
    this.getAllFarmerList();
  }

  getAllFarmerList = () => {
    _Api(Url.USER_GET_ALL_FARMER_LIST.method, Url.USER_GET_ALL_FARMER_LIST.url)
      .then((resp) => {
        this.setState({ farmerList: resp.responseData.result.user });
      })
      .catch((err) => {
        this.setState({ isLoader: false });
      });
  };

  render() {
    let count = 3;
    if (this.state.farmerList.length < 4) {
      count = 1;
    }
    const settings = {
      dots: false,
      centerMode: true,
      centerPadding: "60px",
      infinite: true,
      speed: 500,
      slidesToShow: count,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,

      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1180,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 860,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerMode: false,
            centerPadding: "50px",
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <Slider {...settings} className="cm_ourclient">
        {this.state.farmerList &&
          this.state.farmerList.map((item, index) => {
            return (
              <div className="slidebox">
                <div className="slidebox-inner">
                  <div className="caption">
                    <div className="ourclient_img">
                      <img
                        src={
                          item.profileImage === ""
                            ? require("../../assets/img/placehoder_2.png")
                            : item.profileImage
                        }
                        alt={item.firstName}
                      />
                    </div>
                    <h4>{`${item.firstName} ${item.lastName}`}</h4>
                    <p>
                      <small>{item.farmInfo?.farmName}</small>
                    </p>
                    <p>{item.farmInfo.infoAboutFarmer}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    );
  }
}

export default OurClient;
