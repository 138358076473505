import { Col, Container } from "react-bootstrap";

import React from "react";

const Joinourfoodmovement = () => {
  return (
    <div className="joinus_section">
      <Container>
        <div className="heading">
          <h3>
            <span>JOIN OUR SUSTAINABLE FOOD MOVEMENT</span>
          </h3>
          <h5>@healthyplaneat #healthyplaneat</h5>
        </div>
        <div className="joinus_food d-flex">
          <Col>
            <img
              src={require("../../assets/img/joinusFood1.jpg")}
              alt="Joinus Food"
            />
          </Col>
          <Col>
            <img
              src={require("../../assets/img/joinusFood2.jpg")}
              alt="Joinus Food"
            />
          </Col>
          <Col>
            <img
              src={require("../../assets/img/joinusFood3.jpg")}
              alt="Joinus Food"
            />
          </Col>
          <Col>
            <img
              src={require("../../assets/img/joinusFood4.jpg")}
              alt="Joinus Food"
            />
          </Col>
          <Col>
            <img
              src={require("../../assets/img/joinusFood5.jpg")}
              alt="Joinus Food"
            />
          </Col>
        </div>
        <a href="https://www.instagram.com/healthyplaneat/" target="_blank">
          <button className="btn btn-primary">Follow Us</button>
        </a>
      </Container>
    </div>
  );
};

export default Joinourfoodmovement;
