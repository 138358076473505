import React, { Component } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import About from "./views/about/about";
import BecomeaFarmer from "./views/becomeafarmer/BecomeaFarmer";
import CancleOrder from "./views/cancleorder/CancleOrder.jsx";
import ChangePassword from "./components/auth/ChangePassword";
import Farms from "./views/farms/Farms";
import Footer from "./components/global/Footer";
import ForgotPassword from "./components/auth/ForgotPassword";
import Header from "./components/global/Header";
import Home from "./views/home/Home";
import MarketPopUps from "./views/marketPopups/MarketPopups";
import MarketPopupList from "./views/marketPopups/MarketPuopupList";
import OrderNumber from "./views/order/orderNumber.jsx";
import OrderSummary from "./views/ordersummary/ordersummary";
import PageNotFound from "./views/notfound/NotFound";
import PrivacyPolicy from "./views/privacyPolicy/PrivacyPolicy";
import Productetails from "./views/productdetails/productdetails";
import Recipes from "./views/recipes/recipes";
import RecipesList from "./views/recipes/RecipesList";
import ResetPassword from "./components/auth/ResetPassword";
import Schools from "./views/schools/Schools";
import ScrollToTop from "./views/ScrollToTop";
import ShoppingCart from "./views/shoppingcart/shoppingcart";
// import EditProfile from './views/userprofile/EditProfile';
import TermsOfUse from "./views/termsOfUse/TermsOfUse";
import UserProfile from "./views/userprofile/userprofile";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import farmList from "./views/farms/FarmList";
import OrderNowPage from "./views/order-now/OrderNowPage";
import ShippedProducts from "./views/shippedProducts/ShippedProducts";
import PickUpHost from "./views/pickUpHost/pickUpHost";

const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn === true ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export const history = createBrowserHistory();

history.listen(({ pathname }) => {
  window.scrollTo(0, 0);
});

class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/farms" component={Farms} />
          <Route exact path="/farms/farmlist/:id" component={farmList} />
          <Route
            exact
            path="/farms/farmlist/productdetails/:id"
            component={Productetails}
          />
          <Route exact path="/cancleorder" component={CancleOrder} />
          <Route exact path="/about" component={About} />
          <Route exact path="/schools" component={Schools} />
          <Route exact path="/recipes" component={Recipes} />
          <Route
            exact
            path="/recipes/recipeslist/:id"
            component={RecipesList}
          />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/resetpassword/:id" component={ResetPassword} />
          <Route exact path="/become_a_farmer" component={BecomeaFarmer} />
          <Route exact path="/marketpopups" component={MarketPopUps} />
          <Route exact path="/toyourdoor" component={ShippedProducts} />
          <Route exact path="/order-now" component={OrderNowPage} />
          <Route
            exact
            path="/marketpopups/marketpopuplist/:id"
            component={MarketPopupList}
          />
          <Route exact path="/termsofuse" component={TermsOfUse} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") === "true"}
            path="/shoppingcart"
            component={ShoppingCart}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") === "true"}
            path="/ordersummary"
            component={OrderSummary}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") === "true"}
            path="/ordernumber"
            component={OrderNumber}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") === "true"}
            path="/userprofile"
            component={UserProfile}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") === "true"}
            path="/changepassword"
            component={ChangePassword}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") === "true"}
            path="/pickups"
            component={PickUpHost}
          />
          <Route component={PageNotFound} />
        </Switch>
        <Footer />
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

const connectedApp = connect(mapStateToProps)(Routes);
export { connectedApp as Routes };
