import { cartList } from "./getCart";
import { combineReducers } from "redux";
import { loading } from "./Common";
import { login } from "./Auth";
import { profile } from "./userProfile";
import { orderNow } from "./order-now";
import { header } from "./header";

// reducers

export default combineReducers({
  loading,
  login,
  profile,
  cartList,
  orderNow,
  header,
});
