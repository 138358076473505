const UserConnectGuide1 = [
  {
    img: require("../assets/img/connectguide1.png"),
    heading: "Discover Farms Near You",
    content: "",
  },
  {
    img: require("../assets/img/connectguide2.png"),
    heading: `See what's in Season`,
    content: "",
  },
];
const UserConnectGuide2 = [
  {
    img: require("../assets/img/connectguide3.png"),
    heading: "Place your Order",
    content: "",
  },
  {
    img: require("../assets/img/connectguide4.png"),
    heading: "Pick up at the farm, farmer’s market, pop-up, or get delivery",
    content: "",
  },
];
const FarmNearYou = [
  {
    img: require("../assets/img/farm.png"),
    heading: "Farm",
  },
  {
    img: require("../assets/img/stand.png"),
    heading: "Farmers Market",
  },
  {
    img: require("../assets/img/location.png"),
    heading: "Pop up Location",
  },
  {
    img: require("../assets/img/university.png"),
    heading: "University",
  },
  {
    img: require("../assets/img/School.png"),
    heading: "School",
  },
  {
    img: require("../assets/img/breakfast.png"),
    heading: "Restaurant",
  },
  {
    img: require("../assets/img/star.png"),
    heading: "Other",
  },
];

const OrganicProduct = [
  {
    img: require("../assets/img/high-quality.png"),
    heading: "Enjoy fresh, high-quality food",
  },
  {
    img: require("../assets/img/farmar.png"),
    heading: "Support sustainable growing practices",
  },
  {
    img: require("../assets/img/holdinghand.png"),
    heading: "Build a strong food system in your community",
  },
];

export { UserConnectGuide1, UserConnectGuide2, FarmNearYou, OrganicProduct };

// farmers

const FarmerContact = [
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Star Light Gardens",
    content:
      "Star Light Gardens is a USDA Certified Organic vegetable farm located in Durham, Connecticut.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Star Light Gardens",
    content:
      "Star Light Gardens is a USDA Certified Organic vegetable farm located in Durham, Connecticut.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Star Light Gardens",
    content:
      "Star Light Gardens is a USDA Certified Organic vegetable farm located in Durham, Connecticut.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Star Light Gardens",
    content:
      "Star Light Gardens is a USDA Certified Organic vegetable farm located in Durham, Connecticut.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Star Light Gardens",
    content:
      "Star Light Gardens is a USDA Certified Organic vegetable farm located in Durham, Connecticut.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Star Light Gardens",
    content:
      "Star Light Gardens is a USDA Certified Organic vegetable farm located in Durham, Connecticut.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Star Light Gardens",
    content:
      "Star Light Gardens is a USDA Certified Organic vegetable farm located in Durham, Connecticut.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Star Light Gardens",
    content:
      "Star Light Gardens is a USDA Certified Organic vegetable farm located in Durham, Connecticut.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Star Light Gardens",
    content:
      "Star Light Gardens is a USDA Certified Organic vegetable farm located in Durham, Connecticut.",
  },
];
export default FarmerContact;

const ProductList = [
  {
    img: require("../assets/img/Cucumber.png"),
    heading: "Star Light Gardens - Salad Greens - 1 Bag (6 oz)",
    price: "6.00",
  },
  {
    img: require("../assets/img/eggplant.png"),
    heading: "Star Light Gardens - Salad Greens - 1 Bag (6 oz)",
    price: "6.00",
  },
  {
    img: require("../assets/img/Kohlrabi, purple.png"),
    heading: "Star Light Gardens - Salad Greens - 1 Bag (6 oz)",
    price: "6.00",
  },
  {
    img: require("../assets/img/Hot peppers - Thai chilies.png"),
    heading: "Star Light Gardens - Salad Greens - 1 Bag (6 oz)",
    price: "6.00",
  },
  {
    img: require("../assets/img/Shallots.png"),
    heading: "Star Light Gardens - Salad Greens - 1 Bag (6 oz)",
    price: "6.00",
  },
];

const TwoColImgContent = [
  {
    img: require("../assets/img/Rosemary.png"),
    imgalign: `left`,
    heading: "Rosemary",
    designation: "CEO",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
  },
  {
    img: require("../assets/img/Richard.png"),
    imgalign: `left`,
    heading: "Richard",
    designation: "CTO  ",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
  },
  {
    img: require("../assets/img/Rebecca.png"),
    imgalign: `left`,
    heading: "Rebecca",
    designation: "Officer",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
  },
];

const RecipesList = [
  {
    img: require("../assets/img/Rebecca.png"),
    heading: "Rebecca",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    buttonurl: "#",
  },
];

const MarketPopUp = [
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Sea food Market",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Sea food Market",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Sea food Market",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Sea food Market",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Sea food Market",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Sea food Market",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Sea food Market",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Sea food Market",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.",
  },
  {
    img: require("../assets/img/StarLightGardensImg.png"),
    heading: "Sea food Market",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.",
  },
];

export { ProductList, TwoColImgContent, RecipesList, MarketPopUp };
