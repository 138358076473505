import React from "react";

const debug = require("debug")("FarmCard");

export const FarmCard = (props) => {
  const { farm } = props;

  const link = `/farms/farmlist/${farm._id}`;
  const cardBackgroundImage = farm.media?.[0].thumbUrl;
  const cardSubheading = `${farm.farmAddress[0].city}, ${farm.farmAddress[0].state}`;

  return (
    <div className="hp-card-col slide" key={`farm-card-${farm._id}`}>
      <div className="hp-card hp-card-farm">
        <div
          className="hp-card-background"
          style={{
            backgroundSize: `cover`,
            backgroundColor: `linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15))`,
            backgroundImage: `url(${cardBackgroundImage})`,
          }}
        ></div>
        <a href={link}>
          <div className="hp-card-header">
            <img className="hp-card-icon" src={farm.farmLogo} alt="" />
            <h2 className="hp-card-heading">{farm.farmName}</h2>
            <h3 className="hp-card-subheading">{cardSubheading}</h3>
          </div>
          <div className="hp-card-body">
            <h4 className="hp-card-description">{farm.shortInfo}</h4>
            <div className="hp-card-link floating">
              <button className="">
                Shop {farm.numberAvailableProducts} Products
              </button>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};
