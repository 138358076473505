// convert 0,1,2,3,4,5,6 into days
export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const showAddress = (address) => {
  let firmaddess = "";
  if (address.addressLine1) {
    firmaddess = firmaddess + address.addressLine1;
    if (
      address.addressLine2 ||
      address.city ||
      address.state ||
      address.postalCode
    ) {
      firmaddess = firmaddess + " , ";
    }
  }
  if (address.addressLine2) {
    firmaddess = firmaddess + address.addressLine2;
    if (address.city || address.state || address.postalCode) {
      firmaddess = firmaddess + " , ";
    }
  }
  if (address.city) {
    firmaddess = firmaddess + address.city;
    if (address.state || address.postalCode) {
      firmaddess = firmaddess + " , ";
    }
  }
  if (address.state) {
    firmaddess = firmaddess + address.state;
    if (address.postalCode) {
      firmaddess = firmaddess + " , ";
    }
  }
  if (address.postalCode) {
    firmaddess = firmaddess + address.postalCode;
  }
  return firmaddess;
};

export const Units = (type, othername) => {
  switch (type) {
    // case 1:
    //     return 'wreath';
    //     break;
    // case 2:
    //     return 'bouquet';
    //     break;
    case 3:
      return "bunch";
      break;
    // case 4:
    //     return 'lb';
    //     break;
    case 5:
      return "pint";
      break;
    case 6:
      return "head";
      break;

    case 7:
      return "bag";
      break;
    case 8:
      return "half pint";
      break;
    // case 9:
    //     return '1/2 lb bag'
    //     break;
    case 10:
      return "Pound";
      break;

    case 11:
      return "Half Pound Bag";
      break;

    case 12:
      return "Half Pound";
      break;
    case 13:
      if (othername) {
        return othername;
      } else {
        return "others";
      }
      break;
    default:
      if (othername) {
        return othername;
      } else {
        return "others";
      }
      break;
  }
};

export const GetCloseAndOpenStatus = ({ distributionInfo, currentDate }) => {
  if (!currentDate) currentDate = new Date();
  let orderStatus = false;
  let currentDay = currentDate.getDay();
  let data1 = distributionInfo.placeOrderOpen;
  let data2 = distributionInfo.placeOrderClose;
  let day1 = data1.day;
  let day2 = data2.day;

  var selectday = [];
  if (day1 < day2) {
    for (let i = day1; i <= day2; i++) {
      selectday.push(i);
    }
  }
  if (day1 > day2) {
    for (let i = day1; i <= 6; i++) {
      selectday.push(i);
    }
    for (let i = 0; i <= day2; i++) {
      selectday.push(i);
    }
  }
  if (day1 == day2) {
    selectday.push(day1);
  }

  if (selectday.includes(currentDay)) {
    let m0 = "";
    let currentHours = currentDate.getHours();
    let currentMinutes = currentDate.getMinutes();
    m0 = currentHours >= 12 ? "PM" : "AM";

    if (currentDay == day1 && currentDay == day2) {
      let start_hours = +data1.time.split(":")[0];
      let start_min = +data1.time.split(":")[1];
      let m1 = data1.meridiem;
      start_hours =
        start_hours != 12 ? (m1 == "AM" ? start_hours : start_hours + 12) : 12;

      let end_hours = +data2.time.split(":")[0];
      let end_min = +data2.time.split(":")[1];
      let m2 = data2.meridiem;
      end_hours =
        end_hours != 12 ? (m2 == "AM" ? end_hours : end_hours + 12) : 12;

      if (start_hours < currentHours && end_hours > currentHours) {
        orderStatus = true;
      } else if (start_hours == currentHours && end_hours == currentHours) {
        if (currentMinutes >= start_min && currentMinutes < end_min) {
          orderStatus = true;
        }
      } else if (start_hours == currentHours && currentMinutes >= start_min) {
        orderStatus = true;
      } else if (end_hours == currentHours && currentMinutes < end_min) {
        orderStatus = true;
      }
    } else if (currentDay > day1 && currentDay < day2) {
      orderStatus = true;
    } else if (currentDay < day1 && currentDay < day2) {
      orderStatus = true;
    } else if (currentDay > day1 && currentDay > day2) {
      orderStatus = true;
    } else if (currentDay == day1) {
      let hours = +data1.time.split(":")[0];
      let min = +data1.time.split(":")[1];
      let m1 = data1.meridiem;
      hours = hours != 12 ? (m1 == "AM" ? hours : hours + 12) : 12;

      if (hours < currentHours) {
        orderStatus = true;
      }
      if (hours == currentHours && currentMinutes >= min) {
        orderStatus = true;
      }
    } else if (currentDay == day2) {
      let hours = +data2.time.split(":")[0];
      let min = +data2.time.split(":")[1];
      let m1 = data2.meridiem;
      hours = hours != 12 ? (m1 == "AM" ? hours : hours + 12) : 12;

      if (hours > currentHours) {
        orderStatus = true;
      }
      if (hours == currentHours && currentMinutes <= min) {
        orderStatus = true;
      }
    }
  }
  return orderStatus;
};
