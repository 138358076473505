/**
 * @About
 *
 *
 *
 */

import {
  IS_LOADING_START,
  IS_LOADING_STOP,
  LOGIN_SUCCESS,
  LOG_OUT_SUCCESS,
} from "./Type";

import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";
import { notify } from "../../components/common/Tooster";
import { setLocalStorageUserLogin } from "../../utils/user";

const debug = require("debug")("auth-action");

// action for login
export const login = (req) => (dispatch) => {
  try {
    debug(req);
    dispatch({ type: IS_LOADING_START });
    _Api(Url.USER_LOGIN.method, Url.USER_LOGIN.url, req)
      .then((resp) => {
        debug("success");
        setLocalStorageUserLogin({
          accessToken: resp.responseData.accessToken,
          userType: resp.responseData.userProfile.userType,
        });

        const payload = {
          userProfile: resp.responseData.userProfile,
        };

        dispatch({ type: LOGIN_SUCCESS, payload });

        window.location.reload();
      })
      .catch((err) => {
        debug("error");
        debug(err);
        dispatch({ type: IS_LOADING_STOP });

        if (err.error) notify("err", err.error.responseMessage);
        else if (err) notify("err", err.message);

        if (err.errorCode === 2) dispatch({ type: LOG_OUT_SUCCESS });
      });
  } catch (error) {
    notify("err", error.message);
  }
};
