import { Button, Col, Form } from "react-bootstrap";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import Loader from "../../components/common/Loader";
import Url from "../../services/BaseUrl";
import _ from "lodash";
import { _Api } from "../../services/Api";
import { notify } from "../../components/common/Tooster";

export class AddressInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      isEditAddress: false,
      addressId: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      errors: [],
      responseError: "",
    };
  }

  componentDidMount() {
    const { addressInfo } = this.props;
    if (addressInfo && addressInfo._id) {
      this.setState({
        isEditAddress: true,
        addressId: addressInfo._id,
        addressLine1: addressInfo.addressLine1,
        addressLine2: addressInfo.addressLine2,
        city: addressInfo.city,
        state: addressInfo.state,
        postalCode: addressInfo.postalCode,
      });
    } else {
      this.setState({
        isEditAddress: false,
        addressId: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
      });
    }
  }

  handleOnChange = (e) => {
    const regexp = new RegExp(`^-?[0-9]*$`);
    if (e.target.name === "postalCode") {
      if (regexp.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
          error: [],
          responseError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        error: [],
        responseError: "",
      });
    }
  };

  handleAddressValidate = () => {
    let validate = false;
    let errors = {};
    let { addressLine1, state, city, postalCode } = this.state;

    if (_.isEmpty(addressLine1)) {
      validate = true;
      errors["addressLine1"] = "Address line 1 is required.";
    }
    if (_.isEmpty(state)) {
      validate = true;
      errors["state"] = "State is required.";
    }
    if (_.isEmpty(city)) {
      validate = true;
      errors["city"] = " City is required.";
    }
    if (_.isEmpty(postalCode)) {
      validate = true;
      errors["postalCode"] = " Zip code is required.";
    }
    this.setState({ errors });
    return validate;
  };

  handleOnClickAddAddress = (e) => {
    const { userId, onRefreshAddress } = this.props;
    let { addressLine1, addressLine2, city, state, postalCode } = this.state;
    if (!this.handleAddressValidate()) {
      let req = {
        userId: userId,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
        postalCode: postalCode,
      };
      this.setState({ isLoader: true });
      _Api(Url.USER_ADD_ADDRESS.method, Url.USER_ADD_ADDRESS.url, req)
        .then((resp) => {
          this.setState({ isLoader: false });
          notify("success", resp.responseData.message);
          onRefreshAddress();
        })
        .catch((err) => {
          this.setState({ isLoader: false });
          notify("error", err.error.responseMessage);
        });
    }
  };

  handleOnClickUpdateAddress = (e) => {
    const { userId, onRefreshAddress } = this.props;
    let { addressId, addressLine1, addressLine2, city, state, postalCode } =
      this.state;
    if (!this.handleAddressValidate()) {
      let req = {
        userId: userId,
        addressId: addressId,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
        postalCode: postalCode,
      };
      this.setState({ isLoader: true });
      _Api(Url.USER_EDIT_ADDRESS.method, Url.USER_EDIT_ADDRESS.url, req)
        .then((resp) => {
          this.setState({ isLoader: false });
          notify("success", resp.responseData.message);
          onRefreshAddress();
        })
        .catch((err) => {
          this.setState({ isLoader: false });
          notify("error", err.error.responseMessage);
        });
    }
  };

  render() {
    let { addressLine1, addressLine2, city, state, postalCode, errors } =
      this.state;
    return (
      <div className="shoopingcart_list">
        {this.state.isLoader && <Loader />}
        <div className="heading">
          <h6>
            <span className="text-bc">
              <Link to="/userprofile">Contact Details</Link>
            </span>{" "}
            / {this.state.isEditAddress ? "Edit" : "Add"} Address
          </h6>
        </div>
        <Form className="useraddress">
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Control
                type="text"
                placeholder=" Enter address line 1"
                maxLength={100}
                name="addressLine1"
                value={addressLine1}
                onChange={this.handleOnChange}
              />
              <span className="error_msg">{errors["addressLine1"]}</span>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Control
                type="text"
                placeholder="Enter address line 2"
                maxLength={100}
                name="addressLine2"
                value={addressLine2}
                onChange={this.handleOnChange}
              />
              <span className="error_msg">{errors["addressLine2"]}</span>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Control
                type="text"
                placeholder="Enter city"
                maxLength={50}
                name="city"
                value={city}
                onChange={this.handleOnChange}
              />
              <span className="error_msg">{errors["city"]}</span>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Control
                type="text"
                placeholder="Enter state"
                maxLength={100}
                name="state"
                value={state}
                onChange={this.handleOnChange}
              />
              <span className="error_msg">{errors["state"]}</span>
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Control
              type="text"
              placeholder=" Enter postal code"
              maxLength={10}
              name="postalCode"
              value={postalCode}
              onChange={this.handleOnChange}
            />
            <span className="error_msg">{errors["postalCode"]}</span>
          </Form.Group>
          <div className="text-right">
            {this.state.isEditAddress ? (
              <Button
                variant="info"
                className="btn-lg"
                onClick={this.handleOnClickUpdateAddress}
              >
                Update Address
              </Button>
            ) : (
              <Button
                variant="info"
                className="btn-lg"
                onClick={this.handleOnClickAddAddress}
              >
                Save Address
              </Button>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

export default AddressInfo;
