import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import classnames from "classnames";

const HowItWorks = () => {
  const cards = [
    {
      title: "Discover <br>Local Farms <br>Near You",
      text: "Now working with farms throughout Connecticut and on Fishers Island, more locations coming soon",
      image: require("../../assets/img/connectguide1.png"),
      class: "how-it-works-card-discover",
    },
    {
      title: "Shop Seasonal,<br> Sustainable,<br> Healthy Food",
      text: "Support sustainable growing practices and build a strong food system in your community",
      image: require("../../assets/img/connectguide2.png"),
      class: "how-it-works-card-shop",
    },
    {
      title:
        "Choose from Local <br>Pick Up, Delivery, or <br> Sustainable Shipping",
      text: "Pick up at a farmers market, pop-up, school, or have your order delivered to your door.",
      image: require("../../assets/img/connectguide4.png"),
      class: "how-it-works-card-distribution",
    },
  ];

  return (
    <section className="block padded bg-tan" id="how-it-works">
      <Container className="how-it-works-container">
        <div className="row mb3">
          <div className="col-lg-8">
            <h2 className="section-heading underscore">How it Works</h2>
            <h3 className="font-main h3-5 section-subheading">
              Healthy PlanEat connects you to local sustainable farms so you can
              have a healthy and sustainable diet
            </h3>
          </div>
        </div>
        <Row className="how-it-works-row">
          {cards.map((card, cardIndex) => {
            return (
              <div
                className="how-it-works-col col-md-6 col-lg-4"
                key={`how-it-works-col-${cardIndex}`}
              >
                <div className={`${card.class} how-it-works-card`}>
                  <div className="how-it-works-card-text centered">
                    <h3
                      className="mb1 h3-5 how-it-works-card-heading"
                      dangerouslySetInnerHTML={{ __html: card.title }}
                    ></h3>
                    <h4
                      className="mb1 font-main h4-5 how-it-works-card-subheading"
                      dangerouslySetInnerHTML={{ __html: card.text }}
                    ></h4>
                  </div>
                  <div className="how-it-works-card-image">
                    <img src={`${card.image}`} alt="" />
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default HowItWorks;
