/**
 * @About
 *
 *
 *
 */

import {
  GET_USER_CART_LIST_SUCCESS,
  GET_USER_PROFILE_SUCCESS,
  IS_LOADING_START,
  IS_LOADING_STOP,
} from "./Type";

import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";
import { notify } from "../../components/common/Tooster";

// action for get user profile
export const userProfile = (req) => (dispatch) => {
  try {
    dispatch({ type: IS_LOADING_START });

    _Api(Url.GET_USER_PROFILE.method, Url.GET_USER_PROFILE.url)
      .then((resp) => {
        const payload = {
          profileInfo: resp.responseData.userProfile,
        };

        dispatch({ type: GET_USER_PROFILE_SUCCESS, payload });
        dispatch({ type: IS_LOADING_STOP });
      })
      .catch((err) => {
        dispatch({ type: IS_LOADING_STOP });
      });
  } catch (error) {
    notify("err", error.message);
  }
};

// action for get cart list
export const getCart = () => (dispatch) => {
  try {
    dispatch({ type: IS_LOADING_START });

    _Api(Url.USER_GET_CART_LIST.method, Url.USER_GET_CART_LIST.url)
      .then((resp) => {
        const payload = {
          cartListDetails: resp.responseData.result,
        };

        dispatch({ type: GET_USER_CART_LIST_SUCCESS, payload });
        dispatch({ type: IS_LOADING_STOP });
      })
      .catch((err) => {
        dispatch({ type: IS_LOADING_STOP });
      });
  } catch (error) {
    notify("err", error.message);
  }
};

/**
 * @param  {Number} payload.productQuantity
 * @param  {Number} payload.productPrice
 * @param  {String} payload.productId
 * @param  {String} payload.farmId
 * @param  {String} payload.unit
 * @param  {String} payload.variety
 * @param  {String?} payload.subscriptionInterval?
 * @param  {Number?} payload.subscriptionIntervalCount?
 * @return {[type]}          [description]
 */
export const addToCart = (payload) => (dispatch) => {
  try {
    dispatch({ type: IS_LOADING_START });
    _Api(
      Url.USER_ADD_PRODUCT_TO_CART.method,
      Url.USER_ADD_PRODUCT_TO_CART.url,
      payload,
    )
      .then((resp) => {
        dispatch(getCart());
      })
      .catch((err) => {
        dispatch({ type: IS_LOADING_STOP });
        if (err.error) {
          notify("err", err.error.responseMessage);
        }
      });
  } catch (error) {
    notify("err", error.message);
  }
};

export const updateCart = (payload) => (dispatch) => {
  dispatch({ type: IS_LOADING_START });
  _Api(Url.USER_EDIT_PRODUCT_QTY.method, Url.USER_EDIT_PRODUCT_QTY.url, payload)
    .then((resp) => {
      dispatch(getCart());
    })
    .catch((err) => {
      if (err.error) {
        notify("error", err.error.responseMessage);
      }
    })
    .finally(() => {
      dispatch({ type: IS_LOADING_STOP });
    });
};
