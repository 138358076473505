import { USER_TYPE } from "./user";
import { cartShippedProducts } from "./cart";
const debug = require("debug")("util-distribution");

export const DELIVERY_FOR = {
  CUSTOMER: 1,
  WHOLESALER: 2,
  CUSTOMER_AND_WHOLESALER: 3,
  UNSPECIFIED: null, // this is unspecified for Pickup distributions
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const DISTRIBUTION_COURIER_TYPE = {
  DELIVERY: "Delivery",
  PICKUP: "Pickup",
  SHIPPING: "Shipping",
};

export const DISTRIBUTION_CATEGORY = {
  FARM: 3,
  FARMERS_MARKET: 2,
  POPUP_MARKET: 1,
};

export const DISTRIBUTION_TYPE = {
  PICKUP: 1,
  DELIVERY: 2,
  SHIPPING: 3,
};

export const distributionTypeStringForInt = (distributionType) => {
  if (distributionType === DISTRIBUTION_TYPE.PICKUP)
    return DISTRIBUTION_COURIER_TYPE.PICKUP;
  if (distributionType === DISTRIBUTION_TYPE.DELIVERY)
    return DISTRIBUTION_COURIER_TYPE.DELIVERY;
  if (distributionType === DISTRIBUTION_TYPE.SHIPPING)
    return DISTRIBUTION_COURIER_TYPE.SHIPPING;
  return "";
};

const { uniq } = require("lodash");

const { GetCloseAndOpenStatus } = require("./Helper.js");

const isNonEmptyString = (s) => {
  return typeof s === "string" && s.length > 0;
};

const deliveryForUserType = ({ userType }) => {
  if (userType === USER_TYPE.CUSTOMER) return DELIVERY_FOR.CUSTOMER;
  if (userType === USER_TYPE.WHOLESALER) return DELIVERY_FOR.WHOLESALER;
  return null;
};

export const getPickupDistribution = ({ distributionInfo }) => {
  if (!Array.isArray(distributionInfo)) return [];
  return distributionInfo.filter((distribution) => {
    return (
      distribution.selectPickDel === DISTRIBUTION_TYPE.PICKUP &&
      distribution.pickup.filter((pickup) => {
        return (
          pickup &&
          typeof pickup.day === "number" &&
          Array.isArray(pickup.time) &&
          pickup.time.filter((time) => {
            return (
              isNonEmptyString(time.starttime) && isNonEmptyString(time.endTime)
            );
          }).length > 0
        );
      }).length > 0
    );
  });
};

export const getOpenOrderPickupDistribution = (distributionInfo) => {
  if (!Array.isArray(distributionInfo)) return null;

  const openOrderWindowDistribution =
    getOpenOrderDistribution(distributionInfo);

  return getPickupDistribution({
    distributionInfo: openOrderWindowDistribution,
  });
};

export const hasOpenOrderPickupDistribution = (distributionInfo) => {
  const openPickupDistribution =
    getOpenOrderPickupDistribution(distributionInfo);
  return (
    Array.isArray(openPickupDistribution) && openPickupDistribution.length > 0
  );
};

export const getDeliveryDistribution = (distributionInfo) => {
  if (!Array.isArray(distributionInfo)) return [];

  const userType = localStorage.getItem("userType")
    ? parseInt(localStorage.getItem("userType"))
    : USER_TYPE.CUSTOMER;

  const availableDeliveryFor = [
    deliveryForUserType({ userType }),
    DELIVERY_FOR.CUSTOMER_AND_WHOLESALER,
    DELIVERY_FOR.UNSPECIFIED,
  ];

  const deliveryDistribution = distributionInfo.filter((info) => {
    return (
      info.selectPickDel === DISTRIBUTION_TYPE.DELIVERY &&
      info.delivery &&
      info.delivery.length &&
      info.deliveryPostalCodes &&
      info.deliveryPostalCodes.length &&
      availableDeliveryFor.includes(info.deliveryFor)
    );
  });

  return deliveryDistribution;
};

export const getShippingDistribution = ({
  distributionInfo,
  userType,
  zipCode,
}) => {
  if (!Array.isArray(distributionInfo)) return [];

  const availableDeliveryFor = [
    deliveryForUserType({ userType }),
    DELIVERY_FOR.CUSTOMER_AND_WHOLESALER,
    DELIVERY_FOR.UNSPECIFIED,
  ];

  return distributionInfo.filter((distribution) => {
    const isShipping =
      distribution.selectPickDel === DISTRIBUTION_TYPE.SHIPPING;
    const forClient = availableDeliveryFor.includes(distribution.deliveryFor);
    const hasDeliveryPostalCodes =
      Array.isArray(distribution.deliveryPostalCodes) &&
      distribution.deliveryPostalCodes.length > 0;
    let hasSpecificZipCode = true;
    if (zipCode) {
      hasSpecificZipCode = distribution.deliveryPostalCodes.includes(zipCode);
    }
    return (
      isShipping && forClient && hasDeliveryPostalCodes && hasSpecificZipCode
    );
  });
};

export const hasDeliveryDistribution = (distributionInfo) => {
  const deliveryDistribution = getDeliveryDistribution(distributionInfo);
  return Array.isArray(deliveryDistribution) && deliveryDistribution.length > 0;
};

export const getOpenOrderDeliveryDistribution = (distributionInfo) => {
  if (!Array.isArray(distributionInfo)) return [];

  const openOrderWindowDistribution =
    getOpenOrderDistribution(distributionInfo);

  return getDeliveryDistribution(openOrderWindowDistribution);
};

export const getOpenOrderDeliveryDistributionForDeliveryZipCode = ({
  distributionInfo,
  deliverToZipCode,
}) => {
  const deliveryDistribution =
    getOpenOrderDeliveryDistribution(distributionInfo);
  // no zip code set, return the whole set
  if (!deliverToZipCode) return deliveryDistribution;
  // filter the set based on zicode
  return getOpenOrderDeliveryDistribution(distributionInfo).filter(
    (distributionDetail) => {
      return distributionDetail.deliveryPostalCodes.includes(deliverToZipCode);
    },
  );
};

export const hasOpenOrderDeliveryDistributionForDeliveryZipCode = ({
  distributionInfo,
  deliverToZipCode,
}) => {
  const deliveryDistribution =
    getOpenOrderDeliveryDistributionForDeliveryZipCode({
      distributionInfo,
      deliverToZipCode,
    });
  return Array.isArray(deliveryDistribution) && deliveryDistribution.length > 0;
};

export const hasOpenOrderDeliveryDistribution = (distributionInfo) => {
  const openDeliveryDistribution =
    getOpenOrderDeliveryDistribution(distributionInfo);
  return (
    Array.isArray(openDeliveryDistribution) &&
    openDeliveryDistribution.length > 0
  );
};

export const hasOpenOrderDistribution = (distributionInfo) => {
  return getOpenOrderDistribution(distributionInfo).length > 0;
};

export const getOpenOrderDistribution = (distributionInfo) => {
  if (!Array.isArray(distributionInfo)) return [];

  return distributionInfo.filter((distributionInfo) => {
    return GetCloseAndOpenStatus({ distributionInfo });
  });
};

const filterDistributionForUserType = ({ userType }) => {
  // we use the available delivery for to determine what to see
  const availableDeliveryFor = [
    deliveryForUserType({ userType }), // current user type
    DELIVERY_FOR.CUSTOMER_AND_WHOLESALER, // a delivery or shipping with customer & wholesaler
    DELIVERY_FOR.UNSPECIFIED, // a pickup
  ];
  return (distributionInfo) => {
    return availableDeliveryFor.includes(distributionInfo.deliveryFor);
  };
};

export const getDistributionForUserType = ({ distributionInfo, userType }) => {
  if (!Array.isArray(distributionInfo)) return [];
  return distributionInfo.filter(filterDistributionForUserType({ userType }));
};

export const hasDistributionForUserType = ({ distributionInfo, userType }) => {
  return getDistributionForUserType({ distributionInfo, userType }).length > 0;
};

export const getOpenOrderShippingDistribution = ({
  distributionInfo,
  userType,
  zipCode,
}) => {
  const openOrderWindowDistribution =
    getOpenOrderDistribution(distributionInfo);
  return getShippingDistribution({
    distributionInfo: openOrderWindowDistribution,
    userType,
    zipCode,
  });
};

export const hasOpenOrderShippingDistribution = ({
  distributionInfo,
  userType,
  zipCode,
}) => {
  return (
    getOpenOrderShippingDistribution({ distributionInfo, userType, zipCode })
      .length > 0
  );
};

export const getOpenOrderNonShippingDistribution = ({
  distributionInfo,
  userType,
}) => {
  const openOrderWindowDistribution =
    getOpenOrderDistribution(distributionInfo);
  const pickupDistributions = getPickupDistribution({
    distributionInfo: openOrderWindowDistribution,
  });
  const deliveryDistribution = getDeliveryDistribution(
    openOrderWindowDistribution,
  );

  return pickupDistributions.concat(deliveryDistribution);
};

export const hasOpenOrderNonShippingDistribution = ({
  distributionInfo,
  userType,
}) => {
  return (
    getOpenOrderNonShippingDistribution({ distributionInfo, userType }).length >
    0
  );
};

export const getOpenOrderDistributionForUserType = ({
  distributionInfo,
  userType,
}) => {
  if (!Array.isArray(distributionInfo)) return [];
  return distributionInfo
    .filter((distributionInfo) => {
      return GetCloseAndOpenStatus({ distributionInfo });
    })
    .filter(filterDistributionForUserType({ userType }));
};

export const hasOpenOrderDistributionForUserType = ({
  distributionInfo,
  userType,
}) => {
  return (
    getOpenOrderDistributionForUserType({ distributionInfo, userType }).length >
    0
  );
};

export const formatDayAndTime = (distribution) => {
  if (
    !distribution ||
    !distribution.time ||
    !distribution.time.length ||
    !typeof distribution.day === "number"
  ) {
    return "";
  }

  // pickup times should be flattened so that there is only
  // one pickup time per day
  const { day } = distribution;
  const time = distribution.time[0];

  return `${days[day]} ${time.starttime} - ${time.endTime}`;
};

export const formatDeliveryInfo = (deliveryInfo) => {
  if (!deliveryInfo) {
    return "";
  }

  const { addressLine1, addressLine2, city, state, postalCode } = deliveryInfo;

  const address = [addressLine1, addressLine2, city, state, postalCode].filter(
    Boolean,
  );

  return address.map((s) => s.trim()).join(", ");
};

export const getZipCodes = ({ distributionInfo }) => {
  return uniq(
    distributionInfo
      .filter((distribution) => {
        return Array.isArray(distribution.deliveryPostalCodes);
      })
      .reduce((accumulator, distribution) => {
        return accumulator.concat(distribution.deliveryPostalCodes);
      }, []),
  ).sort();
};

const getDeliveryZipCodes = (distributionInfo) => {
  if (!Array.isArray(distributionInfo)) return [];

  return uniq(
    getDeliveryDistribution(distributionInfo).reduce((accumulator, info) => {
      return accumulator.concat(info.deliveryPostalCodes);
    }, []),
  ).sort();
};

const getOpenOrderDeliveryZipCodes = (distributionInfo) => {
  if (!Array.isArray(distributionInfo)) return [];

  return uniq(
    getOpenOrderDeliveryDistribution(distributionInfo).reduce(
      (accumulator, info) => {
        return accumulator.concat(info.deliveryPostalCodes);
      },
      [],
    ),
  ).sort();
};

export const getDeliveryZipCodesString = (distributionInfo) => {
  return getDeliveryZipCodes(distributionInfo).join(", ");
};
export const getOpenOrderDeliveryZipCodesString = (distributionInfo) => {
  return getOpenOrderDeliveryZipCodes(distributionInfo).join(", ");
};

export const getDeliveryFlatFee = (deliveryInfo) => {
  if (isNaN(parseFloat(deliveryInfo.flatFee))) return 0;
  return deliveryInfo.flatFee.toFixed(2);
};
export const deliveryAvailable = (deliveryInfo, distributionInfo) => {
  if (!deliveryInfo || !deliveryInfo.postalCode) {
    return false;
  }

  const deliveryZipCodes = getDeliveryZipCodes(distributionInfo);

  return deliveryZipCodes.includes(deliveryInfo.postalCode);
};

/**
 * Check if cart is still valid (distribution options that were chosen are still available)
 *
 * @param {object} cart
 * @param {array} farms
 */
export const cartHasValidDistributionOptions = ({ cart, farms, userType }) => {
  const { buyList, deliveryInfo } = cart;

  const shippedProducts = cartShippedProducts({ cart });
  const deliveries = buyList.filter((list) => {
    return list.courierType === "Delivery";
  });

  // deliveries and shipping require an address to send to
  if (
    (deliveries.length > 0 || shippedProducts.length > 0) &&
    deliveryInfo === null
  ) {
    return false;
  }

  let valid = true;

  deliveries.forEach((delivery) => {
    // If we've already established something's wrong, just skip the rest
    if (!valid) {
      return;
    }

    const { farmId } = delivery;

    const farm = farms.find((f) => {
      return f._id === farmId;
    });

    // Couldn't find farm in our list.. something's wrong
    if (!farm) {
      valid = false;
      return;
    }

    const { distributionInfo } = farm;

    // This farm doesn't have distribution info.. something's wrong
    if (!distributionInfo || !distributionInfo.length) {
      valid = false;
      return;
    }

    const deliveryDistribution =
      getOpenOrderDeliveryDistribution(distributionInfo);

    // This farm doesn't have a distribution with delivery ... something's wrong
    if (
      !(Array.isArray(deliveryDistribution) && deliveryDistribution.length > 0)
    ) {
      valid = false;
      return;
    }

    const deliveryPostalCodes = getDeliveryZipCodes(deliveryDistribution);

    // Now we can finally validate that the cart's delivery postal code
    // is in the farmer's distribution delivery postal codes
    if (!deliveryPostalCodes.includes(deliveryInfo.postalCode)) {
      valid = false;
    }
  });

  shippedProducts.forEach(({ productListEntry, farmId }) => {
    if (valid == false) return;
    const zipCode = cart.deliveryInfo?.postalCode;

    const farm = farms.find((f) => {
      return f._id === farmId;
    });

    // Couldn't find farm in our list.. something's wrong
    if (!farm) {
      valid = false;
      return;
    }

    const distributionInfo = farm.distributionInfo;
    const shippingDistributions = getShippingDistribution({
      distributionInfo,
      userType,
    });
    const openShippingDistributions = getOpenOrderDistribution(
      shippingDistributions,
    );
    const openShippingZipCodes = getZipCodes({
      distributionInfo: openShippingDistributions,
    });
    const hasOpenShippingOrderWindow = openShippingDistributions.length > 0;
    const canShipToZipCode = openShippingZipCodes.includes(zipCode);
    if (!canShipToZipCode) {
      valid = false;
    }
    if (!hasOpenShippingOrderWindow) {
      valid = false;
    }
  });

  return valid;
};

export const isValidAddress = (address) => {
  if (typeof address !== "object" || address === null) return false;
  const { addressLine1, city, state, postalCode } = address;
  const required = [addressLine1, city, state, postalCode];
  return required.length === required.filter(isNonEmptyString).length;
};

export const isNonEmptyAddress = (address) => {
  if (typeof address !== "object" || address === null) return false;
  const { addressLine1, city, state, postalCode } = address;
  const optional = [addressLine1, city, state, postalCode];
  return optional.filter(isNonEmptyString).length > 0;
};

export const hasPickupAvailable = (distributionInfo) => {
  let pickupAvailable = false;

  if (!distributionInfo) return pickupAvailable;

  distributionInfo.forEach((info) => {
    if (info.selectPickDel !== DISTRIBUTION_TYPE.PICKUP) return;
    if (!info.pickup) return;
    info.pickup.forEach((pickup) => {
      if (!Number.isInteger(pickup.day)) return;
      pickup.time.forEach((time) => {
        if (time.starttime && time.endTime) {
          pickupAvailable = true;
        }
      });
    });
  });
  return pickupAvailable;
};

export const openOrderWindowDistributionForUserId = ({
  distributionInfo,
  userId,
}) => {
  if (!Array.isArray(distributionInfo)) return [];
  return distributionInfo.filter((distribution) => {
    return (
      distribution.userId === userId &&
      GetCloseAndOpenStatus({ distributionInfo: distribution })
    );
  });
};
