import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import React, { Component } from "react";
import { Units, days } from "../../utils/Helper";

import Geocode from "react-geocode";
import Joinourfoodmovement from "../home/Joinourfoodmovement";
import { Link } from "react-router-dom";
import Loader from "../../components/common/Loader.js";
import { Redirect } from "react-router-dom";
import Url from "../../services/BaseUrl";
import _ from "lodash";
import { _Api } from "../../services/Api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCart } from "../../redux/action/Get";
import { history } from "../../Routes";
import { login } from "../../redux/action/Auth";
import { notify } from "../../components/common/Tooster.js";
import { userProfile } from "../../redux/action/Get";

// function GetCloseAndOpenStatus(day, time) {
//   let orderStatus = false;
//   let currentDay = new Date().getDay();

//   if (currentDay == day) {
//     let currentHours = new Date().getHours();
//     let currentMinutes = new Date().getMinutes();

//     let get_hour_time = time.endTime.split(' ')[0];
//     let end_hours = get_hour_time.split(':')[0];
//     let end_min = get_hour_time.split(':')[1];

//     if (currentHours < end_hours) {
//       orderStatus = true;
//     }
//     if (end_hours == currentHours && currentMinutes <= end_min) {
//       orderStatus = true;
//     }
//   }
//   return orderStatus;
// }

class Productetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToSopingCart: false,
      isLoader: false,
      agreePick: false,
      qtyMsg: false,
      minAmountMsg: false,
      minItemMsg: false,
      addrMsg: false,
      allFill: false,
      isTrue: false,
      validDeliveryAddress: false,
      showDeliveryCharge: true,
      isFlatFee: false,
      select_delivery: true,
      select_flat: false,
      errors: [],
      profileInfo: [],
      cardInfo: [],
      maxQty: 0,

      productDetails: "",
      distribuationDetails: [],
      farmInfo: {},
      isPickupLocation: false,
      isDeliveryLocation: false,
      total: null,
      totalItem: 1,
      selectDelivery: false,
      selectPick: false,
      deliveryFees: 0,
      flatFees: 0,
      minPurchaseOrder: 10,

      farmlat: "",
      farmlng: "",
      farmradius: "",
      productPrice: "",
      productId: "",
      farmerId: "",
      address: "",
      deliveryType: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      postalCode: "",
      totalDeliveryCharges: "",
    };
  }

  RemovehandleClose = () => {
    this.setState({
      qtyMsg: false,
      minAmountMsg: false,
      minItemMsg: false,
      addrMsg: false,
    });
  };

  showAddress = () => {
    let { addressLine1, addressLine2, state, city, postalCode } = this.state;

    let firmaddess = "";
    if (addressLine1) {
      firmaddess = firmaddess + addressLine1;
      if (addressLine2 || city || state || postalCode) {
        firmaddess = firmaddess + " , ";
      }
    }
    if (addressLine2) {
      firmaddess = firmaddess + addressLine2;
      if (city || state || postalCode) {
        firmaddess = firmaddess + " , ";
      }
    }
    if (city) {
      firmaddess = firmaddess + city;
      if (state || postalCode) {
        firmaddess = firmaddess + " , ";
      }
    }
    if (state) {
      firmaddess = firmaddess + state;
      if (postalCode) {
        firmaddess = firmaddess + " , ";
      }
    }
    if (postalCode) {
      firmaddess = firmaddess + postalCode;
    }
    return firmaddess;
  };

  getFeeLatLng = () => {
    let { deliveryFees, addressLine1, addressLine2, state, city, postalCode } =
      this.state;
    let setAddress = addressLine1;
    if (addressLine2) {
      setAddress = setAddress + " " + addressLine2;
    }
    setAddress = setAddress + " " + city + " " + state + " " + postalCode;
    Geocode.fromAddress(setAddress).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        var userLat = response.results[0].geometry.location.lat;
        var userLong = response.results[0].geometry.location.lng;

        var R = 6371;
        var dLat = ((userLat - this.state.farmlat) * Math.PI) / 180;
        var dLon = ((userLong - this.state.farmlng) * Math.PI) / 180;
        var a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos((userLat * Math.PI) / 180) *
            Math.cos((this.state.farmlat * Math.PI) / 180) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        var calculate_fee = deliveryFees * c;
        this.setState({
          totalDeliveryCharges: deliveryFees * c,
          validDeliveryAddress: true,
        });
        // if(parseFloat(this.state.totalDeliveryCharges) == parseFloat(calculate_fee.toFixed(2))) {
        //     this.setState({ showDeliveryCharge: false })
        // } else {
        this.setState({ showDeliveryCharge: true });
        // }
      },
      (error) => {
        console.error(error);
      },
    );
  };

  componentWillReceiveProps(nextProps, nextState) {
    let self = this;
    if (
      nextProps &&
      nextProps.profiledetails &&
      nextProps.profiledetails.profileInfo &&
      nextProps.profiledetails.profileInfo._id
    ) {
      this.setState({ profileInfo: nextProps.profiledetails.profileInfo });
    }
    if (
      nextProps &&
      nextProps.cartListDetails &&
      nextProps.cartListDetails._id
    ) {
      this.setState({ cardInfo: nextProps.cartListDetails });
    }
  }

  componentWillMount() {
    // when menu change
    let self = this;
    let getProps = this.props;
    if (
      getProps &&
      getProps.profiledetails &&
      getProps.profiledetails.profileInfo &&
      getProps.profiledetails.profileInfo._id
    ) {
      this.setState({ profileInfo: getProps.profiledetails.profileInfo });
    }
    if (getProps && getProps.cartListDetails && getProps.cartListDetails._id) {
      this.setState({ cardInfo: getProps.cartListDetails });
    }
  }

  componentDidMount() {
    let selectSingleProductQty = localStorage.getItem("selectSingleProductQty");
    if (selectSingleProductQty) {
      this.setState({ totalItem: selectSingleProductQty }, function () {
        this.getInvetoryDetailsById();
      });
    } else {
      this.setState({ totalItem: 1 }, function () {
        this.getInvetoryDetailsById();
      });
    }

    let self = this;
    let getProps = this.props;
    if (
      getProps &&
      getProps.profiledetails &&
      getProps.profiledetails.profileInfo &&
      getProps.profiledetails.profileInfo._id
    ) {
      this.setState({ profileInfo: getProps.profiledetails.profileInfo });
    }
    if (getProps && getProps.cartListDetails && getProps.cartListDetails._id) {
      this.setState({ cardInfo: getProps.cartListDetails });
    }
  }

  getInvetoryDetailsById = () => {
    let { totalItem } = this.state;
    this.setState({ isLoader: true });
    let req = {
      inventoryId: this.props.match.params.id,
      userType: localStorage.getItem("userType")
        ? localStorage.getItem("userType")
        : 2,
    };
    _Api(
      Url.USER_GET_INVEBTERY_VIEW_DETAILS_BY_ID.method,
      Url.USER_GET_INVEBTERY_VIEW_DETAILS_BY_ID.url,
      "",
      `?inventoryId=${req.inventoryId}&userType=${req.userType}`,
    )
      .then((resp) => {
        let obj = resp.responseData.result;

        this.setState({ isLoader: false });
        this.setState({ productDetails: resp.responseData.result.invetory });
        this.setState({
          distribuationDetails: resp.responseData.result.distribution,
        });
        this.setState({ farmInfo: resp.responseData.result.farm });
        this.setState({
          farmlng: resp.responseData.result.farm.geoLocation[0],
          farmlat: resp.responseData.result.farm.geoLocation[1],
        });
        this.setState({
          productId: resp.responseData.result.invetory._id,
          farmerId: resp.responseData.result.invetory.userId._id,
        });

        if (localStorage.getItem("userType") == 2) {
          this.setState({
            total: totalItem * resp.responseData.result.invetory.consumerPrice,
            maxQty: resp.responseData.result.invetory.maximumConsumerQuantity,
            productPrice: resp.responseData.result.invetory.consumerPrice,
          });
        } else {
          this.setState({
            total:
              totalItem * resp.responseData.result.invetory.wholeselerPrice,
            maxQty: resp.responseData.result.invetory.maximumWholeselerQuantity,
            productPrice: resp.responseData.result.invetory.wholeselerPrice,
          });
        }

        if (resp.responseData.result.invetory.status == 0) {
          this.setState({ maxQty: 0 });
        }

        if (
          resp.responseData.result.distribution.findIndex(
            (obj) => obj.selectPickDel == 2,
          ) != -1
        ) {
          this.setState({
            isDeliveryLocation: true,
            selectPick: false,
            selectDelivery: true,
          });

          let shipping_fee = 0;
          let flat_fee = 0;
          resp.responseData.result.distribution.map(
            (obj_distribution, index) => {
              if (obj_distribution.deliveryFees > shipping_fee) {
                this.setState({
                  deliveryFees: obj_distribution.deliveryFees,
                  farmradius: obj_distribution.radius,
                });
                shipping_fee = obj_distribution.deliveryFees;
              }
              if (obj_distribution.flatFee > flat_fee) {
                this.setState({
                  isFlatFee: true,
                  flatFees: obj_distribution.flatFee,
                  farmradius: obj_distribution.radius,
                });
                flat_fee = obj_distribution.flatFee;
              }
            },
          );
        }

        if (
          resp.responseData.result.distribution.findIndex(
            (obj) => obj.selectPickDel == 1,
          ) != -1
        ) {
          resp.responseData.result.distribution.map((obj_pickup, index) => {
            if (obj_pickup.pickup.length > 0) {
              this.setState({
                isPickupLocation: true,
                selectPick: true,
                selectDelivery: false,
              });
              // obj_pickup.pickup.map((item, index) => {
              //     let valid_for_show = false;
              //     item.time.forEach((shiftTime, i) => {
              //         let display = GetCloseAndOpenStatus(item.day,shiftTime);
              //         if(display) {
              //             valid_for_show = true
              //         }
              //     })
              //     if(valid_for_show) {
              //         this.setState({ isPickupLocation: true, selectPick: true, selectDelivery: false })
              //     }
              // })
            }
          });
        }

        let get_set_delevery_info = 0;
        // , totalDeliveryCharges: cartproduct.deliveryFees
        if (
          this.state.cardInfo &&
          this.state.cardInfo.productList &&
          this.state.cardInfo.productList.length > 0
        ) {
          this.state.cardInfo.productList.map((cartproduct, index) => {
            if (
              cartproduct.farmUserId ==
              resp.responseData.result.invetory.userId._id
            ) {
              if (
                cartproduct.courierType == "Delivery" &&
                get_set_delevery_info == 0
              ) {
                this.setState(
                  {
                    addressLine1: cartproduct.addressLine1,
                    addressLine2: cartproduct.addressLine2,
                    city: cartproduct.addressLine1,
                    state: cartproduct.state,
                    postalCode: cartproduct.postalCode,
                    deliveryType: cartproduct.deliveryType,
                    validDeliveryAddress: true,
                  },
                  function () {
                    this.getFeeLatLng();
                  },
                );
                if (cartproduct.deliveryType == "flat") {
                  this.setState({ select_flat: true, select_delivery: false });
                } else {
                  this.setState({ select_flat: false, select_delivery: true });
                }
                get_set_delevery_info = 1;
              }
            }
          });

          if (get_set_delevery_info == 0) {
            this.state.cardInfo.productList.map((cartproduct, index) => {
              if (
                cartproduct.courierType == "Delivery" &&
                get_set_delevery_info == 0
              ) {
                this.setState(
                  {
                    addressLine1: cartproduct.addressLine1,
                    addressLine2: cartproduct.addressLine2,
                    city: cartproduct.addressLine1,
                    state: cartproduct.state,
                    postalCode: cartproduct.postalCode,
                    deliveryType: cartproduct.deliveryType,
                    validDeliveryAddress: true,
                  },
                  function () {
                    this.getFeeLatLng();
                  },
                );
                if (cartproduct.deliveryType == "flat") {
                  this.setState({ select_flat: true, select_delivery: false });
                } else {
                  this.setState({ select_flat: false, select_delivery: true });
                }
                get_set_delevery_info = 1;
              }
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  handleOnQtyChanged = (e) => {
    let { productDetails } = this.state;
    if (localStorage.getItem("userType") == 2) {
      if (e.target.value > productDetails.maximumConsumerQuantity) {
        this.setState({ qtyMsg: true });
      } else {
        this.setState({
          total: e.target.value * productDetails.consumerPrice,
          totalItem: e.target.value,
        });
        localStorage.setItem("selectSingleProductQty", e.target.value);
      }
    } else {
      if (e.target.value > productDetails.maximumWholeselerQuantity) {
        this.setState({ qtyMsg: true });
      } else {
        this.setState({
          total: e.target.value * productDetails.wholeselerPrice,
          totalItem: e.target.value,
        });
        localStorage.setItem("selectSingleProductQty", e.target.value);
      }
    }
  };

  handleOnChangeYes = (e) => {
    this.setState({ agreePick: true });
  };

  handleShipPickChange = (e) => {
    let errors = {};
    if (e.target.id == "selectDelivery") {
      this.setState({ selectDelivery: true, selectPick: false });
    } else {
      this.setState({ selectPick: true, selectDelivery: false });
    }
    this.setState({ address: "", errors });
  };

  handleDeliveryTypeChange = (e) => {
    let errors = {};
    if (e.target.id == "select_delivery") {
      this.setState({ select_delivery: true, select_flat: false });
    } else {
      this.setState({ select_flat: true, select_delivery: false });
    }
    this.setState({ address: "", errors });
  };

  handleOnPickupAddress = (e) => {
    let errors = {};
    this.setState({ address: e.target.value, errors });
  };

  handleOnChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        errors: [],
        validDeliveryAddress: false,
        showDeliveryCharge: false,
      },
      function () {
        let {
          farmerId,
          cardInfo,
          deliveryFees,
          addressLine1,
          addressLine2,
          state,
          city,
          postalCode,
        } = this.state;
        try {
          if (addressLine1 && state && city && postalCode) {
            this.getFeeLatLng();
          }
        } catch (err) {}
      },
    );
  };

  // delivery Validation
  handleDeliveryValidate = () => {
    let validate = false;
    let errors = {};
    let { addressLine1, addressLine2, state, city, postalCode } = this.state;

    if (_.isEmpty(addressLine1)) {
      validate = true;
      errors["addressLine1"] = "Address line 1 is required.";
    }
    if (_.isEmpty(state)) {
      validate = true;
      errors["state"] = "State is required.";
    }
    if (_.isEmpty(city)) {
      validate = true;
      errors["city"] = " City is required.";
    }
    if (_.isEmpty(postalCode)) {
      validate = true;
      errors["postalCode"] = " Zip code is required.";
    }
    this.setState({ errors });
    return validate;
  };

  // pickup Validation
  handlePickupValidate = () => {
    let validate = false;
    let errors = {};
    if (_.isEmpty(this.state.address)) {
      validate = true;
      errors["selectPickupAddr"] = " Please select pick up location.";
    }
    this.setState({ errors });
    return validate;
  };

  addToCart = (isTrue) => {
    if (this.state.selectDelivery && this.handleDeliveryValidate()) {
      return false;
    }
    if (this.state.selectPick) {
      if (this.handlePickupValidate()) {
        return false;
      }
      if (!this.state.agreePick) {
        let errors = {};
        errors["yes"] = " Please select agree condition.";
        this.setState({ errors });
        return false;
      }
    }

    let {
      productDetails,
      totalItem,
      total,
      productPrice,
      totalDeliveryCharges,
      productId,
      farmerId,
      address,
      addressLine1,
      addressLine2,
      state,
      city,
      postalCode,
      minPurchaseOrder,
    } = this.state;

    if (total == 0 || totalItem < 1) {
      this.setState({ minItemMsg: true });
      return false;
    }

    let setAddress = "";
    let shippingCharge = "";
    if (this.state.selectPick) {
      setAddress = address;
      shippingCharge = 0;
    }
    if (this.state.selectDelivery) {
      if (!this.state.validDeliveryAddress) {
        this.setState({ addrMsg: true });
        return false;
      }
      setAddress = this.showAddress();
      shippingCharge = totalDeliveryCharges
        ? totalDeliveryCharges.toFixed(2)
        : 0;
    }

    let unit = "";
    if (localStorage.getItem("userType") == 2) {
      unit = Units(
        productDetails.consumerUnit,
        productDetails.otherConsumerUnit,
      );
    } else {
      unit = Units(
        productDetails.wholeselerUnit,
        productDetails.otherWholeSalerUnit,
      );
    }

    let set_deliver_fee = 0;
    let set_deliver_type = "";
    if (this.state.selectDelivery) {
      set_deliver_fee = this.state.select_delivery
        ? shippingCharge
        : this.state.flatFees;
      set_deliver_type = this.state.select_delivery ? "delivery" : "flat";
    }

    let req = {
      productQuantity: totalItem,
      productPrice: productPrice,
      totalPrice: total,
      productId: productId,
      farmerId: farmerId,
      deliveryType: set_deliver_type, // delivery or flat
      deliveryFees: set_deliver_fee,
      address: setAddress,
      courierType: this.state.selectDelivery ? "Delivery" : "Pickup",
      unit: unit,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      postalCode: postalCode,
    };

    this.setState({ isLoader: true });
    _Api(
      Url.USER_ADD_PRODUCT_TO_CART.method,
      Url.USER_ADD_PRODUCT_TO_CART.url,
      req,
    )
      .then((resp) => {
        this.props.history.push({
          pathname: isTrue ? "/shoppingcart" : "/ordersummary",
          state: {
            data: this.state.distribuationDetails,
          },
        });
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  render() {
    console.log("productDetails:render");
    let userType = localStorage.getItem("userType");
    let {
      cardInfo,
      isLoader,
      errors,
      isDeliveryLocation,
      isPickupLocation,
      isFlatFee,
      select_flat,
      select_delivery,
      productDetails,
      distribuationDetails,
      selectDelivery,
      selectPick,
      deliveryLocation,
      address,
      yes,
      addressLine1,
      addressLine2,
      state,
      city,
      postalCode,
    } = this.state;

    return (
      <div className="body_container_wrapper product post">
        <Container>
          {isLoader && <Loader />}
          {distribuationDetails.length == 0 ? (
            <p className="text-center p-md-5">
              Sorry we are not deliver and pick order for this time.
            </p>
          ) : (
            <>
              <div className="product_details_wrapper">
                <div className="two_col_wrapper">
                  <Row>
                    <Col md="6">
                      <div className="farmer_cardimg">
                        <img
                          src={
                            productDetails.image == ""
                              ? require("../../assets/img/placehoder_2.png")
                              : productDetails.image
                          }
                          alt={productDetails.itemName}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="two_col_content">
                        <h6>
                          {productDetails.itemName === "others" &&
                          productDetails.otherItemName
                            ? productDetails.otherItemName
                            : productDetails.itemName}
                        </h6>
                        <div className="cm_product_details">
                          <div className="price">
                            <h6 className="headinggray">Unit Price</h6>
                            <p>
                              {userType == 3 ? (
                                <>$ {productDetails.wholeselerPrice}</>
                              ) : (
                                <>$ {productDetails.consumerPrice}</>
                              )}
                            </p>
                          </div>

                          {this.state.maxQty > 0 ? (
                            <>
                              <div className="price">
                                <h6 className="headinggray">Total Price</h6>
                                <p>
                                  {userType == 3 ? (
                                    <>
                                      ${" "}
                                      {this.state.total == null
                                        ? productDetails.wholeselerPrice
                                        : this.state.total}
                                    </>
                                  ) : (
                                    <>
                                      ${" "}
                                      {this.state.total == null
                                        ? productDetails.consumerPrice
                                        : this.state.total}
                                    </>
                                  )}
                                </p>
                              </div>
                              <div className="quantity">
                                <h6>Quantity</h6>
                                {userType == 3 ? (
                                  <>
                                    <input
                                      type="number"
                                      placeholder="1"
                                      min="1"
                                      max={
                                        productDetails.maximumWholeselerQuantity
                                      }
                                      className="form-control"
                                      value={this.state.totalItem}
                                      onChange={this.handleOnQtyChanged}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="number"
                                      placeholder="1"
                                      min="1"
                                      max={
                                        productDetails.maximumConsumerQuantity
                                      }
                                      className="form-control"
                                      value={this.state.totalItem}
                                      onChange={this.handleOnQtyChanged}
                                    />
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>

                        <div>
                          {this.state.maxQty > 0 ? (
                            <>
                              {userType == 3 ? (
                                <>
                                  {productDetails.maximumWholeselerQuantity <
                                  10 ? (
                                    <p>
                                      <small>
                                        Only{" "}
                                        {
                                          productDetails.maximumWholeselerQuantity
                                        }{" "}
                                        units left
                                      </small>
                                    </p>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  {productDetails.maximumConsumerQuantity <
                                  10 ? (
                                    <p>
                                      <small>
                                        Only{" "}
                                        {productDetails.maximumConsumerQuantity}{" "}
                                        units left
                                      </small>
                                    </p>
                                  ) : null}
                                </>
                              )}

                              <div className="radioinline">
                                {isDeliveryLocation && (
                                  <label>
                                    <Form.Check
                                      type="radio"
                                      name="address"
                                      id="selectDelivery"
                                      checked={selectDelivery ? "checked" : ""}
                                      onChange={this.handleShipPickChange}
                                    />
                                    Delivery location
                                  </label>
                                )}
                                {isPickupLocation && (
                                  <label>
                                    <Form.Check
                                      type="radio"
                                      defaultChecked={this.state.checked}
                                      name="address"
                                      checked={selectPick ? "checked" : ""}
                                      onChange={this.handleShipPickChange}
                                    />
                                    Pickup location
                                  </label>
                                )}
                              </div>

                              {selectDelivery == true ? (
                                <>
                                  <h6 className="">Delivery Location</h6>

                                  {isFlatFee && (
                                    <div className="radioinline">
                                      {isDeliveryLocation ? (
                                        <label>
                                          <Form.Check
                                            type="radio"
                                            name="deliveryType"
                                            id="select_delivery"
                                            checked={
                                              select_delivery ? "checked" : ""
                                            }
                                            onChange={
                                              this.handleDeliveryTypeChange
                                            }
                                          />
                                          Delivery Fee
                                        </label>
                                      ) : null}
                                      {isDeliveryLocation && isFlatFee && (
                                        <label>
                                          <Form.Check
                                            type="radio"
                                            name="deliveryType"
                                            checked={
                                              select_flat ? "checked" : ""
                                            }
                                            onChange={
                                              this.handleDeliveryTypeChange
                                            }
                                          />
                                          Flat Fee
                                        </label>
                                      )}
                                    </div>
                                  )}
                                  {distribuationDetails.some(
                                    (item) =>
                                      (item.deliveryFor == 2 &&
                                        userType == 3) ||
                                      (item.deliveryFor == 1 &&
                                        userType == 2) ||
                                      item.deliveryFor == 3,
                                  ) ? (
                                    <Form className="useraddress">
                                      <Form.Row>
                                        <Form.Group as={Col}>
                                          <Form.Control
                                            type="text"
                                            autocomplete="off"
                                            placeholder=" Enter address line 1"
                                            maxLength={100}
                                            name="addressLine1"
                                            value={addressLine1}
                                            onChange={this.handleOnChange}
                                          />
                                          <span className="error_msg">
                                            {errors["addressLine1"]}
                                          </span>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                          <Form.Control
                                            type="text"
                                            autocomplete="off"
                                            placeholder="Enter address line 2"
                                            maxLength={100}
                                            name="addressLine2"
                                            value={addressLine2}
                                            onChange={this.handleOnChange}
                                          />
                                          <span className="error_msg">
                                            {errors["addressLine2"]}
                                          </span>
                                        </Form.Group>
                                      </Form.Row>

                                      <Form.Group>
                                        <Form.Control
                                          type="text"
                                          autocomplete="off"
                                          placeholder="Enter city"
                                          maxLength={50}
                                          name="city"
                                          value={city}
                                          onChange={this.handleOnChange}
                                        />
                                        <span className="error_msg">
                                          {errors["city"]}
                                        </span>
                                      </Form.Group>
                                      <Form.Row>
                                        <Form.Group as={Col}>
                                          <Form.Control
                                            type="text"
                                            autocomplete="off"
                                            placeholder="Enter state"
                                            maxLength={100}
                                            name="state"
                                            value={state}
                                            onChange={this.handleOnChange}
                                          />
                                          <span className="error_msg">
                                            {errors["state"]}
                                          </span>
                                        </Form.Group>
                                      </Form.Row>
                                      <Form.Group>
                                        <Form.Control
                                          type="number"
                                          autocomplete="off"
                                          placeholder=" Enter postal code"
                                          maxLength={10}
                                          name="postalCode"
                                          value={postalCode}
                                          onChange={this.handleOnChange}
                                        />
                                        <span className="error_msg">
                                          {errors["postalCode"]}
                                        </span>
                                      </Form.Group>

                                      {this.state.showDeliveryCharge ? (
                                        <>
                                          {select_flat ? (
                                            <h6>
                                              Flat Delivery Charges:{" "}
                                              {this.state.flatFees > 0
                                                ? this.state.flatFees.toFixed(2)
                                                : ""}
                                            </h6>
                                          ) : null}
                                          {select_delivery &&
                                          this.state.totalDeliveryCharges >
                                            0 ? (
                                            <h6>
                                              Delivery Charges:{" "}
                                              {this.state.totalDeliveryCharges >
                                              0
                                                ? this.state.totalDeliveryCharges.toFixed(
                                                    2,
                                                  )
                                                : ""}
                                            </h6>
                                          ) : null}
                                        </>
                                      ) : null}
                                    </Form>
                                  ) : (
                                    <h6>Sorry, delivery is not available</h6>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="orderlist-radio-wrapper">
                                    <h6>
                                      Choose the pick up location and time slot
                                    </h6>
                                    {distribuationDetails.map((obj, index) => {
                                      return obj.pickup.map((item, index) => {
                                        let time = "";
                                        item.time.forEach((shiftTime, i) => {
                                          // let display = GetCloseAndOpenStatus(item.day,shiftTime);
                                          // if(display) {
                                          time =
                                            time +
                                            "," +
                                            shiftTime.starttime +
                                            "-" +
                                            shiftTime.endTime;
                                          // }
                                        });
                                        if (time != "") {
                                          return (
                                            <>
                                              <Form.Check
                                                type="radio"
                                                name="pickaddress"
                                                value={`${
                                                  obj.distributionLocation
                                                    .address
                                                } ${days[item.day]} ${time}`}
                                                onClick={
                                                  this.handleOnPickupAddress
                                                }
                                                label={`${
                                                  obj.distributionLocation
                                                    .address
                                                } ${days[item.day]} ${time}`}
                                              />
                                            </>
                                          );
                                        }
                                      });
                                    })}
                                  </div>
                                </>
                              )}

                              {selectPick ||
                              distribuationDetails.some(
                                (item) =>
                                  (item.deliveryFor == 2 && userType == 3) ||
                                  (item.deliveryFor == 1 && userType == 2) ||
                                  item.deliveryFor == 3,
                              ) ? (
                                <>
                                  {selectPick && (
                                    <>
                                      <span className="error_msg">
                                        {errors["selectPickupAddr"]}
                                      </span>
                                      <p>
                                        I understand that I am responsible for
                                        picking up my order at the location and
                                        time I selected above *
                                      </p>
                                      <Form.Check
                                        type="radio"
                                        name="agreePickpuCondition"
                                        checked={
                                          this.state.agreePick ? "checked" : ""
                                        }
                                        onClick={this.handleOnChangeYes}
                                        label={`Yes`}
                                      />
                                    </>
                                  )}
                                  <span className="error_msg mb-2">
                                    {errors["yes"]}
                                  </span>
                                  <div className="button_wrapper mt-3">
                                    <Button
                                      variant="info"
                                      type="button"
                                      onClick={() => this.addToCart(true)}
                                    >
                                      {" "}
                                      Add to Cart{" "}
                                    </Button>
                                    <Button
                                      variant="warning"
                                      type="button"
                                      onClick={() => this.addToCart()}
                                    >
                                      {" "}
                                      Buy Now{" "}
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <div className="text-center"> Out of stock </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}

          <Modal
            show={this.state.qtyMsg}
            onHide={this.RemovehandleClose}
            className="cm_confirmation imageView"
          >
            <Modal.Header closeButton>
              <Modal.Title>Only {this.state.maxQty} units left</Modal.Title>
            </Modal.Header>
          </Modal>

          <Modal
            show={this.state.minItemMsg}
            onHide={this.RemovehandleClose}
            className="cm_confirmation imageView"
          >
            <Modal.Header closeButton>
              <Modal.Title>Please select at least 1 unit </Modal.Title>
            </Modal.Header>
          </Modal>

          <Modal
            show={this.state.addrMsg}
            onHide={this.RemovehandleClose}
            className="cm_confirmation imageView"
          >
            <Modal.Header closeButton>
              <Modal.Title>Please fill valid address </Modal.Title>
            </Modal.Header>
          </Modal>
        </Container>
        <Joinourfoodmovement />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.login.isLoggedIn,
    profiledetails: state.profile,
    cartListDetails: state.cartList.cartListDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({ userProfile, getCart }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Productetails);
