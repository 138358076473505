/**
 * @About
 * This file mange environment of the project
 */

export const devSetting = {
  api: {
    url: "https://stageapi.healthyplaneat.com/healthyplaneat/api/v1/",
  },
  lat: 41.520725,
  long: -72.550078,
  google_map_key: "AIzaSyCpwCttYQuxCymySLjzyVtMpUAGJ69WSYQ",
  stripeKey: "pk_test_Mo0qGKPIsSomH8tPpJ3BexnU00cHiaB7Wi",
  url: "https://stagefarmer.healthyplaneat.com/",
  pickUpHostUrl: "https://stagehost.healthyplaneat.com/",
};

export const stagSetting = {
  api: {
    url: "https://stageapi.healthyplaneat.com/healthyplaneat/api/v1/",
  },
  lat: 41.520725,
  long: -72.550078,
  google_map_key: "AIzaSyCpwCttYQuxCymySLjzyVtMpUAGJ69WSYQ",
  stripeKey: "pk_test_RJNyJf29nk1SjkjWVwVhfxw3",
  url: "https://stagefarmer.healthyplaneat.com/",
  pickUpHostUrl: "https://stagehost.healthyplaneat.com/",
};

export const prodSetting = {
  api: {
    url: "https://api.healthyplaneat.com/healthyplaneat/api/v1/",
  },
  lat: 41.520725,
  long: -72.550078,
  google_map_key: "AIzaSyCpwCttYQuxCymySLjzyVtMpUAGJ69WSYQ",
  stripeKey: "pk_live_raBjZFf43Zd3PkymoaNEyaYy",
  url: "https://farmer.healthyplaneat.com/",
  pickUpHostUrl: "https://host.healthyplaneat.com/",
};

export const localhostSetting = {
  api: {
    url: "http://localhost:5004/healthyplaneat/api/v1/",
  },
  lat: 41.520725,
  long: -72.550078,
  google_map_key: "AIzaSyCpwCttYQuxCymySLjzyVtMpUAGJ69WSYQ",
  stripeKey: "pk_test_RJNyJf29nk1SjkjWVwVhfxw3",
  url: "http://localhost:3001/",
  pickUpHostUrl: "http://localhost:3005",
};
