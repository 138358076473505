import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { SubscriptionInfo } from "./products.jsx";
import { useSelector, useDispatch } from "react-redux";
import { parse as parseQueryString } from "query-string";
import { addToCart, updateCart } from "../redux/action/Get";
import {
  setHeaderActiveSignInAsSignUp,
  setHeaderActiveSignInAsSignInAs,
} from "../redux/action/header";
import {
  productNameForInventoryItem,
  productImageForInventoryItem,
  productPriceFromattedForInventoryItem,
  productMaxQuantityForInventoryItem,
  productPriceForInventoryItem,
  productUnitForInventoryItem,
  isSubscriptionItem,
  subscriptionParameterFields,
} from "../utils/inventory";

export const FarmProductCard = React.memo((props) => {
  const { inventoryItem, userType } = props;

  const cartListDetails = useSelector((state) => {
    return state.cartList.cartListDetails;
  });
  const debounceTimer = useRef(null);

  const dispatch = useDispatch();

  const [modalDetailsShow, setModalDetailsShow] = useState(false);
  const [modalMaximumQuantityShow, setModalMaximumQuantityShow] =
    useState(false);
  const [nonZeroProductModal, setNonZeroProductModal] = useState(false);
  const [modalMustSignInShow, setModalMustSignInShow] = useState(false);
  const [productMaxQuantity, setProductMaxQuantity] = useState(
    productMaxQuantityForInventoryItem({ inventoryItem, userType }),
  );
  const [value, setValue] = useState(1);

  const handleIncrement = () => {
    const amount = Number(value) + 1;
    if (isNaN(amount)) return;
    if (amount > productMaxQuantity) {
      setModalMaximumQuantityShow(true);
      return;
    }
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    const timer = setTimeout(() => {
      if (productInCart) {
        dispatch(
          updateCart({
            productQuantity: amount,
            productId: inventoryItem._id,
            farmId: inventoryItem.farmId,
          }),
        );
      } else {
        setValue(amount);
      }
    }, 300);

    debounceTimer.current = timer;
  };

  const handleDecrement = () => {
    const amount = Number(value) - 1;
    if (isNaN(amount)) return;
    if (amount > productMaxQuantity) {
      setModalMaximumQuantityShow(true);
      return;
    }
    if (Number(amount) <= 0) {
      setNonZeroProductModal(true);
      return;
    }

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    const timer = setTimeout(() => {
      if (productInCart) {
        dispatch(
          updateCart({
            productQuantity: amount,
            productId: inventoryItem._id,
            farmId: inventoryItem.farmId,
          }),
        );
      } else {
        setValue(amount);
      }
    }, 300);

    debounceTimer.current = timer;
  };

  const updateProductQuantityOnChange = (event) => {
    if (isNaN(event.target.value)) return;
    if (event.target.value > productMaxQuantity) {
      setModalMaximumQuantityShow(true);
      return;
    }
    setValue(event.target.value);
  };

  let currentProductListEntry = {};
  if (Array.isArray(cartListDetails.buyList)) {
    const currentProductListEntries = cartListDetails.buyList
      ?.map((buyListEntry) => {
        return buyListEntry.productList.filter((productListEntry) => {
          return productListEntry.productId === inventoryItem._id;
        });
      })
      .reduce((accumulator, nested) => {
        return accumulator.concat(nested);
      }, []);
    if (currentProductListEntries.length === 1) {
      currentProductListEntry = currentProductListEntries[0];
    }
  }
  const productInCart =
    typeof currentProductListEntry._id === "string" &&
    currentProductListEntry._id.length > 0;

  const addToCartOnClick = (event) => {
    if (Number(value) === 0) {
      setNonZeroProductModal(true);
      return;
    }
    if (!localStorage.getItem("accessToken")) {
      setModalMustSignInShow(true);
      return;
    }
    const payload = {
      productQuantity: value,
      productPrice: productPriceForInventoryItem({ inventoryItem, userType }),
      productId: inventoryItem._id,
      farmId: inventoryItem.farmId,
      unit: productUnitForInventoryItem({ inventoryItem, userType }),
      variety: inventoryItem.variety,
      distributionTypes: inventoryItem.distributionTypes,
    };
    if (isSubscriptionItem({ inventoryItem })) {
      const subscriptionParams = subscriptionParameterFields({ inventoryItem });
      Object.assign(payload, subscriptionParams);
    }
    dispatch(addToCart(payload));
  };

  /* state from query parameters */
  const queryParams = parseQueryString(window.location.search);
  let showTestProduct = false;
  // hide test products unless ?showTestProduct=true
  if (queryParams.showTestProduct) {
    showTestProduct = true;
  }
  // early null exit if we are not showing test products, and this is a test product
  if (
    productNameForInventoryItem({ inventoryItem })
      .toLowerCase()
      .startsWith("test product") &&
    showTestProduct === false
  ) {
    return null;
  }

  useEffect(() => {
    if (currentProductListEntry.productQuantity) {
      setValue(currentProductListEntry.productQuantity);
    }
  }, [currentProductListEntry]);

  return (
    <>
      <div className="hp-card-col slide hp-card-col-product">
        <div className="hp-card hp-card-product">
          <div className="hp-card-background"></div>
          {isSubscriptionItem({ inventoryItem }) ? (
            <h1 class="subscription-banner">Subscription</h1>
          ) : null}
          <div
            className="hp-card-header"
            style={{
              backgroundImage: `url(${productImageForInventoryItem({
                inventoryItem,
              })})`,
            }}
            onClick={() => setModalDetailsShow(true)}
          ></div>
          <div className="hp-card-body">
            <hgroup className="hp-card-heading-group">
              <h3 className="farmName-product-card">
                {inventoryItem.farmName}
              </h3>
              <h2 className="hp-card-heading hp-card-product-name">
                {productNameForInventoryItem({ inventoryItem })}
              </h2>
              <h3 className="hp-card-subheading hp-card-product-variety">
                {inventoryItem.variety}
              </h3>
            </hgroup>
            <div className="hp-card-body-details">
              <div className="row align-items-center hp-card-product-details-row">
                <div className="col-7">
                  <p className="product-details-price">
                    {productPriceFromattedForInventoryItem({
                      inventoryItem,
                      userType,
                    })}
                  </p>
                </div>
              </div>
              <div className="input-product-container">
                {productMaxQuantity > 0 && (
                  <div className="number-input-container">
                    <button
                      type="button"
                      className="minus"
                      onClick={handleDecrement}
                    ></button>
                    <input
                      name={`qty_${inventoryItem._id}`}
                      max={productMaxQuantity}
                      placeholder="1"
                      value={value}
                      className="number-input"
                      onChange={(event) => {
                        updateProductQuantityOnChange(event);
                      }}
                    />
                    <button
                      type="button"
                      className="plus"
                      onClick={handleIncrement}
                    >
                      +
                    </button>
                  </div>
                )}
              </div>
              <div className="row hidden">
                <div className="col-6">
                  <h6 className="hp-card-label"></h6>
                </div>
                <div className="col-6">
                  <h6 className="hp-card-label righted">Quantity</h6>
                </div>
              </div>
            </div>
            <SubscriptionInfo inventoryItem={inventoryItem} />
            {productMaxQuantity < 1 && (
              <h4 className="hp-card-product-out-of-stock">Out of stock</h4>
            )}
          </div>
          <div className="hp-card-link floating">
            {productMaxQuantity >= 1 && (
              <Button
                variant="info"
                disabled={productInCart}
                onClick={addToCartOnClick}
              >
                {productInCart ? "Added to cart" : "Add to cart"}
              </Button>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={modalDetailsShow}
        onHide={() => setModalDetailsShow(false)}
        className="cm_confirmation imageView"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={productImageForInventoryItem({ inventoryItem })}
              alt=" "
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="product-name">
            {productNameForInventoryItem({ inventoryItem })}
          </h6>

          <h6 className="product-variety">{inventoryItem.variety}</h6>
          <div className="price">
            Price{" "}
            {productPriceFromattedForInventoryItem({ inventoryItem, userType })}
          </div>

          <p>{inventoryItem.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalDetailsShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalMaximumQuantityShow}
        onHide={() => setModalMaximumQuantityShow(false)}
        className="cm_confirmation imageView"
      >
        <Modal.Header closeButton>
          <Modal.Title>Only {productMaxQuantity} units left</Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        show={nonZeroProductModal}
        onHide={() => setNonZeroProductModal(false)}
        className="cm_confirmation imageView"
      >
        <Modal.Header closeButton>
          <Modal.Title>Cannot add 0 units to your cart</Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        show={modalMustSignInShow}
        onHide={() => setModalMustSignInShow(false)}
        className="cm_confirmation imageView"
      >
        <Modal.Header closeButton>
          <Modal.Title>You are not Signed In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            Please{" "}
            <span
              className="link"
              onClick={() => {
                setModalMustSignInShow(false);
                dispatch(setHeaderActiveSignInAsSignInAs());
              }}
            >
              {" "}
              Sign In
            </span>{" "}
            to your account.
            <div>
              Don't have an account?{" "}
              <span
                className="link"
                onClick={() => {
                  setModalMustSignInShow(false);
                  dispatch(setHeaderActiveSignInAsSignUp());
                }}
              >
                {" "}
                Sign Up{" "}
              </span>
            </div>
          </h6>
        </Modal.Body>
      </Modal>
    </>
  );
});
