import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Url from "../services/BaseUrl";
import { _Api } from "../services/Api";
import { isEmpty } from "lodash";

import Slider from "react-slick";
import { notify } from "./common/Tooster.js";
import { PickupCard } from "./PickupCard";

const debug = require("debug")("PickupCard");
const queryString = require("query-string");

const MARKET_STATUS = {
  ACTIVE: 0,
  INACTIVE: 1,
};

export const PickupCards = ({ search, sliderProps }) => {
  const [pickups, setPickups] = useState([]);

  const useSlider = !isEmpty(sliderProps);

  useEffect(() => {
    const queryParams = {
      status: MARKET_STATUS.ACTIVE,
      search,
    };
    _Api(
      Url.USER_GET_MARKET_POPUP_DETAILS.method,
      Url.USER_GET_MARKET_POPUP_DETAILS.url,
      "",
      `?${queryString.stringify(queryParams)}`,
    )
      .then((resp) => {
        setPickups(resp.responseData.marketInfo);
        // this.setState({
        //   isLoader: false,
        // });
      })
      .catch((err) => {
        // this.setState({ isLoader: false });
        if (err.error) notify("error", err.error.responseMessage);
      });
  }, [search]);

  if (useSlider && pickups.length > 3) {
    return (
      <Slider {...sliderProps}>
        {pickups.map((pickup) => {
          if (
            Array.isArray(pickup.marketPickupWindows) &&
            pickup.marketPickupWindows.length > 0
          ) {
            const cardProps = {
              pickup,
            };
            return <PickupCard {...cardProps} />;
          }
        })}
      </Slider>
    );
  }
  if (useSlider && pickups.length === 0) return null;

  return (
    <div className="cards-container">
      {pickups.map((pickup) => {
        const cardProps = {
          pickup,
        };
        return <PickupCard {...cardProps} />;
      })}
    </div>
  );
};
