const debug = require("debug")("util-farm");

export const addNumberAvailableProducts = ({ farms, inventory }) => {
  return farms
    .map((farm) => {
      const userId = farm.userId;
      const productsAvailable = inventory.filter((inventoryItem) => {
        return inventoryItem.userId === userId;
      });
      farm.numberAvailableProducts = productsAvailable.length;
      return farm;
    })
    .filter((farm) => {
      return farm.numberAvailableProducts > 0;
    });
};

export const groupFarmsWithInventory = ({ farms, inventory }) => {
  return farms
    .map((farm) => {
      const userId = farm.userId;
      const productsAvailable = inventory.filter((inventoryItem) => {
        return inventoryItem.userId === userId;
      });
      farm.numberAvailableProducts = productsAvailable.length;
      farm.inventory = productsAvailable;
      return farm;
    })
    .filter((farm) => {
      return farm.numberAvailableProducts > 0;
    });
};
