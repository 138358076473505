const debug = require("debug")("util-user");

export const USER_TYPE = {
  FARMER: 1,
  CUSTOMER: 2,
  WHOLESALER: 3,
  PICKUPHOST: 5,
};

const USER_TYPE_STRING = {
  FARMER: "Farmer",
  CUSTOMER: "Customer",
  WHOLESALER: "Wholesaler",
};

export const CONSUMER_TYPE = {
  UNIVERSITY: 1,
  SCHOOL: 2,
  RESTAURANT: 3,
  OTHER: 4,
};

export const userTypeStringFromInt = ({ userType }) => {
  debug("userTypeStringFromInt:userType:", userType);
  if (userType === USER_TYPE.FARMER) return USER_TYPE_STRING.FARMER;
  if (userType === USER_TYPE.CUSTOMER) return USER_TYPE_STRING.CUSTOMER;
  if (userType === USER_TYPE.WHOLESALER) return USER_TYPE_STRING.WHOLESALER;
  return "";
};

export const getUserType = () => {
  return localStorage.getItem("userType")
    ? parseInt(localStorage.getItem("userType"))
    : USER_TYPE.CUSTOMER;
};

export const setLocalStorageUserLogin = ({ accessToken, userType }) => {
  if (accessToken) localStorage.setItem("accessToken", accessToken);
  if (userType) localStorage.setItem("userType", userType);
  localStorage.setItem("isLoggedIn", "true");
};

export const setLocalStorargeUserLogout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("userType");
};
