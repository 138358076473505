import React, { Component } from "react";
import OrderNow from "./components/OrderNow";

class ShippedProducts extends Component {
  render() {
    return (
      <div className="body_container_wrapper home">
        <OrderNow />
      </div>
    );
  }
}

export default ShippedProducts;
