import { Col, Container, Form } from "react-bootstrap";
import React, { Component } from "react";

import ReactPhoneInput from "react-phone-input-2";
import Url from "../../services/BaseUrl";
import _ from "lodash";
import { _Api } from "../../services/Api";
import { isValidEmail } from "../../utils/Validation";
import { notify } from "../../components/common/Tooster";

export class BecomeaFarmer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      farmName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      certifications: "",
      questions: "",
      errors: [],
      responseError: "",
    };
  }
  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    });
  };

  handleValidate = () => {
    let {
      firstName,
      lastName,
      email,
      phone,
      address,
      address2,
      city,
      state,
      postalCode,
      country,
      questions,
      certifications,
    } = this.state;
    let validate = false;
    let errors = {};

    if (_.isEmpty(firstName)) {
      validate = true;
      errors["firstName"] = "First name is required.";
    }

    if (_.isEmpty(lastName)) {
      validate = true;
      errors["lastName"] = "Last Name is required.";
    }

    if (_.isEmpty(email)) {
      validate = true;
      errors["email"] = "Email is required.";
    } else if (!isValidEmail(email)) {
      validate = true;
      errors["email"] = "Enter vaild email";
    }

    if (_.isEmpty(phone)) {
      validate = true;
      errors["phone"] = "Phone number is required.";
    }

    if (_.isEmpty(address)) {
      validate = true;
      errors["address"] = "Address 1 is required.";
    }

    if (_.isEmpty(address2)) {
      validate = true;
      errors["address2"] = "Address 2  is required.";
    }

    if (_.isEmpty(city)) {
      validate = true;
      errors["city"] = "City  is required.";
    }

    if (_.isEmpty(state)) {
      validate = true;
      errors["state"] = "State  is required.";
    }

    if (_.isEmpty(postalCode)) {
      validate = true;
      errors["postalCode"] = "Postal code  is required.";
    }

    if (_.isEmpty(country)) {
      validate = true;
      errors["country"] = "Country is required.";
    }

    if (_.isEmpty(questions)) {
      validate = true;
      errors["questions"] = "Questions is required.";
    }

    if (_.isEmpty(certifications)) {
      validate = true;
      errors["certifications"] = "Certifications is required.";
    }

    this.setState({ errors });
    return validate;
  };

  handleOnFarmerSubmit = (e) => {
    this.handleValidate();
    if (!this.handleValidate()) {
      let {
        firstName,
        lastName,
        email,
        phone,
        address,
        address2,
        city,
        state,
        postalCode,
        questions,
        certifications,
      } = this.state;

      let req = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        addressLine1: address,
        addressLine2: address2,
        city: city,
        state: state,
        postalCode: postalCode,
        questions: questions,
        certifications: certifications,
      };
      _Api(Url.FARMER_SIGNUP.method, Url.FARMER_SIGNUP.url, req)
        .then((resp) => {
          notify("success", resp.responseData.message);
          this.props.history.goBack();
          alert(
            "you have successfully your account, admin will shorty verify your account.",
          );
        })
        .catch((err) => notify("error", err.error.responseMessage));
    }
  };
  render() {
    let {
      errors,
      firstName,
      lastName,
      email,
      phone,
      address,
      address2,
      city,
      state,
      postalCode,
      country,
      questions,
      certifications,
    } = this.state;

    return (
      <div className="body_container_wrapper home">
        <Container>
          <Form className="cm_becomefarmer">
            <Form.Row>
              <legend>
                Your Name <sup>*</sup>
              </legend>
              <Form.Group as={Col}>
                <input
                  className="form-control"
                  spellcheck="false"
                  maxlength={50}
                  type="text"
                  placeholder="First Name"
                  data-title="First"
                  required
                  name="firstName"
                  value={firstName}
                  onChange={this.handleOnChange}
                />
                <span>First Name</span> &nbsp;
                <span className="error_msg">{errors["firstName"]}</span>
              </Form.Group>
              <Form.Group as={Col}>
                <input
                  className="form-control"
                  spellcheck="false"
                  type="text"
                  placeholder="Last Name"
                  data-title="Last"
                  required
                  maxlength={50}
                  name="lastName"
                  value={lastName}
                  onChange={this.handleOnChange}
                />
                <span>Last Name</span> &nbsp;
                <span className="error_msg">{errors["lastName"]}</span>
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <label>
                Email <sup>*</sup>
              </label>
              <input
                className="form-control"
                spellcheck="false"
                autocomplete="email"
                placeholder="email"
                type="email"
                required
                maxlength={50}
                name="email"
                value={email}
                onChange={this.handleOnChange}
              />
              <span className="error_msg">{errors["email"]}</span>
            </Form.Group>
            <Form.Row>
              <legend>
                Phone <sup>*</sup>
              </legend>
              <Form.Group as={Col}>
                {/* <input className="form-control"
                                 type="text" 
                                 data-title="Areacode"
                                  required 
                                  maxlength={50}
                                     name="phone"
                                     value={phone}
                                    onChange={this.handleOnChange}
                                  />  */}
                <ReactPhoneInput
                  value={phone}
                  // placeholder="Please enter phone number"
                  onChange={(phone) => this.setState({ phone: phone })}
                />
                <span>Phone Number</span> &nbsp;
                <span className="error_msg">{errors["phone"]}</span>
              </Form.Group>
              <Form.Group as={Col}>
                {/* <input className="form-control"
                                 type="text"
                                  data-title="Prefix"
                                   required
                                   maxlength={50}
                                     name="lastName"
                                     value={lastName}
                                    onChange={this.handleOnChange}
                                   /> */}
                {/* <ReactPhoneInput
                                        value={phone}
                                        // placeholder="Please enter phone number"
                                        onChange={(phone) => this.setState({phone: phone})}
                                    />
                                    <span>###</span>  */}
              </Form.Group>
              {/* <Form.Group as={Col}>
                                <input className="form-control" maxlength="4" type="text" data-title="Line" required/> <span>####</span> 
                        </Form.Group> */}
            </Form.Row>
            <Form.Group>
              <legend>
                Address <sup>*</sup>
              </legend>
              <Form.Group>
                <input
                  className="form-control"
                  spellcheck="false"
                  type="text"
                  data-title="Line1"
                  required
                  placeholder="Address 1"
                  maxlength={156}
                  name="address"
                  value={address}
                  onChange={this.handleOnChange}
                />
                <span>Address 1</span> &nbsp;
                <span className="error_msg">{errors["address"]}</span>
              </Form.Group>
              <Form.Group>
                <input
                  className="form-control"
                  spellcheck="false"
                  name="address2"
                  type="text"
                  placeholder="Address 2"
                  data-title="Line2"
                  maxlength={156}
                  name="address2"
                  value={address2}
                  onChange={this.handleOnChange}
                />
                <span>Address 2</span> &nbsp;
                <span className="error_msg">{errors["address2"]}</span>
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col}>
                  <input
                    className="form-control"
                    spellcheck="false"
                    name="city"
                    type="text"
                    placeholder="City"
                    data-title="City"
                    required
                    maxlength={50}
                    name="city"
                    value={city}
                    onChange={this.handleOnChange}
                  />
                  <span>City</span> &nbsp;
                  <span className="error_msg">{errors["city"]}</span>
                </Form.Group>
                <Form.Group as={Col}>
                  <input
                    className="form-control"
                    spellcheck="false"
                    name="state"
                    type="text"
                    data-title="State"
                    placeholder="State"
                    required
                    maxlength={50}
                    name="state"
                    value={state}
                    onChange={this.handleOnChange}
                  />
                  <span>State/Province</span> &nbsp;
                  <span className="error_msg">{errors["state"]}</span>
                </Form.Group>
                <Form.Group as={Col}>
                  <input
                    className="form-control"
                    spellcheck="false"
                    type="text"
                    data-title="Zip"
                    placeholder="Zip"
                    required
                    maxlength={10}
                    name="postalCode"
                    value={postalCode}
                    onChange={this.handleOnChange}
                  />
                  <span>Zip/Postal Code</span> &nbsp;
                  <span className="error_msg">{errors["postalCode"]}</span>
                </Form.Group>
                <Form.Group as={Col}>
                  <input
                    className="form-control"
                    spellcheck="false"
                    type="text"
                    data-title="Country"
                    placeholder="Country"
                    required
                    maxlength={50}
                    name="country"
                    value={country}
                    onChange={this.handleOnChange}
                  />
                  <span>Country</span> &nbsp;
                  <span className="error_msg">{errors["country"]}</span>
                </Form.Group>
              </Form.Row>
            </Form.Group>
            <Form.Group>
              <label>
                Certifications / Pledges <sup>*</sup>
              </label>
              <textarea
                className="form-control"
                rows="5"
                required
                placeholder="Certifications / Pledges"
                maxlength={500}
                name="certifications"
                value={certifications}
                onChange={this.handleOnChange}
              ></textarea>
              <span className="error_msg">{errors["certifications"]}</span>
            </Form.Group>
            <Form.Group>
              <label>Questions / Comments</label>
              <textarea
                className="form-control"
                rows="5"
                maxlength={500}
                placeholder="Questions / Comments"
                name="questions"
                value={questions}
                onChange={this.handleOnChange}
              ></textarea>
            </Form.Group>
            <div className="becomefarmer_btn">
              <input
                class="btn btn-outline-primary btn-lg"
                type="button"
                onClick={this.handleOnFarmerSubmit}
                value="Submit"
              ></input>
              <input
                class="btn btn-secondary btn-lg"
                type="button"
                value="Cancel"
              ></input>
            </div>
          </Form>
        </Container>
      </div>
    );
  }
}

export default BecomeaFarmer;
