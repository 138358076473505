// TODO add the ability to sign up as a Pick Up Host
import debugFactory from "debug";
import { Button, Form, Modal } from "react-bootstrap";
import React, { Component } from "react";

import Geocode from "react-geocode";
import { Select } from "antd";
import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCart } from "../../redux/action/Get";
import { history } from "../../Routes";
import { isValidEmail } from "../../utils/Validation";
import { setLocalStorageUserLogin, USER_TYPE } from "../../utils/user";
import { login } from "../../redux/action/Auth";
import { notify } from "../common/Tooster";
import siteSetting from "../../configs/env/Index";
import { userProfile } from "../../redux/action/Get";
import {
  SIGN_IN_MODAL_STATES,
  setHeaderActiveSignInModal,
} from "../../redux/action/header";
import { isEmpty } from "lodash";

const debug = debugFactory("sign-up");

const { Option } = Select;

const USER_TYPE_SCHOOL = "4";
const USER_TYPE_WHOLESALE_BUYER = "3";
const CONSUMER_TYPE_SCHOOL = "2";

const customerType = {
  1: "Farmer",
  2: "Consumer",
  3: "Whole Saler",
  4: "School",
};

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      userType: "",
      lat: "",
      long: "",
      confirmPassword: "",
      errors: [],
      responseError: "",
      value: "",
      address: "",
      area: "",
      city: "",
      state: "",
      postalCode: "",
      certificateImage: "",
      userDetails: {
        firstName: "",
        lastName: "",
      },
      certifiedOrganic: "",
      nofa: "",
      other: "",
      termAndCondition: "",
      checked: false,
      consumerType: "",
      isScrolledPast: false,
      isSchool: false,
    };
  }

  handleValidate = () => {
    debug("validate");
    let validate = false;
    let errors = {};
    let {
      lastName,
      firstName,
      email,
      checked,
      address,
      postalCode,
      confirmPassword,
      city,
      state,
      password,
      userType,
      consumerType,
    } = this.state;

    if (isEmpty(firstName)) {
      validate = true;
      errors["firstName"] = "First name is required.";
    }

    if (isEmpty(lastName)) {
      validate = true;
      errors["lastName"] = "Last name is required.";
    }

    if (checked === false) {
      validate = true;
      errors["checked"] = "Please select term and conditions.";
    }

    if (isEmpty(email)) {
      validate = true;
      errors["email"] = "Email is required.";
    } else if (!isValidEmail(email)) {
      validate = true;
      errors["email"] = "Enter a valid email.";
    }

    if (isEmpty(password)) {
      validate = true;
      errors["password"] = "Password is required.";
    } else if (password.length <= 4) {
      validate = true;
      errors["password"] = "Password must be greater than 4 digits.";
    }

    if (isEmpty(confirmPassword)) {
      validate = true;
      errors["confirmPassword"] = "Confirm password is required.";
    }

    if (password !== confirmPassword) {
      validate = true;
      errors["password"] = "Password and confirm password must be same.";
    }

    debug("userType");
    debug(userType);
    if (!userType) {
      validate = true;
      debug("user type invalid");
      errors["userType"] = "User type is required.";
    } else if (isEmpty(userType) && isEmpty(consumerType)) {
      validate = true;
      errors["consumerType"] = "Select whole sale type is required.";
    }

    if (isEmpty(address)) {
      validate = true;
      errors["address"] = "Address line 1 is required.";
    }

    if (isEmpty(address)) {
      validate = state;
      errors["state"] = "State is required.";
    }

    if (isEmpty(city)) {
      validate = true;
      errors["city"] = "City is required.";
    }

    if (isEmpty(postalCode)) {
      validate = true;
      errors["postalCode"] = "Zip code is required.";
    }

    this.setState({ errors });

    return validate;
  };

  //Sign up
  handleSignUpSubmit = (e) => {
    const debugSubmit = debug.extend("submit");
    let {
      lastName,
      firstName,
      email,
      long,
      lat,
      address,
      certifiedOrganic,
      nofa,
      other,
      certificateImage,
      area,
      city,
      state,
      password,
      userType,
      postalCode,
      consumerType,
      isSchool,
    } = this.state;

    debugSubmit("start-validate");
    if (this.handleValidate === true) return;
    debugSubmit("validate-passed");

    const finalUserType =
      userType === USER_TYPE_SCHOOL ? USER_TYPE_WHOLESALE_BUYER : userType;
    const finalConsumerType = isSchool ? CONSUMER_TYPE_SCHOOL : consumerType;

    debugSubmit("finalUserType:", finalUserType);
    debugSubmit("finalConsumerType:", finalConsumerType);

    let formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("lat", lat ? lat : 0);
    formData.append("long", long ? long : 0);
    formData.append("userType", finalUserType);
    formData.append(
      "consumerType",
      finalConsumerType === undefined ? "" : finalConsumerType,
    );
    // formData.append("deviceID", "erty");
    // formData.append("deviceToken", "qwert");
    // formData.append("deviceTypeID", "3");
    formData.append("addressLine1", address);
    formData.append("addressLine2", area);
    formData.append("state", state);
    formData.append("postalCode", postalCode);
    formData.append("city", city);
    formData.append("password", password);
    formData.append("certificateImage", certificateImage);
    formData.append(
      "certifiedOrganic",
      certifiedOrganic === "" ? 0 : certifiedOrganic,
    );
    formData.append("nofa", nofa === "" ? 0 : nofa);
    formData.append("other", other === "" ? 0 : other);

    debugSubmit("make-request");
    _Api(Url.USER_SIGNUP.method, Url.USER_SIGNUP.url, formData)
      .then((resp) => {
        if (resp.responseData.userProfile.userType === USER_TYPE.FARMER) {
          this.props.handleCloseModal();
          notify("success", resp.responseData.message);

          history.push({
            pathname: siteSetting.url,
            state: {
              accessToken: resp.responseData.accessToken,
            },
          });
          window.location.href = siteSetting.url;
        } else if (
          resp.responseData.userProfile.userType === USER_TYPE.PICKUPHOST
        ) {
          this.props.handleCloseModal();
          notify("success", resp.responseData.message);

          history.push({
            pathname: siteSetting.pickUpHostUrl,
            state: {
              accessToken: resp.responseData.accessToken,
            },
          });
          window.location.href = siteSetting.pickUpHostUrl;
        } else {
          setLocalStorageUserLogin({
            accessToken: resp.responseData.accessToken,
            userType: resp.responseData.userProfile.userType,
          });
          this.props.action.userProfile();
          notify("success", resp.responseData.message);
          this.props.handleCloseModal();
        }
      })
      .catch((err) => {
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  //Select Consumber type
  handleOnChangeSelectSubType = (e) => {
    this.setState({ consumerType: e, errors: [], responseError: "" });
  };

  //select user Type
  handleOnChangeSelect = (e) => {
    debug("handleOnChangeSelect");
    debug(e);
    // Reset the state when a new user type is selected
    this.state.consumerType = "";
    this.state.isSchool = false;
    if (e === "SCHOOL") {
      this.state.isSchool = true;
      e = USER_TYPE.WHOLESALER;
    } else if (e === "WHOLESALER") {
      e = USER_TYPE.WHOLESALER;
    } else if (e === "CUSTOMER") {
      e = USER_TYPE.CUSTOMER;
    } else if (e === "FARMER") {
      e = USER_TYPE.FARMER;
    } else if (e === "PICKUPHOST") {
      e = USER_TYPE.PICKUPHOST;
    }
    this.setState({ userType: e, errors: [], responseError: "" });
  };

  handleChanged = (e) => {
    let self = this;
    if (e.target.name === "address") {
      Geocode.fromAddress(e.target.value).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          self.setState({ lat: lat, long: lng });
        },
        (error) => {
          console.error(error);
        },
      );
    }
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    });
  };

  handleChangedChecked = (e) => {
    this.setState({ checked: !this.state.checked });
  };

  handlePicUpload = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    let { valiDate } = this.state;

    reader.addEventListener(
      "load",
      () => {
        this.setState({ profilePicPreview: reader.result }, function () {});
      },
      false,
    );
    if (file) {
      reader.readAsDataURL(file);
    }
    if (
      file.type === "image/jpeg" ||
      file.type === "image/gif" ||
      file.type === "image/png" ||
      file.type === "image/svg+xml" ||
      file.type === "application/pdf"
    ) {
      this.setState({
        certificateImage: event.target.files[0],
        profilePicErrorMsg: "",
      });
    } else {
      valiDate = false;
      this.setState({ profilePicErrorMsg: "File type not supported." });
    }
  };

  handleChangeCheckBox = (e) => {
    this.setState({
      certifiedOrganic: e.target.value,
      error: [],
      responseError: "",
    });
  };

  handleChangeCheckBox1 = (e) => {
    this.setState({ nofa: e.target.value });
  };

  handleChangeCheckBox2 = (e) => {
    this.setState({ other: e.target.value });
  };

  handleChangeCheckBoxTermsCodition = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    });
  };

  render() {
    let {
      firstName,
      lastName,
      email,
      postalCode,
      profilePicPreview,
      password,
      errors,
      address,
      area,
      state,
      city,
      confirmPassword,
    } = this.state;

    return (
      <div className="confirmation_popup">
        <Modal
          show={this.props.showModalSignUp}
          onHide={() => this.props.handleCloseModal()}
          className="cm_confirmation login"
        >
          <Modal.Header closeButton> </Modal.Header>
          <Modal.Body>
            <div className="two_col login signup">
              <div>
                <div className="login_bg">
                  <h2>Create Account</h2>
                </div>
              </div>
              <div>
                <div className="login_content">
                  <h5>Sign Up</h5>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    onChange={this.handleChanged}
                    name="firstName"
                    value={firstName}
                    placeholder="First Name"
                  />
                  <span className="error_msg">{errors["firstName"]}</span>
                  <Form.Control
                    type="lastName"
                    autoComplete="off"
                    onChange={this.handleChanged}
                    name="lastName"
                    value={lastName}
                    placeholder="Last Name"
                  />
                  <span className="error_msg">{errors["lastName"]}</span>
                  <Form.Control
                    type="email"
                    autoComplete="off"
                    onChange={this.handleChanged}
                    name="email"
                    value={email}
                    placeholder="Email Address"
                  />
                  <span className="error_msg">{errors["email"]}</span>
                  <Select
                    name="userType"
                    placeholder="Select User Type"
                    onChange={this.handleOnChangeSelect}
                    className="form-control text-left"
                  >
                    <Option value={"CUSTOMER"}>Local Customer</Option>
                    <Option value={"SCHOOL"}>School</Option>
                    <Option value={"WHOLESALER"}>Wholesale Buyer</Option>
                    <Option value={"PICKUPHOST"}>Pick Up Host</Option>
                    <Option value={"FARMER"}>Farmer</Option>
                  </Select>

                  <span className="error_msg">{errors["userType"]}</span>
                  {this.state.userType == USER_TYPE.FARMER && (
                    <Form.Group controlId="formBasicCheckbox">
                      <div className="signupradio">
                        <Form.Check
                          type="checkbox"
                          id="USDA Certified Organic"
                          value="1"
                          onChange={this.handleChangeCheckBox}
                          label="USDA Certified Organic"
                        />
                        <Form.Check
                          type="checkbox"
                          id="NOFA"
                          value="1"
                          label="NOFA"
                          onChange={this.handleChangeCheckBox1}
                        />
                        <Form.Check
                          type="checkbox"
                          id="Other"
                          value="1"
                          label="Other"
                          onChange={this.handleChangeCheckBox2}
                        />
                      </div>
                    </Form.Group>
                  )}

                  <Form.Group>
                    {(customerType[this.state.userType] === "Whole Saler" ||
                      customerType[this.state.userType] === "School") && (
                      <>
                        {customerType[this.state.userType] === "Whole Saler" &&
                          !this.state.isSchool && (
                            <>
                              <Select
                                // name="userType"
                                // value={userType}
                                placeholder="Select Wholesale Type"
                                onChange={this.handleOnChangeSelectSubType}
                                className="form-control text-left"
                              >
                                <Option value="1">University</Option>
                                <Option value="3">Restaurant</Option>
                                <Option value="4">Other</Option>
                              </Select>
                              <span className="error_msg">
                                {errors["consumerType"]}
                              </span>
                            </>
                          )}

                        <div className="file-uploader">
                          <div className="file-uploader-plus">
                            {this.state.certificateImage?.type ===
                            "application/pdf" ? (
                              <img
                                alt="certificate preview"
                                src={require("../../assets/img/pdf.png")}
                                className="documentpreview"
                              />
                            ) : profilePicPreview ? (
                              <img
                                alt="profile pic preview"
                                src={profilePicPreview}
                                className="documentpreview"
                              />
                            ) : (
                              <>
                                <i class="fas fa-plus"></i>
                                <span>Upload EIN Documents</span>
                              </>
                            )}
                          </div>
                          <Form.Group>
                            <input
                              type="file"
                              accept="image/x-png,image/gif,image/jpeg,image/jpg"
                              onChange={this.handlePicUpload}
                            />
                          </Form.Group>
                        </div>
                        <span className="error_msg">
                          {errors["certificateImage"]}
                        </span>
                      </>
                    )}
                  </Form.Group>

                  <Form.Control
                    autoComplete="off"
                    onChange={this.handleChanged}
                    type="password"
                    name="password"
                    value={password}
                    placeholder="Password"
                  />
                  <span className="error_msg">{errors["password"]}</span>
                  <Form.Control
                    autoComplete="off"
                    onChange={this.handleChanged}
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    placeholder="Confirm Password"
                  />
                  <span className="error_msg">{errors["confirmPassword"]}</span>

                  <Form.Control
                    type="text"
                    placeholder="Enter Address Line 1..."
                    maxLength={100}
                    autoComplete="off"
                    name="address"
                    value={address}
                    onChange={this.handleChanged}
                  />
                  <span className="error_msg">{errors["address"]}</span>

                  <Form.Control
                    type="text"
                    placeholder="Enter Address Line 2..."
                    maxLength={100}
                    autoComplete="off"
                    name="area"
                    value={area}
                    onChange={this.handleChanged}
                  />
                  <span className="error_msg">{errors["area"]}</span>

                  <Form.Control
                    type="text"
                    placeholder="Enter city..."
                    maxLength={100}
                    autoComplete="off"
                    name="city"
                    value={city}
                    onChange={this.handleChanged}
                  />
                  <span className="error_msg">{errors["city"]}</span>

                  <Form.Control
                    type="text"
                    placeholder="Enter state..."
                    maxLength={30}
                    autoComplete="off"
                    name="state"
                    value={state}
                    onChange={this.handleChanged}
                  />
                  <span className="error_msg">{errors["state"]}</span>
                  <Form.Control
                    type="number"
                    placeholder="Enter Zip code..."
                    maxLength={30}
                    autoComplete="off"
                    name="postalCode"
                    value={postalCode}
                    onChange={this.handleChanged}
                  />
                  <span className="error_msg">{errors["postalCode"]}</span>

                  <label className="terms-ncondition">
                    <Form.Check
                      type="checkbox"
                      defaultChecked={this.state.checked}
                      label=""
                      name="address"
                      id="selectPick"
                      onChange={this.handleChangedChecked}
                    />
                    I agree to the
                    <a href="/termsofuse" target="_blank">
                      {" "}
                      term and conditions.
                    </a>
                    <span className="error_msg">{errors["checked"]}</span>
                  </label>
                  <Button
                    variant="dark"
                    disabled={this.state.disable}
                    onClick={this.handleSignUpSubmit}
                  >
                    Sign Up
                  </Button>
                  <p className="create_ac">
                    <small>
                      Already have an account?{" "}
                      <span
                        onClick={() => this.props.handleShowModalSignInAs()}
                      >
                        Sign In
                      </span>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.login.isLoggedIn,
    profiledetails: state.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      { login, userProfile, getCart, setHeaderActiveSignInModal },
      dispatch,
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
