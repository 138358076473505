import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="page_container">
      <div class="main">
        <div class="error sectionmargin">
          <div id="outline">
            <div id="errorboxoutline">
              <div class="error-code">
                <span class="first">4</span>
                <span class="">0</span>
                <span class="last">4</span>{" "}
              </div>
              <div class="wrap-text">
                <div class="error-message">
                  <h2>Article not found</h2>
                </div>
                <div id="errorboxbody">
                  <p>Please try one of the following pages:</p>
                </div>
                <Link
                  class="button-home"
                  exact
                  to="/"
                  title="Go to the Home Page"
                >
                  Home Page
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
