import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import React, { Component } from "react";

import Loader from "../../components/common/Loader";
import Url from "../../services/BaseUrl";
import _ from "lodash";
import { _Api } from "../../services/Api";
import { isValidEmail } from "../../utils/Validation";
import { notify } from "../common/Tooster";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      email: "",
      name: "",
      zipCode: "",
      errors: [],
      responseError: "",
    };
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    });
  };

  handleValidate = () => {
    let validate = false;
    let errors = {};
    let { email, zipCode, name } = this.state;
    if (_.isEmpty(name)) {
      validate = true;
      errors["name"] = "Name is requird.";
    }
    if (_.isEmpty(zipCode)) {
      validate = true;
      errors["zipCode"] = "Zip code is requird.";
    }
    if (_.isEmpty(email)) {
      validate = true;
      errors["email"] = "email is required.";
    } else if (!isValidEmail(email)) {
      validate = true;
      errors["email"] = "Enter a valid email.";
    }
    this.setState({ errors });
    return validate;
  };

  handleOnClickSubmit = () => {
    let self = this;
    if (!this.handleValidate()) {
      let { email, name, zipCode } = this.state;
      let req = {
        name: name,
        email: email,
        zipCode: zipCode,
      };
      self.setState({ isLoader: true });
      _Api(
        Url.USER_NEWS_LETTER_SIGNUP.method,
        Url.USER_NEWS_LETTER_SIGNUP.url,
        req,
      )
        .then((resp) => {
          self.setState({ isLoader: false, email: "", name: "", zipCode: "" });
          notify("success", resp.responseData.message);
        })
        .catch((err) => {
          self.setState({ isLoader: false });
          notify("error", err.error.responseMessage);
        });
    }
  };

  render() {
    let { email, name, zipCode, errors } = this.state;
    return (
      <footer className="footer-wrapper">
        {this.state.isLoader && <Loader />}
        <Container>
          <Row>
            <Col md="4">
              <div className="sign_up">
                <h5>Sign Up For Our Newsletter</h5>
                <ButtonGroup
                  aria-label="Basic example"
                  className="sighup_wrapper"
                >
                  <div>
                    <Form.Control
                      type="text"
                      onChange={this.handleOnChange}
                      name="name"
                      value={this.state.name}
                      placeholder="Name"
                    />
                    <span className="error_msg">{errors["name"]}</span>
                  </div>
                  <div>
                    <Form.Control
                      type="number"
                      onChange={this.handleOnChange}
                      name="zipCode"
                      value={this.state.zipCode}
                      placeholder=" Zip Code"
                    />

                    <span className="error_msg">{errors["zipCode"]}</span>
                  </div>
                </ButtonGroup>
                <Form.Control
                  type="email"
                  onChange={this.handleOnChange}
                  name="email"
                  className="signup_input"
                  value={this.state.email}
                  placeholder="Enter Your Email"
                />
                <span className="error_msg">{errors["email"]}</span>
                <Button variant="light" onClick={this.handleOnClickSubmit}>
                  Sign Up
                </Button>
              </div>
            </Col>
            <Col md="4">
              <div className="contact_us">
                <h5>Contact Us</h5>
                <h5>
                  <a href="mailto:info@healthyplaneat.com">
                    info@healthyplaneat.com
                  </a>
                </h5>
              </div>
            </Col>
            <Col md="4">
              <div className="social_icon">
                <h6>Follow Us</h6>
                <ul>
                  <li>
                    <a href="https://twitter.com/healthyplaneat">
                      <img
                        src={require("../../assets/img/twitter.png")}
                        alt="twitter"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/healthyplaneat/">
                      <img
                        src={require("../../assets/img/fb.png")}
                        alt="facebook"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/healthyplaneat/">
                      <img
                        src={require("../../assets/img/insta.png")}
                        alt="instagram"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <p className="mb-0">
            <small>
              © 2020 Sustainable Planeat LLC{" "}
              <a href="/termsofuse" target="_blank">
                Terms of Use
              </a>{" "}
              |{" "}
              <a href="/privacypolicy" target="_blank">
                Privacy Policy
              </a>
            </small>
          </p>
        </Container>
      </footer>
    );
  }
}
