import { Button, Card, CardDeck, Modal } from "react-bootstrap";
import React, { Component } from "react";
import { USER_TYPE } from "../../utils/user";

import siteSetting from "../../configs/env/Index";

//const custmberType = { 1: 'Farmer', 2: 'Consumer', 3: 'Whole Saler' };

class SignInAs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      userType: "",
      lat: "",
      long: "",
      confirmPassword: "",
      errors: [],
      responseError: "",
      value: "",
      showModal: false,
      showModalLogin: false,
      address: "",
      area: "",
      city: "",
      state: "",
      disable: true,
    };
  }

  handleOnClickLogin = () => {
    this.props.handleShowModalLogin();
    this.setState({ disable: true });
  };

  handle = (e) => {
    this.props.setUserTypeSelect(e);
    if (e === USER_TYPE.FARMER) {
      window.location.href = siteSetting.url;
    } else if (e === USER_TYPE.PICKUPHOST) {
      window.location.href = siteSetting.pickUpHostUrl;
    } else {
      this.setState({ disable: false });
      this.props.setUserTypeSelect(e);
      this.props.handleShowModalLogin();
      this.setState({ disable: true });
    }
  };

  render() {
    return (
      <div className="confirmation_popup">
        {/*Select buyer/consumer*/}
        <Modal
          show={this.props.showModalSignInAs}
          onHide={() => this.props.handleCloseModal()}
          className="cm_confirmation signin_as"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>Sign in as</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="signin_opt">
              <CardDeck>
                <label className="userselect card">
                  <input
                    type="radio"
                    name="userselect"
                    className="userselect-radio"
                  />
                  <Card
                    value={USER_TYPE.CUSTOMER}
                    onClick={() => this.handle(USER_TYPE.CUSTOMER)}
                  >
                    <Card.Img
                      className="card-img-top"
                      varant="top"
                      src={require("../../assets/img/consumer.png")}
                    />
                    <Card.Body>
                      <Card.Title>Local Customer</Card.Title>
                    </Card.Body>
                  </Card>
                </label>

                <label className="userselect card">
                  <input
                    type="radio"
                    name="userselect"
                    className="userselect-radio"
                  />
                  <Card
                    value={USER_TYPE.WHOLESALER}
                    onClick={() => this.handle(USER_TYPE.WHOLESALER)}
                  >
                    <Card.Img
                      className="card-img-top"
                      variant="top"
                      src={require("../../assets/img/wholesale-buyer.png")}
                    />
                    <Card.Body>
                      <Card.Title>Wholesale Buyer</Card.Title>
                    </Card.Body>
                  </Card>
                </label>

                <label className="userselect card">
                  <input
                    type="radio"
                    name="userselect"
                    className="userselect-radio"
                  />
                  <Card
                    value={USER_TYPE.FARMER}
                    onClick={() => this.handle(USER_TYPE.FARMER)}
                  >
                    <Card.Img
                      className="card-img-top"
                      variant="top"
                      src={require("../../assets/img/farmer.png")}
                    />
                    <Card.Body>
                      <Card.Title>Farmer</Card.Title>
                    </Card.Body>
                  </Card>
                </label>

                <label className="userselect card">
                  <input
                    type="radio"
                    name="userselect"
                    className="userselect-radio"
                  />
                  <Card
                    value={USER_TYPE.WHOLESALER}
                    onClick={() => this.handle(USER_TYPE.WHOLESALER)}
                  >
                    <Card.Img
                      className="card-img-top"
                      variant="top"
                      src={require("../../assets/img/schoollarge.png")}
                    />
                    <Card.Body>
                      <Card.Title>School</Card.Title>
                    </Card.Body>
                  </Card>
                </label>

                <label className="userselect card">
                  <input
                    type="radio"
                    name="userselect"
                    className="userselect-radio"
                  />
                  <Card
                    value={USER_TYPE.PICKUPHOST}
                    onClick={() => this.handle(USER_TYPE.PICKUPHOST)}
                  >
                    <Card.Img
                      className="card-img-top"
                      varant="top"
                      src={require("../../assets/img/host.png")}
                    />
                    <Card.Body>
                      <Card.Title>Pick Up Host</Card.Title>
                    </Card.Body>
                  </Card>
                </label>
              </CardDeck>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/*<Button
              variant="secondary"
              disabled={this.state.disable}
              onClick={() => this.handleOnClickLogin()}
            >
              Next
            </Button>*/}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default SignInAs;
