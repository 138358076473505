import { Button, Col, Container, Form, Navbar, Row } from "react-bootstrap";
// import React from 'react'
import React, { Component } from "react";

import { Link } from "react-router-dom";
import Loader from "../common/Loader";
import Url from "../../services/BaseUrl";
import _ from "lodash";
import { _Api } from "../../services/Api";
import { history } from "../../Routes";
import { isValidEmail } from "../../utils/Validation";
import { setLocalStorargeUserLogout } from "../../utils/user";
import { setLocalStorageUserLogin } from "../../utils/user";
import { notify } from "../common/Tooster";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirmPassword: "",
      errors: [],
      responseError: "",
    };
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    });
  };

  handleValidate = () => {
    let validate = false;
    let errors = {};
    let { password, confirmPassword, desc, imageLocation, phone, countryCode } =
      this.state;

    if (_.isEmpty(password)) {
      validate = true;
      errors["password"] = "Password is required.";
    } else if (password.length <= 4) {
      validate = true;
      errors["password"] = "Password must be greater than 4 digits.";
    }
    if (_.isEmpty(confirmPassword)) {
      validate = true;
      errors["confirmPassword"] = "Confirm password is required.";
    }
    if (confirmPassword != password) {
      validate = true;
      errors["confirmPassword"] =
        " New password confirm password must be same.";
    }

    this.setState({ errors });
    return validate;
  };

  // Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "",
  handleResetSubmit = (e) => {
    e.preventDefault();
    if (!this.handleValidate()) {
      let { password } = this.state;
      setLocalStorageUserLogin({
        accessToken: this.props.match.params.id,
      });

      let req = {
        newPassword: password,
      };
      _Api(Url.USER_RESET_PASSWORD.method, Url.USER_RESET_PASSWORD.url, req)
        .then((resp) => {
          notify("success", resp.responseData.message);
          window.location.href = "/";
          setLocalStorargeUserLogout();
        })
        .catch((err) => {
          this.setState({ isLoader: false });
          if (err.error) notify("error", err.error.responseMessage);
        });
    }
  };

  render() {
    let {
      email,
      password,
      passwordErrorMsg,
      emailErrorMsg,
      errors,
      success,
      confirmPasswordErrorMsg,
      confirmPassword,
    } = this.state;
    return (
      <div className="body_container_wrapper login_page">
        <Container>
          <h1>
            <Navbar.Brand href="#home"></Navbar.Brand>
          </h1>
          <div className="login_wrapper">
            <Row>
              <Col md="4" className="mx-auto text-center">
                <div></div>
                <h6>Reset Password</h6>
                <Form.Group>
                  <Form.Control
                    type="Password"
                    onChange={this.handleOnChange}
                    name="password"
                    value={password}
                    placeholder="New Password"
                  />
                  <span className="error_msg">{errors["password"]}</span>
                </Form.Group>{" "}
                <Form.Group>
                  <Form.Control
                    type="Password"
                    onChange={this.handleOnChange}
                    name="confirmPassword"
                    value={confirmPassword}
                    placeholder="Confirm Password"
                  />
                  <span className="error_msg">{errors["confirmPassword"]}</span>
                </Form.Group>
                <Form.Group
                  controlId="formBasicCheckbox"
                  className="forgot_wrapper"
                ></Form.Group>
                <Button
                  type="submit"
                  onClick={this.handleResetSubmit}
                  variant="dark"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

// export default Login
