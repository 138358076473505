import React, { Component } from "react";
import { connect } from "react-redux";

class PickUpHost extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div id="pick-up-host">
          <div className="body_inner_wrapper vh70 p2">
            <h6>Pick Up Host Orders</h6>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { props } = state;

  return {
    props,
  };
}

export default connect(mapStateToProps)(PickUpHost);
