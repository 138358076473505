import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import classnames from "classnames";
import { setHeaderActiveSignInAsSignUp } from "../../redux/action/header";

class SignUpToday extends Component {
  constructor(props) {
    super(props);
    // store component specific state
    this.state = {};
  }

  handleShowModal = () => {
    this.props.action.setHeaderActiveSignInAsSignUp();
  };

  render(props) {
    if (localStorage.getItem("isLoggedIn") === "true") {
      return null;
    }

    return (
      <section className="block bg-tan" id="sign-up-today">
        <div className="sign-up-today-wrapper multi-column-wrapper">
          <div className="sign-up-today-1 sign-up-today-block multi-column-block multi-column-block-image">
            <div className="multi-column-block-inner">
              <img src={require("../../assets/img/sign-up-today.png")} alt="" />
            </div>
          </div>
          <div className="sign-up-today-2 sign-up-today-block multi-column-block multi-column-block-text">
            <div className="multi-column-block-inner">
              <h2 className="section-heading underscore">Register Today</h2>
              <h3 className="font-main h3-5 section-subheading">
                Signing up is free for everyone, <br />
                and takes less than a minute
              </h3>
              <div className="sign-up-today-button-container mt2">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={() => this.handleShowModal()}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({ setHeaderActiveSignInAsSignUp }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpToday);
