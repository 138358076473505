import {
  Alert,
  Col,
  Container,
  Dropdown,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import React, { Component } from "react";

import Loader from "../../components/common/Loader";
import Login from "../../components/auth/Login";
import { Select } from "antd";
import SignInAs from "../../components/auth/SignInAs";
import Signup from "../../components/auth/Signup";
import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCart } from "../../redux/action/Get";
import { login } from "../../redux/action/Auth";
import { notify } from "../common/Tooster";
import { userProfile } from "../../redux/action/Get";
import {
  SIGN_IN_MODAL_STATES,
  setHeaderActiveSignInModal,
} from "../../redux/action/header";
import { isEmpty } from "lodash";

const debug = require("debug")("Header");

const { Option } = Select;

const custmberType = { 1: "Farmer", 2: "Consumer", 3: "Whole Saler" };

class HeaderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      userType: "",
      lat: "",
      long: "",
      confirmPassword: "",
      errors: [],
      responseError: "",
      value: "",
      address: "",
      area: "",
      city: "",
      state: "",
      cartListDetails: [],
      userDetails: {
        firstName: "",
        lastName: "",
      },
      isScrolledPast: false,
    };
  }

  handleShowModalSignUp = () => {
    this.props.action.setHeaderActiveSignInModal({
      activeSignInModal: SIGN_IN_MODAL_STATES.SIGN_UP,
    });
  };

  handleShowModalLogin = () => {
    this.props.action.setHeaderActiveSignInModal({
      activeSignInModal: SIGN_IN_MODAL_STATES.LOGIN,
    });
  };
  handleShowModalSignInAs = () => {
    this.props.action.setHeaderActiveSignInModal({
      activeSignInModal: SIGN_IN_MODAL_STATES.SIGN_IN_AS,
    });
  };
  handleCloseModal = () => {
    debug("handleCloseModal");
    this.props.action.setHeaderActiveSignInModal({
      activeSignInModal: SIGN_IN_MODAL_STATES.NONE,
    });
  };
  // handleCloseModalSignUp = () => {
  //   this.setState({ showModalSignUp: false });
  // };
  // handleCloseModalLogin = () => {
  //   this.setState({ showModalLogin: false });
  // };

  // //for select custmber and wholesale
  // handleCloseModalSignInAs = () => {
  //   this.setState({ showModalSignInAs: false });
  // };

  //Select Consumber type
  handleOnChangeSelectSubType = (e) => {
    this.setState({ consumerType: e });
  };

  //select user Type
  handleOnChangeSelect = (e) => {
    this.setState({ userType: e });
  };

  //OnChange
  handleChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    });
  };

  setUserTypeSelect = (value) => {
    debug("setUserTypeSelect");
    debug(value);
    this.setState({ userType: value, disable: false });
  };

  //set address thorugh map
  handleAddressLatLong = (latValue, lngValue, address, area, city, state) => {
    this.setState({
      lat: latValue,
      long: lngValue,
      address: address,
      area: area,
      city: city,
      state: state,
    });
  };
  //Logout
  handleSignOut = () => {
    _Api(Url.USER_LOGOUT.method, Url.USER_LOGOUT.url)
      .then((resp) => {
        if (resp.statusCode == 1) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("isLoggedIn");
          localStorage.removeItem("userType");
          localStorage.removeItem("locationType");
          localStorage.removeItem("deliveryFees");
          localStorage.removeItem("isTime");
          notify("success", resp.responseData.message);
          window.location.href = "/";
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  //OnScroll
  // handleScroll = (e) => {

  //   this.setState({

  //   });

  // };

  componentDidMount() {
    const self = this;

    if (localStorage.getItem("accessToken")) {
      this.props.action.userProfile();
      this.props.action.getCart();
    }

    let scrollToTopButton = document.getElementById("scroll-to-top-button");
    scrollToTopButton.onclick = function (event) {
      event.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    /* Nav Scroll Spy */
    let lastKnownScrollPosition = 0;
    let ticking = false;
    let headerHeight = document
      .querySelector(".header_wrapper")
      .getBoundingClientRect().height;

    function checkScrollPosition(scrollPos) {
      //console.log(scrollPos);
      if (scrollPos > headerHeight) {
        self.setState({
          isScrolledPast: true,
        });
      } else {
        self.setState({
          isScrolledPast: false,
        });
      }
    }

    document.addEventListener("scroll", function (e) {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(function () {
          checkScrollPosition(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    });
  }

  render() {
    let cart = [];
    cart = this.props.cartListDetails.totalBuyProduct;

    const isScrolledPastClass = this.state.isScrolledPast
      ? "is-scrolled-past"
      : "";

    return (
      <header className={`header_wrapper ${isScrolledPastClass}`}>
        <Alert variant="success" className="cm_header_top">
          <p>Welcome to Healthy PlanEat</p>
        </Alert>
        <Navbar expand="md" className="cm_headerlinks">
          <Container>
            <Navbar.Brand href="/" className="hidden_desktop">
              <img
                src={require("../../assets/img/OFFICIAL LOGO.png")}
                alt="Logo"
              />
            </Navbar.Brand>
            <div className="cm-cart-mobile visible-phone">
              <Link className="nav-link cart  " to="/shoppingcart">
                <img src={require("../../assets/img/cart.png")} alt="cart" />
                <sup className={cart > 0 ? "cart-full" : "cart-empty"}>
                  {cart > 0 ? cart : null}
                </sup>
              </Link>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="">
                <NavLink exact to="/">
                  Home
                </NavLink>
                <NavLink exact to="/about">
                  About
                </NavLink>
                <NavLink exact to="/farms">
                  Farms
                </NavLink>
                <NavLink exact to="/marketpopups">
                  Markets and Pop-Ups
                </NavLink>
                <Navbar.Brand href="/" className="visible_desktop">
                  <img
                    src={require("../../assets/img/OFFICIAL LOGO.png")}
                    alt="Logo"
                  />
                </Navbar.Brand>
                <NavLink exact to="/toyourdoor">
                  To Your Door
                </NavLink>
                <NavLink exact to="/schools">
                  Schools
                </NavLink>
                <NavLink exact to="/recipes">
                  Recipes
                </NavLink>
                {localStorage.getItem("accessToken") ? (
                  <>
                    <Nav.Link>
                      <Dropdown className="useropt_links">
                        <Dropdown.Toggle>
                          {`${this.props.profiledetails?.profileInfo.firstName} ${this.props.profiledetails?.profileInfo.lastName}`}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            className="nav-link"
                            onClick={() =>
                              (window.location.href = "/UserProfile")
                            }
                          >
                            Profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="nav-link"
                            onClick={() =>
                              (window.location.href = "/ordernumber")
                            }
                          >
                            Orders
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="nav-link"
                            onClick={() =>
                              (window.location.href = "/changepassword")
                            }
                          >
                            Change Password
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="nav-link"
                            onClick={this.handleSignOut}
                          >
                            Sign Out
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav.Link>
                    <Link
                      className="nav-link cart hidden-phone"
                      to="/shoppingcart"
                    >
                      <img
                        src={require("../../assets/img/cart.png")}
                        alt="cart"
                      />
                      <sup className={cart > 0 ? "cart-full" : "cart-empty"}>
                        {cart > 0 ? cart : null}
                      </sup>
                    </Link>
                  </>
                ) : (
                  <li>
                    <Nav.Link
                      href="#"
                      onClick={() => this.handleShowModalSignUp(true)}
                    >
                      Sign Up /
                    </Nav.Link>
                    <Nav.Link
                      href="#"
                      onClick={() => this.handleShowModalSignInAs(true)}
                    >
                      Sign In
                    </Nav.Link>
                  </li>
                )}
              </Nav>
            </Navbar.Collapse>
            <SignInAs
              showModalSignInAs={this.props.showModalSignInAs}
              handleShowModalLogin={this.handleShowModalLogin.bind(this)}
              userType={this.state.userType}
              setUserTypeSelect={this.setUserTypeSelect.bind(this)}
              handleCloseModal={this.handleCloseModal.bind(this)}
            />
            <Login
              showModalLogin={this.props.showModalLogin}
              handleShowModalSignUp={this.handleShowModalSignUp.bind(this)}
              userType={this.state.userType}
              handleCloseModal={this.handleCloseModal.bind(this)}
            />
            <Signup
              showModalSignUp={this.props.showModalSignUp}
              handleShowModalSignInAs={this.handleShowModalSignInAs.bind(this)}
              handleCloseModal={this.handleCloseModal.bind(this)}
            />
          </Container>
        </Navbar>
        <div className="scroll-to-top">
          <a
            href="#"
            className="scroll-to-top-button icon"
            data-icon="“"
            id="scroll-to-top-button"
          ></a>
        </div>
      </header>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.login.isLoggedIn,
    profiledetails: state.profile,
    cartListDetails: state.cartList.cartListDetails,
    showModalSignUp: state.header.showModalSignUp,
    showModalLogin: state.header.showModalLogin,
    showModalSignInAs: state.header.showModalSignInAs,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      { login, userProfile, getCart, setHeaderActiveSignInModal },
      dispatch,
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderView);
