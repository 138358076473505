import React, { Component } from "react";
import { Form, Button, Container, ButtonGroup } from "react-bootstrap";
import classnames from "classnames";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userProfile } from "../../redux/action/Get";
import Loader from "../../components/common/Loader";
import Signup from "../../components/auth/Signup";
import Url from "../../services/BaseUrl";
import _ from "lodash";
import { _Api } from "../../services/Api";
import { isValidEmail } from "../../utils/Validation";
import { notify } from "../../components/common/Tooster";

class KeepUpToDate extends Component {
  constructor(props) {
    super(props);
    // store component specific state
    this.state = {
      isLoader: false,
      hasSubscribed: false,
      email: "",
      name: "",
      zipCode: "",
      errors: [],
      responseError: "",
    };
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    });
  };

  handleValidate = () => {
    let validate = false;
    let errors = {};
    let { email, zipCode, name } = this.state;
    if (_.isEmpty(name)) {
      validate = true;
      errors["name"] = "Name is requird.";
    }
    if (_.isEmpty(zipCode)) {
      validate = true;
      errors["zipCode"] = "Zip code is requird.";
    }
    if (_.isEmpty(email)) {
      validate = true;
      errors["email"] = "email is required.";
    } else if (!isValidEmail(email)) {
      validate = true;
      errors["email"] = "Enter a valid email.";
    }
    this.setState({ errors });
    return validate;
  };

  handleOnClickSubmit = () => {
    let self = this;
    if (!this.handleValidate()) {
      let { email, name, zipCode } = this.state;
      let req = {
        name: name,
        email: email,
        zipCode: zipCode,
      };
      self.setState({ isLoader: true });
      _Api(
        Url.USER_NEWS_LETTER_SIGNUP.method,
        Url.USER_NEWS_LETTER_SIGNUP.url,
        req,
      )
        .then((resp) => {
          self.setState({ isLoader: false, email: "", name: "", zipCode: "" });
          notify("success", resp.responseData.message);
        })
        .catch((err) => {
          self.setState({ isLoader: false });
          notify("error", err.error.responseMessage);
        });
    }
  };

  componentDidMount() {
    this.props.action.userProfile();
  }

  render(props) {
    const { profiledetails } = this.props;
    if (!profiledetails) {
      console.log(profiledetails);
    }
    let { email, name, zipCode, errors } = this.state;
    return (
      <section className="block bg-brand-tertiary" id="keep-up-to-date">
        {this.state.isLoader && <Loader />}
        <div className="keep-up-to-date-wrapper multi-column-wrapper">
          <div className="keep-up-to-date-1 sign-up-today-block multi-column-block multi-column-block-text">
            <div className="multi-column-block-inner">
              <h2 className="section-heading underscore white">
                Keep Up to Date
              </h2>
              <h3 className="font-main h3-5 section-subheading white mb2">
                Sign up for our newsletter to discover new vendors, weekly
                specials, events, and seasonal recipes
              </h3>
              <div className="keep-up-to-date-form newsletter-form">
                <div className="newsletter-form-top">
                  <div className="newsletter-form-name">
                    <Form.Control
                      type="text"
                      onChange={this.handleOnChange}
                      name="name"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={this.state.name}
                      placeholder="Name"
                    />
                    <span className="error_msg">{errors["name"]}</span>
                  </div>
                  <div className="newsletter-form-zip">
                    <Form.Control
                      type="text"
                      onChange={this.handleOnChange}
                      name="zipCode"
                      value={this.state.zipCode}
                      placeholder="Zip Code"
                    />
                    <span className="error_msg">{errors["zipCode"]}</span>
                  </div>
                </div>
                <div className="newsletter-form-email">
                  <Form.Control
                    type="email"
                    onChange={this.handleOnChange}
                    name="email"
                    value={this.state.email}
                    placeholder="Your Email"
                  />
                  <span className="error_msg">{errors["email"]}</span>
                </div>
                <button
                  className="btn btn-brand"
                  onClick={this.handleOnClickSubmit}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
          <div className="keep-up-to-date-2 sign-up-today-block multi-column-block multi-column-block-image">
            <div className="multi-column-block-inner">
              <img src={require("../../assets/img/food-grid.png")} alt="" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    profiledetails: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({ userProfile }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KeepUpToDate);
