import React, { Component } from "react";
import OrderNow, { DISPLAY_AS } from "../home/OrderNow";

import { parse as parseQueryString } from "query-string";

const debug = require("debug")("OrderNowPage");

const DISTRIBUTION_FILTER_TYPES = {
  PICKUP: "pickup",
  DELIVERY: "delivery",
  SHIPPING: "shipping",
};

const distributionFilterTypeForString = (filterType) => {
  if (filterType === DISTRIBUTION_FILTER_TYPES.PICKUP)
    return DISTRIBUTION_FILTER_TYPES.PICKUP;
  if (filterType === DISTRIBUTION_FILTER_TYPES.DELIVERY)
    return DISTRIBUTION_FILTER_TYPES.DELIVERY;
  if (filterType === DISTRIBUTION_FILTER_TYPES.SHIPPING)
    return DISTRIBUTION_FILTER_TYPES.SHIPPING;
  // if not specified, default to pickup
  return DISTRIBUTION_FILTER_TYPES.PICKUP;
};

class OrderNowPage extends Component {
  constructor(props) {
    super(props);
    debug("constructor:props");
    debug(props);
    debug(parseQueryString);

    let filterType = DISTRIBUTION_FILTER_TYPES.PICKUP;
    if (props.location.search) {
      const queryParams = parseQueryString(props.location.search);
      filterType = distributionFilterTypeForString(queryParams.type);
    }

    this.state = {
      filterType,
    };
  }

  componentDidMount() {}

  render() {
    const { filterType } = this.state;

    return (
      <div className="body_container_wrapper home">
        <OrderNow filterType={filterType} displayAs={DISPLAY_AS.GRID} />
      </div>
    );
  }
}

export default OrderNowPage;
