import { Col, Container, Row } from "react-bootstrap";
import React, { Component } from "react";

import { Empty } from "antd";
import Loader from "../../components/common/Loader";
import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";
import { notify } from "../../components/common/Tooster.js";
import { PickupCard } from "../../components/PickupCard.jsx";

class MarketPopUps extends Component {
  constructor(props) {
    super(props);
    this.state = { marketPopupDetails: "", search: "", isLoader: false };
  }

  componentDidMount() {
    this.getMarketAndPopup();
  }

  handleSearch = (e) =>
    this.setState({ search: e.target.value }, () => this.getMarketAndPopup());

  redirectToProductDetails = (id) => {
    this.props.history.push({
      pathname: `/marketpopups/marketpopuplist/${id}`,
    });
  };

  getMarketAndPopup = () => {
    this.setState({ isLoader: true });
    let req = {
      search: this.state.search,
    };
    _Api(
      Url.USER_GET_MARKET_POPUP_DETAILS.method,
      Url.USER_GET_MARKET_POPUP_DETAILS.url,
      "",
      `?search=${req.search}`,
    )
      .then((resp) => {
        this.setState({
          marketPopupDetails: resp.responseData.marketInfo,
          isLoader: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  render() {
    let { marketPopupDetails } = this.state;
    return (
      <div className="body_container_wrapper">
        <Container>
          <div className="farmers_search header">
            <Row>
              <Col md="12">
                <div>
                  <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by Market Name"
                      maxLength={30}
                      name="search"
                      value={this.state.search}
                      onChange={this.handleSearch}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {this.state.isLoader && <Loader />}
          <div className="farmers_cardsection market_popup">
            <Row>
              {marketPopupDetails &&
                marketPopupDetails.map((value, i) => {
                  return (
                    <Col md="4" key={`market_card-${i}`}>
                      <PickupCard pickup={value} />
                    </Col>
                  );
                })}
            </Row>
          </div>
          {this.state.marketPopupDetails &&
          this.state.marketPopupDetails.length ? (
            ""
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Container>
      </div>
    );
  }
}

export default MarketPopUps;
