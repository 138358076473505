import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  StripeProvider,
  injectStripe,
} from "react-stripe-elements";
import React, { Component } from "react";

import AddressInfo from "./address";
import EditProfile from "./EditProfile";
import Loader from "../../components/common/Loader";
import Popup from "reactjs-popup";
import Url from "../../services/BaseUrl";
import _ from "lodash";
import { _Api } from "../../services/Api";
import { connect } from "react-redux";
import { notify } from "../../components/common/Tooster";
import { showAddress } from "../../utils/Helper";
import siteSetting from "../../configs/env/Index";

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

class _SplitFieldsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      isLoader: false,
      isEditable: false,
      isShowContactDetail: true,
      isShowAddress: false,
      isShowEditProfile: false,
      addressInfo: "",

      errors: "",
      cardHolderErrorMsg: "",
      checked: false,
      cardId: "",
      profileInfo: [],
      cardInfo: [],
      subscribtionInfo: [],
      openAddCardPopup: false,
      addcarderrmsg: "",
      openRemoveCardPopup: false,
      CustId: "",
      openCardRemoveSuccessPopup: false,
    };
  }

  handleCloseModal = () => {
    this.setState({
      openAddCardPopup: false,
      openRemoveCardPopup: false,
      openCardRemoveSuccessPopup: false,
    });
  };

  componentWillReceiveProps(nextProps, nextState) {
    if (
      nextProps &&
      nextProps.profiledetails &&
      nextProps.profiledetails.profileInfo &&
      nextProps.profiledetails.profileInfo._id
    ) {
      this.setState({ profileInfo: nextProps.profiledetails.profileInfo });
    }
  }

  componentWillMount() {
    // when menu change
    let getProps = this.props;
    if (
      getProps &&
      getProps.profiledetails &&
      getProps.profiledetails.profileInfo &&
      getProps.profiledetails.profileInfo._id
    ) {
      this.setState({ profileInfo: getProps.profiledetails.profileInfo });
    }
  }

  componentDidMount() {
    let getProps = this.props;
    if (
      getProps &&
      getProps.profiledetails &&
      getProps.profiledetails.profileInfo &&
      getProps.profiledetails.profileInfo._id
    ) {
      this.setState({ profileInfo: getProps.profiledetails.profileInfo });
    }

    this.setState({
      isShowAddress: false,
      isShowEditProfile: false,
      isShowContactDetail: true,
    });
    this.getUserProfile();
  }

  getUserProfile = () => {
    this.setState({ isLoader: true });
    _Api(Url.GET_USER_PROFILE.method, Url.GET_USER_PROFILE.url)
      .then((resp) => {
        this.setState({
          userDetails: resp.responseData.userProfile,
          isLoader: false,
        });
        this.getUserCustomerInfo();
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        notify("error", err.error.responseMessage);
      });
  };

  handleShowContact = () => {
    this.setState({
      addressInfo: "",
      isShowAddress: false,
      isShowEditProfile: false,
      isShowContactDetail: true,
    });
  };

  handleShowAddressForm = () => {
    this.setState({
      addressInfo: "",
      isShowAddress: true,
      isShowEditProfile: false,
      isShowContactDetail: false,
    });
  };

  handleOnAddressEdit = (info, e) => {
    this.setState({
      addressInfo: info,
      isShowAddress: true,
      isShowEditProfile: false,
      isShowContactDetail: false,
    });
  };

  refreshAddress = () => {
    this.setState({
      addressInfo: "",
      isShowAddress: false,
      isShowEditProfile: false,
      isShowContactDetail: true,
    });
    this.getUserProfile();
  };

  refreshEditProfile = () => {
    this.setState({
      addressInfo: "",
      isShowAddress: false,
      isShowEditProfile: false,
      isShowContactDetail: true,
    });
    this.getUserProfile();
  };

  handleShowEditProfileForm = () => {
    this.setState({
      addressInfo: "",
      isShowAddress: false,
      isShowEditProfile: true,
      isShowContactDetail: false,
    });
  };

  getUserCustomerInfo = () => {
    let self = this;
    _Api(
      Url.GET_USER_STRIPE_CUSTOMER_INFO.method,
      Url.GET_USER_STRIPE_CUSTOMER_INFO.url,
    )
      .then((response) => {
        if (response.statusCode === 1) {
          let cardId = "";
          if (
            response.responseData.result.invoice_settings.default_payment_method
          ) {
            cardId =
              response.responseData.result.invoice_settings
                .default_payment_method;
          } else {
            cardId = response.responseData.result.default_source;
          }

          // Filter out other payment methods such as "sources",
          // we only want to display cards
          let cardInfo = response.responseData.result.sources.data.filter(
            (card) => card.object === "card",
          );
          let subscribtionInfo =
            response.responseData.result.subscriptions.data;
          self.setState({
            cardId: cardId,
            cardInfo: cardInfo,
            subscribtionInfo: subscribtionInfo,
            isLoader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        // notify('error', err.error.responseMessage)
      });
  };

  setDefaultCard(item, e) {
    this.setState({ errors: "" });
    let self = this;
    self.setState({ isLoader: true });
    let req = {
      default_payment_method: item.id,
    };
    _Api(
      Url.USER_STRIPE_CARD_CHANGE.method,
      Url.USER_STRIPE_CARD_CHANGE.url,
      req,
    )
      .then((resp) => {
        this.setState({ isLoader: false });
        if (resp.statusCode === 1) {
          this.setState({ cardId: item.id });
        } else {
          this.setState({ errors: resp.error.errorMessage });
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        notify("error", err.error.responseMessage);
      });
  }

  addCardPopup = () => {
    this.setState({ errors: "", cardHoldeName: "" });
    this.setState({ openAddCardPopup: !this.state.openAddCardPopup });
  };

  removeCardPopup = (CardId) => {
    this.setState({ errors: "" });
    this.setState({
      openRemoveCardPopup: !this.state.openRemoveCardPopup,
      CardId,
    });
  };

  removeCard(cardId) {
    this.setState({ errors: "" });
    let self = this;
    this.setState({
      isLoader: true,
      errors: "",
      openRemoveCardPopup: !this.state.openRemoveCardPopup,
    });
    let req = {
      cardId: cardId,
    };

    _Api(
      Url.USER_STRIPE_CARD_REMOVE.method,
      Url.USER_STRIPE_CARD_REMOVE.url,
      req,
    )
      .then((resp) => {
        this.setState({ isLoader: false, openCardRemoveSuccessPopup: true });
        if (resp.statusCode === 1) {
          self.getUserCustomerInfo();
        } else {
          this.setState({ errors: resp.error.errorMessage });
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        notify("error", err.error.responseMessage);
      });
  }

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  handleNameSave = (e) => {
    let self = this;
    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + "ErrorMsg"]: "",
        errorMessage: "",
      },
      () => this.setState({ errors: "" }),
    );
  };

  handleCreateAddCard = (evt) => {
    evt.preventDefault();
    this.setState({ addcarderrmsg: "", cardHolderErrorMsg: "" });
    if (this.props.stripe) {
      let self = this;
      let { cardHoldeName, cardHolderErrorMsg } = this.state;
      if (cardHoldeName === "" || cardHoldeName === undefined) {
        this.setState({ cardHolderErrorMsg: "Please enter card holder name." });
      } else {
        this.props.stripe.createToken({ name: cardHoldeName }).then((res) => {
          if (res.error) {
            this.setState({ loader: false, addcarderrmsg: res.error.message });
          } else {
            this.setState({ isLoader: true });
            let cardtoken = res.token.id;
            let req = {
              source: cardtoken,
            };
            _Api(
              Url.USER_STRIPE_ADD_CARD.method,
              Url.USER_STRIPE_ADD_CARD.url,
              req,
            )
              .then((resp) => {
                self.setState({ isLoader: false });
                if (resp.statusCode == 1) {
                  this.getUserCustomerInfo();
                  this.setState({ openAddCardPopup: false });
                } else {
                  this.setState({ isLoader: false });
                  self.setState({ addcarderrmsg: resp.error.responseMessage });
                }
              })
              .catch((err) => {
                this.setState({ isLoader: false });
                notify("error", err.error.responseMessage);
              });
          }
        });
      }
    } else {
    }
  };

  render() {
    let {
      errors,
      profileInfo,
      addcarderrmsg,
      cardHolderErrorMsg,
      userDetails,
      cardId,
      cardInfo,
      openAddCardPopup,
      openRemoveCardPopup,
      openCardRemoveSuccessPopup,
    } = this.state;

    return (
      <div className="body_container_wrapper userprofile">
        <Container>
          {this.state.isLoader && <Loader />}
          <Tab.Container defaultActiveKey="first">
            <Row>
              <Col md={4}>
                <div className="user_details">
                  <h6>Profile</h6>
                  <div className="user_img">
                    {userDetails ? (
                      <>
                        <div className="u_img">
                          <img
                            className="roundedimg"
                            src={
                              userDetails && userDetails.profileImage
                                ? userDetails.profileImage
                                : require("../../assets/img/OFFICIAL LOGO.png")
                            }
                            alt=""
                          />
                        </div>

                        <h6>{`${userDetails.firstName} ${userDetails.lastName}`}</h6>
                        <p>
                          <small>{userDetails.email}</small>
                        </p>
                        <div className="tabber_button">
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey="first">
                                <Button
                                  variant="dark"
                                  onClick={this.handleShowContact}
                                >
                                  Contact Details
                                </Button>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second">
                                <Button variant="dark">Payment</Button>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="cm_payment">
                      {this.state.isShowAddress ? (
                        <AddressInfo
                          onRefreshAddress={this.refreshAddress}
                          addressInfo={this.state.addressInfo}
                          userId={profileInfo._id}
                        />
                      ) : null}
                      {this.state.isShowEditProfile ? (
                        <EditProfile
                          onRefreshEditProfile={this.refreshEditProfile}
                          userId={profileInfo._id}
                        />
                      ) : null}

                      {this.state.isShowContactDetail ? (
                        <div className="shoopingcart_list">
                          <div className="heading">
                            <h6>Contact Details</h6>
                          </div>

                          <div className="table-responsive ">
                            <table class="table contact-details">
                              <tbody>
                                {userDetails &&
                                  Object.values(userDetails.address).map(
                                    (item, i) => {
                                      return (
                                        <tr>
                                          <td className="">
                                            <div className="shopping_list">
                                              <div className="content">
                                                {showAddress(item)}
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <Button
                                              variant="outline-dark"
                                              className="cancle-button sizesame"
                                              onClick={this.handleOnAddressEdit.bind(
                                                this,
                                                userDetails.address[i],
                                              )}
                                            >
                                              Edit
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    },
                                  )}
                              </tbody>
                            </table>

                            <table class="table contact-details">
                              <tbody>
                                <tr>
                                  <td className=""></td>
                                  <td>
                                    <Button
                                      onClick={this.handleShowAddressForm}
                                      variant="outline-dark"
                                      className="cancle-button sizesame"
                                    >
                                      Add Address
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="total"></div>
                          </div>
                          <div className="heading">
                            <h6>Email Address</h6>
                          </div>
                          <div className="table-responsive ">
                            <table class="table contact-details">
                              <tbody>
                                <tr>
                                  <td className="max-w">
                                    <div className="shopping_list">
                                      <div className="content">
                                        <input
                                          type="email"
                                          className="editable_fields"
                                          placeholder={userDetails.email}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td className="">
                                    <div className="shopping_list">
                                      <div className="content">
                                        <h6>Phone Number</h6>
                                        <input
                                          type="number"
                                          className="editable_fields"
                                          placeholder={
                                            userDetails.phone === ""
                                              ? "NA"
                                              : userDetails.phone
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td className="">
                                    <Button
                                      variant="outline-dark"
                                      className="cancle-button sizesame"
                                      onClick={this.handleShowEditProfileForm}
                                    >
                                      Edit Profile
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="cm_payment">
                      <div className="shoopingcart_list">
                        <div className="heading">
                          <h6>Payment</h6>
                        </div>

                        <div className="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Card Number</th>
                                <th>MM / YY</th>
                                <th align="right">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!_.isEmpty(cardInfo)
                                ? cardInfo.map((item, index = 0) => {
                                    return (
                                      <tr>
                                        <td>
                                          <div className="shopping_list">
                                            <div className="content">
                                              <h6>
                                                <label className="conta cmchk-box">
                                                  <input
                                                    checked={
                                                      cardId === item.id
                                                        ? true
                                                        : false
                                                    }
                                                    type="checkbox"
                                                    onChange={this.setDefaultCard.bind(
                                                      this,
                                                      item,
                                                    )}
                                                  />
                                                  <span className="checkmark"></span>
                                                </label>
                                                <span className="cardImage mr-0">
                                                  {item.brand == "Visa" ? (
                                                    <img
                                                      src={require("../../assets/img/cardicon/visa.png")}
                                                    />
                                                  ) : null}
                                                  {item.brand ==
                                                  "Visa (debit)" ? (
                                                    <img
                                                      src={require("../../assets/img/cardicon/visaDebit.png")}
                                                    />
                                                  ) : null}
                                                  {item.brand == "MasterCard" ||
                                                  item.brand ==
                                                    "Mastercard (2-series)" ||
                                                  item.brand ==
                                                    "Mastercard (debit)" ||
                                                  item.brand ==
                                                    "Mastercard (prepaid)" ? (
                                                    <img
                                                      src={require("../../assets/img/cardicon/master.png")}
                                                    />
                                                  ) : null}
                                                  {item.brand ==
                                                  "American Express" ? (
                                                    <img
                                                      src={require("../../assets/img/cardicon/a_ex.png")}
                                                    />
                                                  ) : null}
                                                  {item.brand ==
                                                    "Diners Club" ||
                                                  item.brand ==
                                                    "Diners Club (14 digit card)" ? (
                                                    <img
                                                      src={require("../../assets/img/cardicon/d_c.png")}
                                                    />
                                                  ) : null}
                                                  {item.brand == "JCB" ? (
                                                    <img
                                                      src={require("../../assets/img/cardicon/jcb.png")}
                                                    />
                                                  ) : null}
                                                  {item.brand == "UnionPay" ? (
                                                    <img
                                                      src={require("../../assets/img/cardicon/uP.png")}
                                                    />
                                                  ) : null}
                                                  {item.brand == "Discover" ? (
                                                    <img
                                                      src={require("../../assets/img/cardicon/discover.png")}
                                                    />
                                                  ) : null}
                                                </span>
                                                ************{item.last4}
                                              </h6>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <h6 className="qty">
                                            {item.exp_month}/{item.exp_year}
                                          </h6>
                                        </td>
                                        <td>
                                          {cardId != item.id ? (
                                            <Button
                                              variant="outline-dark"
                                              className="cancle-button"
                                              onClick={this.removeCardPopup.bind(
                                                this,
                                                item.id,
                                              )}
                                            >
                                              Remove
                                            </Button>
                                          ) : null}
                                        </td>
                                      </tr>
                                    );
                                  })
                                : ""}
                            </tbody>
                          </table>
                        </div>
                        <div className="total">
                          <Button
                            variant="outline-dark"
                            className="cancle-button"
                            onClick={this.addCardPopup.bind(this)}
                          >
                            Add New Card
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

          <Popup
            open={openRemoveCardPopup}
            closeOnDocumentClick
            onClose={this.handleCloseModal}
            className="delete"
          >
            <div className="cm_modal dlt-cart">
              <button
                type="button"
                className="close"
                onClick={this.handleCloseModal}
              >
                &times;
              </button>
              &nbsp;
              <div className="modal-body text-center">
                <h4>
                  <b>Are you sure you want to remove this card?</b>
                </h4>
                <br />
                <div className="cm-popup-btn">
                  <button
                    onClick={this.removeCard.bind(this, this.state.CardId)}
                    className="btn btn-primary btn-blue"
                  >
                    Yes
                  </button>
                  &nbsp;
                  <button
                    onClick={this.handleCloseModal}
                    class="btn btn-secondary"
                  >
                    No
                  </button>
                </div>
                <br />
              </div>
            </div>
          </Popup>

          <Popup
            open={openCardRemoveSuccessPopup}
            closeOnDocumentClick
            onClose={this.handleCloseModal}
            className="delete"
          >
            <div className="cm_modal dlt-cart">
              <button
                type="button"
                className="close"
                onClick={this.handleCloseModal}
              >
                &times;
              </button>
              &nbsp;
              <div className="modal-body text-center">
                <h4>
                  <b>Card is successfully removed.</b>
                </h4>{" "}
                <br />
                <div className="cm-popup-btn">
                  <button
                    className="btn btn-header btn-blue"
                    onClick={this.handleCloseModal}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </Popup>

          <Popup
            open={openAddCardPopup}
            closeOnDocumentClick
            onClose={this.handleCloseModal}
            className="delete"
          >
            <div className="cm_modal user_details">
              <button
                type="button"
                className="close"
                onClick={this.handleCloseModal}
              >
                &times;
              </button>
              &nbsp;
              <div className="modal-body text-center">
                <div className="paymentitem_content">
                  <h5 className="">Add New Card</h5>
                  <form
                    className="add_stripe_card_form"
                    onSubmit={this.handleCreateAddCard.bind(this)}
                  >
                    <div className="form-group row">
                      <div className="split-form">
                        <label htmlFor="card"> Card number</label>
                        <label className="form-control">
                          <CardNumberElement
                            {...createOptions()}
                            onChange={this.handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="split-form">
                        <input
                          type="text"
                          autocomplete="off"
                          className="form-control"
                          value={this.state.cardHoldeName}
                          name="cardHoldeName"
                          id="cardHoldeName"
                          onChange={this.handleNameSave}
                          maxLength="35"
                          placeholder="Card Holder Name"
                        />
                        {cardHolderErrorMsg ? (
                          <div className="error">{cardHolderErrorMsg}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="slip_cart-sec">
                      <div className="cm_striped_label">
                        <label className="form-control" htmlFor="Expiration">
                          Expiration date
                          <CardExpiryElement
                            {...createOptions()}
                            onChange={this.handleChange}
                          />
                        </label>
                      </div>

                      <div className="cm_striped_label ">
                        <label className="form-control">
                          CVC
                          <CardCVCElement
                            {...createOptions()}
                            onChange={this.handleChange}
                            className=""
                          />
                        </label>
                      </div>
                    </div>

                    {addcarderrmsg ? (
                      <div className="error">{addcarderrmsg}</div>
                    ) : null}

                    <div className="addcart_button">
                      <button
                        onClick={this.handleCloseModal}
                        className="btn btn-secondary"
                      >
                        Close
                      </button>
                      <button className="btn btn-primary btn-blue">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Popup>
        </Container>
      </div>
    );
  }
}

// export default UserProfile;

function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.login.isLoggedIn,
    profiledetails: state.profile,
    cartListDetails: state.cartList.cartListDetails,
  };
}
const SplitFieldsForm = injectStripe(
  connect(mapStateToProps)(_SplitFieldsForm),
);

export default class UserProfile extends Component {
  render() {
    return (
      <StripeProvider apiKey={siteSetting.stripeKey}>
        <Elements>
          <SplitFieldsForm handleResult={this.props.handleResult} />
        </Elements>
      </StripeProvider>
    );
  }
}
