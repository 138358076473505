import { Col, Container, Row } from "react-bootstrap";
import React, { Component } from "react";

import Banner from "../banner";
import Signup from "../../components/auth/Signup";

class Schools extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSignUp: false,
    };
  }

  toggleSignUp = () => {
    this.setState({ showSignUp: !this.state.showSignUp });
  };

  render() {
    return (
      <div className="body_container_wrapper no_pad">
        <Signup
          showModal={this.state.showSignUp}
          handleShowModal={this.toggleSignUp}
        />

        <Banner
          heading="Farm to School"
          bannerbg={`url(${require("../../assets/img/about.png")})`}
        />
        <Container>
          <div className="who_we_are two_col_wrapper">
            <div className="heading">
              <h3>
                <span>Farm to School</span>
              </h3>
              <h6>
                Children deserve access to healthy and sustainably produced food
                grown by local farmers.
              </h6>
            </div>
          </div>
          <div className=" two_col_wrapper">
            <div className="heading">
              <h3>
                <span>
                  Bring Locally Grown Food To Your School with Healthy PlanEat
                </span>
              </h3>
              <h6>
                Are you interested in bringing healthy, sustainable, locally
                grown food to your school?
              </h6>
              <h6>
                You can shop directly from local sustainable farms via Healthy
                PlanEat and pay with a credit card or by invoice.
              </h6>

              <h6>We can also provide:</h6>
              <ul className="responsive-list">
                <li>Tours at local sustainable farms</li>
                <li>
                  Events at your school where students can meet local farmers
                </li>
                <li>
                  Marketing materials about the farms, farmers, and foods you’ve
                  ordered
                </li>
              </ul>
            </div>
          </div>
          <div className="who_we_are two_col_wrapper">
            <div className="heading">
              <h3>
                <span>Let’s Get Started!</span>
              </h3>
              <p>
                Click “Sign Up” and select “School” for your user type. No EIN
                is required.
              </p>
              <p>
                You can also sign up for our farm to school newsletter to
                receive updates on what’s in season.
              </p>
            </div>
          </div>
          <div className="imgcontent_wrapper">
            <div className="about_wrapper">
              <div className=" two_col_wrapper  right">
                <Row>
                  <Col md="4">
                    <div className="farmer_cardimg">
                      <img
                        src={require("../../assets/img/Put-local-on-your-tray-clear-background-400x317.png")}
                        className="logosize"
                      />
                    </div>
                  </Col>
                  <Col md="8">
                    <div className="two_col_content">
                      <div className="heading">
                        <h3>
                          <span>Put Local On Your Tray</span>
                        </h3>
                        <h6 className="content">
                          <span>
                            Farm to School programs provide many benefits:
                            students are educated about healthy food choices,
                            access to healthy food is increased for students of
                            all income levels, communities are engaged, food
                            miles are reduced, public health is improved, and
                            economic development occurs at the local level.
                          </span>
                        </h6>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default Schools;
