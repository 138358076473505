import React from "react";
import { Container } from "react-bootstrap";

const Banner = (props) => {
  return (
    <div className="banner-bg" style={{ backgroundImage: `${props.bannerbg}` }}>
      <div className="banner-content">
        <Container>
          <h1>{props.heading}</h1>
        </Container>
      </div>
    </div>
  );
};

export default Banner;
