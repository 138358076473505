import {
  SIGN_IN_MODAL_STATES,
  SET_HEADER_ACTIVE_SIGN_IN_MODAL,
} from "../action/header";

const debug = require("debug")("header-reducer");

const headerState = {
  activeSignInModal: SIGN_IN_MODAL_STATES.NONE,
  showModalSignUp: false,
  showModalLogin: false,
  showModalSignInAs: false,
};

// given the current state, determine what other state to set
export const getNewStateForNextProps = ({
  activeSignInModal,
  nextActiveSignInModal,
}) => {
  const stateToSet = {};
  if (activeSignInModal !== nextActiveSignInModal) {
    if (nextActiveSignInModal === SIGN_IN_MODAL_STATES.SIGN_UP) {
      stateToSet.showModalSignUp = true;
      stateToSet.showModalLogin = false;
      stateToSet.showModalSignInAs = false;
    }
    if (nextActiveSignInModal === SIGN_IN_MODAL_STATES.SIGN_IN_AS) {
      stateToSet.showModalSignUp = false;
      stateToSet.showModalLogin = false;
      stateToSet.showModalSignInAs = true;
    }
    if (nextActiveSignInModal === SIGN_IN_MODAL_STATES.LOGIN) {
      stateToSet.showModalSignUp = false;
      stateToSet.showModalLogin = true;
      stateToSet.showModalSignInAs = false;
    }
    if (nextActiveSignInModal === SIGN_IN_MODAL_STATES.NONE) {
      stateToSet.showModalSignUp = false;
      stateToSet.showModalLogin = false;
      stateToSet.showModalSignInAs = false;
    }
    stateToSet.activeSignInModal = nextActiveSignInModal;
  }
  return stateToSet;
};

export const header = (state = headerState, action) => {
  switch (action.type) {
    case SET_HEADER_ACTIVE_SIGN_IN_MODAL:
      const stateToSet = getNewStateForNextProps({
        activeSignInModal: state.activeSignInModal,
        nextActiveSignInModal: action.payload.activeSignInModal,
      });
      return {
        ...state,
        ...stateToSet,
      };
    default:
      return state;
  }
};
