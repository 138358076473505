import React, { useState, useEffect } from "react";
import { USER_TYPE } from "../utils/user";
import { Tab, Tabs, Modal } from "react-bootstrap";
import _ from "lodash";
import { _Api } from "../services/Api";
import {
  productPriceFromattedForInventoryItem,
  productPriceForInventoryItem,
  productUnitForInventoryItem,
  isSubscriptionItem,
  subscriptionParameterFields,
  productNameForInventoryItem,
} from "../utils/inventory";
import {
  setHeaderActiveSignInAsSignUp,
  setHeaderActiveSignInAsSignInAs,
} from "../redux/action/header";
import { addToCart } from "../redux/action/Get";
import Pagination from "react-js-pagination";
import { useSelector, useDispatch } from "react-redux";
import { days } from "../utils/Helper";

const DeliveryInventoryList = ({
  farms,
  inventory,
  setInventory,
  sortedFarms,
  selectedFarm,
  filterType,
  isZipCodeSet,
  alertDeliveryShow,
  zipCode,
  sliderProps,
  distributionType,
  userType,
}) => {
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [inventoryLength, setInventoryLength] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [inventoryType, setInventoryType] = useState(0);
  const [filteredInventory, setFilteredInventory] = useState();
  const [modalMaximumQuantityShow, setModalMaximumQuantityShow] =
    useState(false);
  const [modalMaximumQuantity, setModalMaximumQuantity] = useState(0);
  const [modalMustSignInShow, setModalMustSignInShow] = useState(false);
  const itemsPerPage = 10;

  const cartListDetails = useSelector((state) => {
    return state.cartList.cartListDetails;
  });

  useEffect(() => {
    // Reset the filtered inventory when the farm changes
    setFilteredInventory();
    // Reset the page number after a user changes the farm
    setPageNo(1);
    // Reset the inventory type when the farm changes
    // default back to the "All" inventory type (0)
    setInventoryType(0);

    setInventory(
      selectedFarm
        ? selectedFarm.map((farm) => farm.inventory).flat()
        : sortedFarms.map((farm) => farm.inventory).flat(),
    );
  }, [selectedFarm, sortedFarms]);

  let currentProductListEntry = {};
  if (Array.isArray(cartListDetails.buyList)) {
    const currentProductListEntries = cartListDetails.buyList?.map(
      (buyListEntry) => {
        return buyListEntry.productList.filter((productListEntry) => {
          return inventory.map((inv) => inv._id === productListEntry.productId);
        });
      },
    );

    if (currentProductListEntries.length === 1) {
      currentProductListEntry = currentProductListEntries[0];
    }

    if (currentProductListEntries.length > 1) {
      currentProductListEntry = currentProductListEntries.reduce((acc, cur) =>
        acc.concat(cur),
      );
    }
  }

  const cartContainsProduct = (item) => {
    if (currentProductListEntry.length === undefined) {
      return;
    }
    return currentProductListEntry.find(
      (product) => product.productId === item._id,
    );
  };

  const quantityInCart = (item) => {
    if (currentProductListEntry.length === undefined) {
      return;
    }
    return currentProductListEntry.find(
      (product) => product.productId === item._id,
    )?.productQuantity;
  };

  const farmNameAssociatedWithProduct = (item) => {
    return sortedFarms.find((sortedFarm) => {
      return sortedFarm.inventory.find((inv) => inv._id === item._id);
    })?.farmName;
  };

  const [productQuantity, setProductQuantity] = useState(1);

  const prouctQuantityOnChange = (index, event, item) => {
    let quantity = +event.target.value;
    if (isNaN(quantity)) return;
    if (quantity > item.maximumWholeselerQuantity) {
      setModalMaximumQuantity(item.maximumWholeselerQuantity);
      setModalMaximumQuantityShow(true);
    } else {
      setProductQuantity(quantity);
    }
  };

  const addToCartOnClick = (event, item) => {
    if (!localStorage.getItem("accessToken")) {
      setModalMustSignInShow(true);
      return;
    }

    const payload = {
      productQuantity,
      productPrice: productPriceForInventoryItem({
        inventoryItem: item,
        userType,
      }),
      productId: item._id,
      farmId: item.distributionInfo.farmId,
      unit: productUnitForInventoryItem({ inventoryItem: item, userType }),
      variety: item.variety,
      distributionTypes: item.distributionTypes,
    };
    if (isSubscriptionItem({ inventoryItem: item })) {
      const subscriptionParams = subscriptionParameterFields({
        inventoryItem: item,
      });
      Object.assign(payload, subscriptionParams);
    }
    dispatch(addToCart(payload));

    // Reset the quantity to 1 so that default quantity is still 1
    // if the user adds another product
    setProductQuantity(1);
  };

  const handlePageChange = (pageNo) => {
    // Scroll to the top of the table when changing pages
    document.getElementById("tableTop").scrollIntoView({
      behavior: "smooth",
    });
    setPageNo(pageNo);
  };

  const handleTabChange = (key) => {
    // Reset page number to 1 when switching tabs
    setPageNo(1);

    // Set the inventory type to the tab key
    // so we can default back to the "All" tab
    // when a user changes the farm
    setInventoryType(key);
    if (+key === 0) {
      return setFilteredInventory(inventory);
    }

    setFilteredInventory(
      inventory.filter((item) => item.inventoryType === +key),
    );
  };

  useEffect(() => {
    const indexOfLastItem = pageNo * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // Prioritize filtered inventory if it exists
    const chooseInventory = filteredInventory ? filteredInventory : inventory;

    // Set inventory length for pagination
    setInventoryLength(chooseInventory.length);

    // Set current items for pagination
    const currentItems = chooseInventory.slice(
      indexOfFirstItem,
      indexOfLastItem,
    );
    setCurrentItems(currentItems);
  }, [pageNo, inventory, filteredInventory]);

  const inventoryRows = (
    <div className="cm-inventory_gridview">
      <div className="table-responsive" id="divToPrint">
        <table className="table cm_bottom_space" id="tableTop">
          <thead>
            <tr className="broder_top_0">
              <th scope="col">Image</th>
              <th scope="col">Product</th>
              <th scope="col">Farm</th>
              <th scope="col">Order Window</th>
              <th scope="col">Price Per Unit</th>
              <th scope="col">Units Available</th>
              <th scope="col">Quantity</th>
              <th scope="col">Add to Cart</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(currentItems) &&
              currentItems.map((item, index) => (
                <tr key={item._id}>
                  <td>
                    <img
                      height="40px"
                      width="40px"
                      src={item.image}
                      alt={productNameForInventoryItem({ inventoryItem: item })}
                    />
                  </td>
                  <td>
                    <div onClick={(e) => this.openViewPopup(e, item)}>
                      {productNameForInventoryItem({ inventoryItem: item })}
                    </div>
                  </td>
                  <td>{farmNameAssociatedWithProduct(item)}</td>
                  <td>
                    {item.distributionInfo
                      ? `${days[item.distributionInfo.placeOrderOpen.day]} ${
                          item.distributionInfo.placeOrderOpen.time
                        } ${item.distributionInfo.placeOrderOpen.meridiem} - ${
                          days[item.distributionInfo.placeOrderClose.day]
                        } ${item.distributionInfo.placeOrderClose.time} ${
                          item.distributionInfo.placeOrderClose.meridiem
                        }`
                      : null}
                  </td>
                  <td>
                    {}
                    {productPriceFromattedForInventoryItem({
                      inventoryItem: item,
                      userType: USER_TYPE.WHOLESALER,
                    })}
                  </td>
                  <td>
                    {item.maximumWholeselerQuantity
                      ? item.maximumWholeselerQuantity
                      : ""}{" "}
                  </td>
                  <td>
                    {item.maximumWholeselerQuantity > 0 && (
                      <input
                        type="number"
                        name={`qty_${item._id}`}
                        min="0"
                        max={item.maximumWholeselerQuantity}
                        placeholder="0"
                        disabled={cartContainsProduct(item)}
                        value={quantityInCart(item)}
                        className="form-control"
                        onChange={(event) =>
                          prouctQuantityOnChange(index, event, item)
                        }
                      />
                    )}
                  </td>
                  <td>
                    <button
                      className="order-now-delivery-button"
                      disabled={cartContainsProduct(item)}
                      onClick={(event) => addToCartOnClick(event, item)}
                    >
                      {cartContainsProduct(item) ? "Added" : "Add"}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {currentItems.length !== 0 ? (
          <div className="d-flex justify-content-end cm-pagination">
            <Pagination
              itemClassPrev={"prevClass"}
              itemClassNext={"nextClass"}
              activePage={pageNo}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={inventoryLength}
              pageRangeDisplayed={3}
              onChange={(e) => handlePageChange(e)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );

  return (
    <>
      <div className="cm-inventory" id="delivery-inventory-list">
        <Tabs
          defaultActiveKey="0"
          onSelect={(e) => handleTabChange(e)}
          activeKey={inventoryType}
          id="uncontrolled-tab-example"
        >
          <Tab eventKey="0" title="All">
            {inventoryRows}
          </Tab>
          {/* Will turn into Vegetables */}
          <Tab eventKey="1" title="Vegetables">
            {inventoryRows}
          </Tab>
          <Tab eventKey="2" title="Seedlings">
            {inventoryRows}
          </Tab>
          {/* 
            Commenting out CSA Shares and Subscription Boxes for now
            <Tab eventKey="3" title="CSA Shares">
              {inventoryRows}
            </Tab>
            <Tab eventKey="4" title="Subscription Boxes">
              {inventoryRows}
            </Tab> 
          */}
          <Tab eventKey="6" title="Fruits">
            {inventoryRows}
          </Tab>
          <Tab eventKey="7" title="Meat and Eggs">
            {inventoryRows}
          </Tab>
          <Tab eventKey="8" title="Dairy">
            {inventoryRows}
          </Tab>
          <Tab eventKey="5" title="Others">
            {inventoryRows}
          </Tab>
        </Tabs>
        {_.isEmpty(currentItems) ? (
          <h3 className="text-center">No data found</h3>
        ) : (
          ""
        )}
      </div>
      <Modal
        show={modalMaximumQuantityShow}
        onHide={() => setModalMaximumQuantityShow(false)}
        className="cm_confirmation imageView"
      >
        <Modal.Header closeButton>
          <Modal.Title>Only {modalMaximumQuantity} units left</Modal.Title>
        </Modal.Header>
      </Modal>
      {/* This will not be shown as users will have to be signed in
      and signed in as a wholesaler or school to view this component,
      leaving this here for now just incase we plan on using this view 
      for all user types */}
      <Modal
        show={modalMustSignInShow}
        onHide={() => setModalMustSignInShow(false)}
        className="cm_confirmation imageView"
      >
        <Modal.Header closeButton>
          <Modal.Title>You are not Signed In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            Please{" "}
            <span
              className="link"
              onClick={() => {
                setModalMustSignInShow(false);
                dispatch(setHeaderActiveSignInAsSignInAs());
              }}
            >
              {" "}
              Sign In
            </span>{" "}
            to your account.
            <div>
              Don't have an account?{" "}
              <span
                className="link"
                onClick={() => {
                  setModalMustSignInShow(false);
                  dispatch(setHeaderActiveSignInAsSignUp());
                }}
              >
                {" "}
                Sign Up{" "}
              </span>
            </div>
          </h6>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeliveryInventoryList;
