import React, { useState, useEffect } from "react";
import { USER_TYPE } from "../utils/user";
import { Alert, Dropdown, Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from "lodash";
import Url from "../services/BaseUrl";
import { _Api } from "../services/Api";
import { currentlyOrderableInventory } from "../utils/inventory";
import {
  addNumberAvailableProducts,
  groupFarmsWithInventory,
} from "../utils/farm";
import { DISTRIBUTION_TYPE } from "../utils/distribution";
import classnames from "classnames";
import DeliveryInventoryList from "./DeliveryInventoryList";
import { hasOpenOrderDeliveryDistribution } from "../utils/distribution";
import { InventoryProductCards } from "./InventoryProductCards";
import { FarmCards } from "./FarmCards";

const debug = require("debug")("FarmCards");
const queryString = require("query-string");

const DeliveryListViewTooltip = (props) => <Tooltip>{props}</Tooltip>;

const DeliveryWholesale = ({
  alertDeliveryShow,
  zipCode,
  sliderProps,
  userType,
  isZipCodeSet,
  filterType,
  closeDistributionAlert,
}) => {
  const [view, setView] = useState(1); // Default to table view
  const [farms, setFarms] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [sortedFarms, setSortedFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState(null);
  const cardsProps = {
    zipCode,
    sliderProps,
    distributionType: DISTRIBUTION_TYPE.DELIVERY,
    userType,
    isZipCodeSet,
    filterType,
  };

  const handleFarmChange = (farm) => {
    // Update the list of farms
    sortedFarms.map((f) => {
      if (f._id === farm._id) {
        return setSelectedFarm([farm]);
      }
      return f;
    });
  };

  useEffect(() => {
    if (!zipCode) {
      return;
    }

    debug("query-farms");
    const queryParams = {
      zipCode,
      userType: USER_TYPE.WHOLESALER,
      distributionType: DISTRIBUTION_TYPE.DELIVERY,
    };

    // Reset selected farm when zipCode changes
    // Only display farms that are orderable for the current zipCode
    if (zipCode && selectedFarm) {
      setSelectedFarm(null);
    }

    _Api(
      Url.USER_ALL_FARM_DISTRIBUTIONS_INVENTORY.method,
      Url.USER_ALL_FARM_DISTRIBUTIONS_INVENTORY.url,
      "",
      `?${queryString.stringify(queryParams)}`,
    ).then((resp) => {
      debug("query-farm-result");
      const { farms, inventory, distributions } = resp.responseData.result;
      setFarms(
        addNumberAvailableProducts({
          farms: resp.responseData.result.farms,
          inventory: currentlyOrderableInventory({
            inventory,
            distributions,
          }),
        }),
      );
      const groupedFarms = groupFarmsWithInventory({
        farms,
        inventory: currentlyOrderableInventory({
          inventory,
          distributions,
        }),
      });
      const sortedByProductName = _.sortBy(groupedFarms, ["farmName"]);
      setSortedFarms(sortedByProductName);

      setInventory(
        selectedFarm
          ? selectedFarm.map((farm) => farm.inventory).flat()
          : sortedByProductName.map((farm) => farm.inventory).flat(),
      );
    });
  }, [zipCode]);

  useEffect(() => {
    if (!inventory || sortedFarms.length === 0) {
      return;
    }

    debug("query-farm-details");
    const farmIds = sortedFarms.map((farm) => farm._id).join(",");
    _Api(
      Url.USER_GET_FARM_BY_IDS.method,
      Url.USER_GET_FARM_BY_IDS.url,
      "",
      `?farmIds=${farmIds}&userType=${userType}`,
    ).then((resp) => {
      debug("query-farm-details-result");
      if (resp.statusCode === 1) {
        let farmInfo = resp.responseData.farmInfo;

        const distributionInfo = farmInfo.map((item) => {
          item.distributionInfo.map((i) => (i.farmId = item._id));
          return item.distributionInfo;
        });

        const currentDay = new Date().getDay();

        // Choose distribution info that is open for the current day
        const currentDayDistributionInfo = distributionInfo.map((item) => {
          return item.find((i) => {
            if (i.placeOrderOpen.day === currentDay) {
              return i;
            }

            // Check to see if the other distribution info is open for the current day
            if (i.placeOrderOpen.day !== currentDay) {
              const openOrder = hasOpenOrderDeliveryDistribution(item);
              if (openOrder) {
                return i;
              }
            }
          });
        });

        // Add the distribution info to the inventory items
        const updatedInventory = sortedFarms.map((item) => {
          const distribution = currentDayDistributionInfo.find(
            (i) => i.farmId === item._id,
          );
          item.inventory.map((i) => (i.distributionInfo = distribution));
          return item.inventory;
        });

        setInventory(updatedInventory.flat());
      }
    });
  }, [sortedFarms]);

  return (
    <>
      <div
        className={classnames({
          "order-now-flex": true,
          active: true,
        })}
      >
        <div>
          <OverlayTrigger
            placement="top"
            // overlay={DeliveryListViewTooltip("View inventory as cards")}
            overlay={DeliveryListViewTooltip("View farms")}
          >
            <button
              className={classnames({
                "order-now-nav-button": true,
                active: view === 0,
              })}
              data-type="cards"
              onClick={() => setView(0)}
              disabled={!zipCode}
            >
              <i className="fas fa-th-large"></i>
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={DeliveryListViewTooltip("View inventory as a list")}
          >
            <button
              className={classnames({
                "order-now-nav-button": true,
                active: view === 1,
              })}
              data-type="list"
              onClick={() => setView(1)}
              disabled={!zipCode}
            >
              <i className="fas fa-bars"></i>
            </button>
          </OverlayTrigger>
        </div>
        <div className="order-now-flex">
          {!selectedFarm && view === 1 ? (
            <p>Please select which farm(s) you would like to order from.</p>
          ) : null}

          {view === 1 ? (
            <Dropdown className="order-now-nav-dropdown">
              <Dropdown.Toggle size="sm" disabled={!zipCode || !isZipCodeSet}>
                {selectedFarm ? selectedFarm[0].farmName : "Choose Farm"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSelectedFarm(null)}>
                  All
                </Dropdown.Item>
                {sortedFarms.map((farm, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleFarmChange(farm)}
                    >
                      {farm.farmName}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
        </div>
      </div>

      {!zipCode || !isZipCodeSet ? (
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <Alert
              variant="warning"
              className="order-now-no-results hp-alert hp-alert-large"
            >
              Please enter your zip code to see available products
            </Alert>
          </div>
        </div>
      ) : view === 1 ? (
        <DeliveryInventoryList
          filterType={1}
          {...cardsProps}
          farms={farms}
          inventory={inventory}
          setInventory={setInventory}
          sortedFarms={sortedFarms}
          selectedFarm={selectedFarm}
        />
      ) : (
        <div className="order-now-deliveries-items order-now-slider-container">
          {/* 
            // Requested to be hidden for now
            <InventoryProductCards
            view={view}
            setView={setView}
            {...cardsProps}
            />
          */}

          <FarmCards {...cardsProps} />
        </div>
      )}
    </>
  );
};

export default DeliveryWholesale;
