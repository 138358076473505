import { Button, Modal } from "react-bootstrap";
import React, { Component } from "react";
import { SubscriptionInfoOrderSummary } from "../../components/products.jsx";
import { getFarmIdsFromCart, isValidCart } from "../../utils/inventory";

import { USER_TYPE } from "../../utils/user";

import Loader from "../../components/common/Loader.js";
import { NullComponent } from "../../components/common/Null";
import Url from "../../services/BaseUrl";
import { _Api } from "../../services/Api";
import { bindActionCreators } from "redux";
import {
  cartHasValidDistributionOptions,
  formatDeliveryInfo,
} from "../../utils/distribution";
import { cartShippedProducts, cartNonShippedBuyList } from "../../utils/cart";
import { connect } from "react-redux";
import { getCart } from "../../redux/action/Get";
import { notify } from "../../components/common/Tooster.js";
const debug = require("debug")("order-summary-items");

class OrderSummaryItems extends Component {
  state = {
    getApiResponse: false,
    isLoader: true,
    removeitem: false,
    cartListDetails: [],
    productListId: "",
    transferArray: [],
    totalPrice: "",
    totalSipping: "",
    totalPayAmount: "",
    farms: [],
  };

  RemovehandleClose = () => {
    this.setState({ removeitem: false });
  };

  componentDidMount() {
    this.getCartList();
    this.setState({
      userType: localStorage.getItem("userType")
        ? +localStorage.getItem("userType")
        : USER_TYPE.CONSUMER,
    });
  }

  getFarmsDetails = (farmIds) => {
    if (!farmIds || !farmIds.length) {
      return;
    }

    const { userType } = this.state;
    _Api(
      Url.USER_GET_FARM_BY_IDS.method,
      Url.USER_GET_FARM_BY_IDS.url,
      "",
      `?farmIds=${farmIds}&userType=${userType}`,
    )
      .then((resp) => {
        this.setState({ getApiFarmDetail: true });
        if (resp.statusCode === 1) {
          let farmInfo = resp.responseData.farmInfo;

          const { cartListDetails } = this.state;
          const validCart = isValidCart(cartListDetails, farmInfo);

          const validDistributions = cartHasValidDistributionOptions({
            cart: cartListDetails,
            farms: farmInfo,
            userType,
          });

          if (!validCart || !validDistributions) {
            window.location.href = "shoppingcart";
          }

          if (validCart) {
            this.setState({ isLoader: false, farms: farmInfo });
          }
        }
      })
      .catch((err) => {
        if (err.error) {
          notify("error", err.error.responseMessage);
        }
      });
  };

  getCartList = () => {
    this.setState({ isLoader: true });
    _Api(Url.USER_GET_CART_LIST.method, Url.USER_GET_CART_LIST.url)
      .then((resp) => {
        if (resp.responseData && resp.responseData.result) {
          let cartListDetails = resp.responseData.result;

          if (!cartListDetails._id || cartListDetails.totalBuyProduct < 1) {
            localStorage.setItem("totalPayAmount", 0);
            window.location.href = "shoppingcart";
          }

          const farmIds = getFarmIdsFromCart(cartListDetails);

          this.setState({ cartListDetails: cartListDetails }, function () {
            this.getFarmsDetails(farmIds);

            localStorage.setItem(
              "totalPayAmount",
              cartListDetails.totalPayPrice,
            );
          });
        }
      })
      .catch((err) => {
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  RemovehandleShow = (id, farmId) => {
    this.setState({ removeitem: true, productListId: id, farmId: farmId });
  };

  itemDeleteToCart = () => {
    let req = {
      productListId: this.state.productListId,
      farmId: this.state.farmId,
    };
    this.setState({ isLoader: true });
    _Api(
      Url.USER_DELETE_PRODUCT_TO_CART.method,
      Url.USER_DELETE_PRODUCT_TO_CART.url,
      req,
    )
      .then((resp) => {
        this.setState({ isLoader: false });
        if (resp.statusCode === 1) {
          this.RemovehandleClose();
          this.getCartList();
          this.props.action.getCart();
        }
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        if (err.error) notify("error", err.error.responseMessage);
      });
  };

  render() {
    const { cartListDetails, farms } = this.state;

    const shippedProducts = cartShippedProducts({ cart: cartListDetails });
    const hasShippedProducts = shippedProducts.length > 0;
    let ShippedProductsSummary = NullComponent;
    if (hasShippedProducts) {
      ShippedProductsSummary = () => (
        <div
          className="ordersummary-item-group"
          key={`buy-list-shipped-products`}
        >
          <div className="farmSectionHeading">Shipped Products</div>
          <div className="ordersummary-item-products table-responsive">
            {shippedProducts.map(({ productListEntry, farmId }) => {
              return (
                <div
                  className="shopping_list ordersummary-item-row"
                  key={`product-list-${productListEntry.productId}`}
                >
                  <div className="checkout-product-image">
                    <img
                      src={
                        productListEntry.image === ""
                          ? require("../../assets/img/providerfarm.png")
                          : productListEntry.image
                      }
                      alt={productListEntry.productName}
                    />
                  </div>
                  <div className="content">
                    <div className="top-heading">
                      <h6 className="ordersummary_itemname product-name">
                        {productListEntry.productName}
                        <SubscriptionInfoOrderSummary
                          inventoryItem={productListEntry}
                        />
                      </h6>
                      <span className="checkout-quantity">
                        {productListEntry.productQuantity}
                      </span>
                      <div className="finalorderprice">
                        {" "}
                        <small>@&nbsp;</small>
                        <h6 className="mb-0 product-price">
                          {" "}
                          ${productListEntry.productPrice}{" "}
                        </h6>
                        <h6 className="mb-0">
                          {" "}
                          &nbsp;= $
                          {productListEntry.productPrice *
                            productListEntry.productQuantity}{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="bottom-opt">
                      <button
                        onClick={() =>
                          this.RemovehandleShow(productListEntry._id, farmId)
                        }
                        className="btn btn-secondary size_small checkout-remove-button"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {cartListDetails.deliveryInfo && (
            <p className="item_details location">
              <span className="label">Ship to </span>
              <br />
              {formatDeliveryInfo(cartListDetails.deliveryInfo)}
            </p>
          )}
        </div>
      );
    }

    const nonShippedBuyList = cartNonShippedBuyList({ cart: cartListDetails });
    const hasNonShippedBuyList = nonShippedBuyList.length > 0;
    let NonShippedProductsSummary = NullComponent;
    if (hasNonShippedBuyList) {
      NonShippedProductsSummary = () =>
        nonShippedBuyList.map((buyListEntry, buyListEntryIndex) => {
          return (
            <div
              className="ordersummary-item-group"
              key={`buy-list-${buyListEntryIndex}`}
            >
              <div className="farmSectionHeading">
                {" "}
                {buyListEntry.farmName}
                {" Products"}
              </div>
              <div className="ordersummary-item-products table-responsive">
                {buyListEntry.productList &&
                  buyListEntry.productList.map((item, index) => {
                    return (
                      <div
                        className="shopping_list ordersummary-item-row"
                        key={`product-list-${item.productId}`}
                      >
                        <div className="checkout-product-image">
                          <img
                            src={
                              item.image === ""
                                ? require("../../assets/img/providerfarm.png")
                                : item.image
                            }
                            alt={item.productName}
                          />
                        </div>
                        <div className="content">
                          <div className="top-heading">
                            <h6 className="ordersummary_itemname product-name">
                              {item.productName}
                              <SubscriptionInfoOrderSummary
                                inventoryItem={item}
                              />
                            </h6>
                            <span className="checkout-quantity">
                              {item.productQuantity}
                            </span>
                            <div className="finalorderprice">
                              {" "}
                              <small>@&nbsp;</small>
                              <h6 className="mb-0 product-price">
                                {" "}
                                ${item.productPrice}{" "}
                              </h6>
                              <h6 className="mb-0">
                                {" "}
                                &nbsp;= $
                                {item.productPrice * item.productQuantity}{" "}
                              </h6>
                            </div>
                          </div>
                          <div className="bottom-opt">
                            <button
                              onClick={() =>
                                this.RemovehandleShow(
                                  item._id,
                                  buyListEntry.farmId,
                                )
                              }
                              className="btn btn-secondary size_small checkout-remove-button"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {buyListEntry.courierType && buyListEntry.address ? (
                <p className="item_details location">
                  <span class="label">
                    {buyListEntry.courierType} Location{" "}
                  </span>
                  <br />
                  {buyListEntry.courierType === "Delivery" ? (
                    formatDeliveryInfo(cartListDetails.deliveryInfo)
                  ) : (
                    <p>{buyListEntry.address}</p>
                  )}
                </p>
              ) : null}
            </div>
          );
        });
    }

    return (
      <div className="ordersummary_item ">
        {this.state.isLoader && <Loader />}
        <ShippedProductsSummary />
        <NonShippedProductsSummary />

        {cartListDetails && cartListDetails._id ? (
          <div className="mb-4 boxed">
            {cartListDetails.deliveryFees > 0 ? (
              <>
                <h6>
                  {" "}
                  Subtotal: &nbsp;
                  <span className="h-orange">
                    $
                    {cartListDetails.totalPrice
                      ? cartListDetails.totalPrice
                      : ""}
                  </span>
                </h6>
                <h6>
                  Delivery Charge -
                  <span className="h-orange">
                    $
                    {cartListDetails.deliveryFees
                      ? cartListDetails.deliveryFees
                      : ""}
                  </span>
                </h6>
              </>
            ) : null}
            <h6 className="checkout-total">
              {" "}
              Total: &nbsp;
              <span className="h-orange">
                $
                {cartListDetails.totalPayPrice > 0
                  ? cartListDetails.totalPayPrice
                  : ""}
              </span>
            </h6>
          </div>
        ) : this.state.getApiResponse ? (
          <>
            <div className="text-center">
              <h6 className="text-orange">Your cart is empty</h6>
              <Button
                size="sm"
                className="mx-2"
                variant="info"
                onClick={() => this.redirectToBuy()}
              >
                Buy Products{" "}
              </Button>
            </div>
          </>
        ) : null}

        <Modal
          show={this.state.removeitem}
          onHide={this.RemovehandleClose}
          className="cm_confirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <img
                alt="food stand"
                src={require("../../assets/img/stand.png")}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Remove Item</Modal.Body>
          <Modal.Footer>
            <div className="cmbtngrp">
              <Button
                variant="warning"
                onClick={() => this.itemDeleteToCart(this.state.productListId)}
              >
                Yes
              </Button>
              <Button variant="secondary" onClick={this.RemovehandleClose}>
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.login.isLoggedIn,
    profiledetails: state.profile,
    cartListDetails: state.cartList.cartListDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({ getCart }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderSummaryItems);
