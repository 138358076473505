import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import Url from "../services/BaseUrl";
import { _Api } from "../services/Api";
import { isEmpty, sampleSize } from "lodash";
import Slider from "react-slick";
import { currentlyOrderableInventory } from "../utils/inventory";
import { groupFarmsWithInventory } from "../utils/farm";
import { InventoryProductCard } from "./InventoryProductCard";

const debug = require("debug")("FarmCards");
const queryString = require("query-string");

const noop = () => undefined;

export const InventoryProductCards = ({
  view,
  setView,
  userType,
  zipCode,
  distributionType,
  sliderProps,
}) => {
  const [farms, setFarms] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [inventorySize, setInventorySize] = useState(0);

  const useSlider = !isEmpty(sliderProps);
  const pickRandomNumber = 14;

  // - get delivery distributions that deliver to zipcode
  // - get inventory from each farmer, that is for delviery/pickup
  // - locally filter inventory based on open distributions
  // - use that count to determine products available
  useEffect(() => {
    debug("query-farms");
    const queryParams = {
      zipCode,
      userType,
      distributionType,
    };
    if (distributionType) {
      queryParams.distributionType = distributionType;
    }

    _Api(
      Url.USER_ALL_FARM_DISTRIBUTIONS_INVENTORY.method,
      Url.USER_ALL_FARM_DISTRIBUTIONS_INVENTORY.url,
      "",
      `?${queryString.stringify(queryParams)}`,
    ).then((resp) => {
      debug("query-farm-result");
      const { inventory, distributions } = resp.responseData.result;
      setFarms(
        groupFarmsWithInventory({
          farms: resp.responseData.result.farms,
          inventory: currentlyOrderableInventory({ inventory, distributions }),
        }),
      );
      setInventorySize(inventory.length);
    });
  }, [zipCode]);

  useEffect(() => {
    // This should be handled with filters on the backend
    // This gets a list of 10 random items from the farms
    // and ensures that the inventory item has an image
    setInventory(
      sampleSize(
        farms
          .map((farm) =>
            farm.inventory.filter((inv) => {
              inv.farmId = farm._id; // Add farmId to inventory item
              return inv.image !== "undefined";
            }),
          )
          .flat(),
        pickRandomNumber,
      ),
    );
  }, [farms]);

  if (useSlider && inventory.length > 3) {
    return (
      <Slider {...sliderProps} key="farm-slider">
        {inventory.map((item) => {
          const cardProps = {
            inventoryItem: item,
            userType,
            farmId: item.farmId,
          };
          return (
            <InventoryProductCard {...cardProps} key={`item-${item._id}`} />
          );
        })}
        <div className="order-now-additional-products">
          <Button variant="info" onClick={() => setView(1)}>
            View{" "}
            {inventorySize - pickRandomNumber <= 1
              ? "all"
              : `${inventorySize - pickRandomNumber} more`}{" "}
            products
          </Button>
        </div>
      </Slider>
    );
  }

  if (zipCode && inventory.length === 0)
    return (
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <Alert
            variant="warning"
            className="order-now-no-results hp-alert hp-alert-large"
          >
            There aren't currently any farms delivering to your zip code
          </Alert>
        </div>
      </div>
    );

  //initial case,
  if (useSlider && inventory.length === 0) return null;

  //default condition - render farms in a grid, without slider
  return (
    <div className="cards-container cards-container-farms">
      {inventory.map((item) => {
        const cardProps = {
          inventoryItem: item,
          userType,
          farmId: item.farmId,
        };
        return <InventoryProductCard {...cardProps} key={`item-${item._id}`} />;
      })}
    </div>
  );
};
