import {
  GET_USER_PROFILE_FAILED,
  GET_USER_PROFILE_SUCCESS,
} from "../action/Type";

const initalState = {
  profileInfo: {
    firstName: "",
    lastName: "",
  },
};

// get all  users details
export const profile = (state = initalState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_USER_PROFILE_FAILED:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};
