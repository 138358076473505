import { productIsShippable } from "./inventory";

const debug = require("debug")("util-cart");

export const cartShippedProducts = ({ cart }) => {
  if (!cart) return [];
  if (!Array.isArray(cart.buyList)) return [];
  const shippedProducts = cart.buyList
    .map((buyListEntry, buyListEntryIndex) => {
      const farmId = buyListEntry.farmId;
      const shippedProducts = buyListEntry.productList
        .map((productListEntry, productListEntryIndex) => {
          return {
            buyListEntryIndex,
            productListEntryIndex,
            productListEntry,
            farmId,
          };
        })
        .filter(({ productListEntry }) => {
          return productIsShippable({ inventoryItem: productListEntry });
        });
      return shippedProducts;
    })
    .reduce((accumulator, nested) => {
      return accumulator.concat(nested);
    }, []);
  return shippedProducts;
};

export const cartNonShippedBuyList = ({ cart }) => {
  if (!cart) return [];
  if (!Array.isArray(cart.buyList)) return [];
  return cart.buyList
    .map((buyListEntry, buyListEntryIndex) => {
      const nonShippedProductList = buyListEntry.productList.filter(
        (productListEntry) => {
          return !productIsShippable({ inventoryItem: productListEntry });
        },
      );
      return {
        ...buyListEntry,
        productList: nonShippedProductList,
      };
    })
    .filter((buyListEntry) => {
      return buyListEntry.productList.length > 0;
    });
};
