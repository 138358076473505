const debug = require("debug")("header-action");

export const SET_HEADER_ACTIVE_SIGN_IN_MODAL =
  "SET_HEADER_ACTIVE_SIGN_IN_MODAL";
export const SIGN_IN_MODAL_STATES = {
  SIGN_UP: "SIGN_UP",
  SIGN_IN_AS: "SIGN_IN_AS",
  LOGIN: "LOGIN",
  NONE: "NONE",
};

export const setHeaderActiveSignInModal =
  ({ activeSignInModal }) =>
  (dispatch) => {
    debug("setHeaderActiveSignInModal");
    debug(activeSignInModal);
    dispatch({
      type: SET_HEADER_ACTIVE_SIGN_IN_MODAL,
      payload: { activeSignInModal },
    });
  };

export const setHeaderActiveSignInAsSignUp = () => (dispatch) => {
  dispatch({
    type: SET_HEADER_ACTIVE_SIGN_IN_MODAL,
    payload: { activeSignInModal: SIGN_IN_MODAL_STATES.SIGN_UP },
  });
};

export const setHeaderActiveSignInAsSignInAs = () => (dispatch) => {
  dispatch({
    type: SET_HEADER_ACTIVE_SIGN_IN_MODAL,
    payload: { activeSignInModal: SIGN_IN_MODAL_STATES.SIGN_IN_AS },
  });
};

export const setHeaderActiveSignInAsLogin = () => (dispatch) => {
  dispatch({
    type: SET_HEADER_ACTIVE_SIGN_IN_MODAL,
    payload: { activeSignInModal: SIGN_IN_MODAL_STATES.LOGIN },
  });
};

export const setHeaderActiveSignInAsNone = () => (dispatch) => {
  dispatch({
    type: SET_HEADER_ACTIVE_SIGN_IN_MODAL,
    payload: { activeSignInModal: SIGN_IN_MODAL_STATES.NONE },
  });
};
